import api from 'app/utils/api';

export class WorkAllocationService {
  static async getWorkAllocations() {
    try {
      const response = await api.get(
        `/processing-admin/work-allocations/all-allocations`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static async addOfficeToWorkAllocation(data: any) {
    try {
      const response = await api.post(
        '/processing-admin/work-allocations/add-office',
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static async addWorkAllocation(data: any) {
    try {
      const response = await api.put(
        `/processing-admin/work-allocations/edit-out-of-office`,
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static async editWorkAllocation(data: any) {
    try {
      const response = await api.put(
        `/processing-admin/work-allocations/edit-out-of-office`,
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static async deleteWorkAllocation(data: any) {
    try {
      const response = await api.delete(
        '/processing-admin/work-allocations/out-of-office',
        {
          data: data,
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static assignPrimaryAdmin(data: any) {
    try {
      const response = api.post(
        `/processing-admin/work-allocations/allocate`,
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static reassignPrimaryAdmin(data: any) {
    try {
      const response = api.post(
        `/processing-admin/work-allocations/reassign`,
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static getProcessingTeamMembers() {
    // NOTE:  this api will have about 20 max admins. 50 is inflated just to adjust and get all. we want to get all at once. Change if required
    try {
      const response = api.get(`/processing-team?limit=50`);
      return response;
    } catch (error) {
      return error;
    }
  }
}
