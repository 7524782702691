import React from 'react';
import { FilterProvider } from './FilterContext';
import ComplianceChecksPage from './index';

const WrappedComplianceChecksPage = ({
  isCompliance,
}: {
  isCompliance?: boolean;
}) => {
  return (
    <FilterProvider isCompliance={isCompliance || false}>
      <ComplianceChecksPage isCompliance={isCompliance} />
    </FilterProvider>
  );
};

export default WrappedComplianceChecksPage;
