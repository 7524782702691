interface FilterState {
  selectedStatuses: string[];
  selectedTransactionTypes: string[];
  selectedTransactionMilestones: string[];
  statusLabels: string[];
  createdAtFilter: { gte: string; lte: string };
  closingDateFilter: { gte: string; lte: string };
  updatedAtFilter: { gte: string; lte: string };
  selectedTags: string[];
  pageSize: number;
}

class FilterHandler {
  private storageKey: string = 'transaction_filters';
  private defaultValue: FilterState;
  private currentState: FilterState;

  constructor(config: { defaultValue: FilterState }) {
    this.defaultValue = config.defaultValue;
    const stored = sessionStorage.getItem(this.storageKey);
    this.currentState = stored ? JSON.parse(stored) : this.defaultValue;
  }

  getStoredFilters(): FilterState {
    return { ...this.currentState };
  }

  storeFilters(filters: FilterState): void {
    this.currentState = { ...filters };
    sessionStorage.setItem(this.storageKey, JSON.stringify(this.currentState));
  }

  clearFilters(): void {
    this.currentState = { ...this.defaultValue };
    sessionStorage.removeItem(this.storageKey);
  }
}

const defaultFilterState = {
  selectedStatuses: [],
  selectedTransactionTypes: [],
  selectedTransactionMilestones: [],
  statusLabels: [],
  createdAtFilter: { gte: '', lte: '' },
  closingDateFilter: { gte: '', lte: '' },
  updatedAtFilter: { gte: '', lte: '' },
  selectedTags: [],
  pageSize: 10,
};

export const getTransactionFilterHandler = () =>
  new FilterHandler({
    defaultValue: defaultFilterState,
  });
