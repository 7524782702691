import { User } from '../types';
import api from 'app/utils/api';

export const remarksService = {
  // Get all remarks for an entity
  getRemarks: async (entityId: string, entityType: string): Promise<any[]> => {
    const url = `/comments/${entityType}/${entityId}`;
    const response = await api.get(url);
    return response.data;
  },

  // Add a new remark
  addRemark: async (
    entityId: string,
    entityType: string,
    content: string
  ): Promise<any> => {
    const url = `/comments/${entityType}/${entityId}`;
    const data = {
      text: content,
    };
    const response = await api.post(url, data);
    return response.data;
  },

  // Delete a remark
  deleteRemark: async (entityId: string, remarkId: string): Promise<void> => {
    const response = await api.delete(`/comments/${remarkId}`);
    return response.data;
  },

  // Search users for mentions
  searchUsers: async (
    query: string,
    entityId: string,
    entityType: string
  ): Promise<User[]> => {
    const url = `/comments/taggable/${entityType}/${entityId}${query ? `?search=${query}` : ''}`;
    const response = await api.get(url);
    return response.data;
  },
};
