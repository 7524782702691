import './styles/TaskTable.scss';
import './styles/TaskPreviewModal.scss';

import { Breadcrumb, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';

import { AppURLs } from 'app/utils/appURLs';
import { Box } from '@mui/material';
import { ComplianceService } from 'app/services/compliance';
import TaskTable from './components/TaskTable';
import { getCompliancePartnerID } from 'app/utils/localStorageHandler/userProfile';
import { getCustomIdFromUrl } from 'app/utils/url';
import { partners } from 'app/configs/partner';
import { transactionSourceAtom } from 'app/atomManagers/transactionSourceAtom';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const TaskModule: React.FC = () => {
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState<any>();
  const transactionID = getCustomIdFromUrl('transactionID');
  const transactionSource = useRecoilValue(transactionSourceAtom);

  const goToTransactions = () => {
    if (transactionSource === 'compliance-transactions') {
      navigate(AppURLs.complianceDashboardComplianceTransactions());
    } else {
      navigate(AppURLs.complianceDashboardTransactions());
    }
  };

  useEffect(() => {
    if (!transactionID) {
      return;
    }
    ComplianceService.getTransactionByID(transactionID).then(
      (res) => {
        setTransaction(res.data);
      },
      (err) => {
        message.error(err.message);
      }
    );
  }, []);
  return (
    <div className="task-module">
      <Box className="main-title-container">
        {transactionID ? (
          <Breadcrumb style={{ margin: '1rem 0 1rem 0', display: 'flex' }}>
            <Breadcrumb.Item onClick={() => goToTransactions()}>
              <Typography style={{ cursor: 'pointer' }}>
                Transactions
              </Typography>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Typography>
                {getCompliancePartnerID() === partners.KEYES &&
                  transaction?.externalSourceID &&
                  `${transaction.externalSourceID} - `}
                {transaction?.name}
              </Typography>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Typography>Tasks</Typography>
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          <Typography
            style={{
              margin: '1rem 0 1rem 0',
              fontSize: '1.25rem',
              fontWeight: 700,
            }}
          >
            Pending Tasks
          </Typography>
        )}
      </Box>
      <TaskTable roles={['Compliance']} />
    </div>
  );
};

export default TaskModule;
