const complianceTransactionStatusMap: Record<string, Record<string, string>> = {
  PRE_LISTING: {
    name: 'Pre-Listing',
    mainColor: '#FFA500', // Orange
    backgroundColor: '#FFF4E5',
    fontColor: '#8B4500',
  },
  PRIVATE_LISTING: {
    name: 'Private Listing',
    mainColor: '#800080', // Purple
    backgroundColor: '#F3E5F5',
    fontColor: '#4B0082',
  },
  ACTIVE_LISTING: {
    name: 'Active Listing',
    mainColor: '#0000FF', // Blue
    backgroundColor: '#E6F4FF',
    fontColor: '#003EB3',
  },
  UNDER_CONTRACT: {
    name: 'Under Contract', // Gold
    mainColor: '#FFD700',
    backgroundColor: '#FFF4B8',
    fontColor: '#AD6800',
  },
  WITHDRAWN: {
    name: 'Withdrawn', // Gray
    mainColor: '#808080',
    backgroundColor: '#F5F5F5',
    fontColor: '#505050',
  },
  SOLD: {
    name: 'Sold', // Green
    mainColor: '#008000',
    backgroundColor: '#CFFAD3',
    fontColor: '#099530',
  },
  TERMINATED: {
    name: 'Terminated', // Red
    mainColor: '#FF0000',
    backgroundColor: '#FFE5E5',
    fontColor: '#8B0000',
  },
};

const transactionProcessingStatusMap: Record<string, Record<string, string>> = {
  NOT_STARTED: {
    name: 'Not submitted for Review',
    mainColor: '#808080', // Gray
    backgroundColor: '#F5F5F5',
    fontColor: '#505050',
    severity: 'inactive',
  },
  PROCESSING: {
    name: 'Under Review',
    mainColor: '#0000FF', // Blue
    backgroundColor: '#E6F4FF',
    fontColor: '#003EB3',
    severity: 'progress',
  },
  MAX_APPROVED: {
    name: 'Max Approved',
    mainColor: '#FFA500', // Orange
    backgroundColor: '#FFF4E5',
    fontColor: '#8B4500',
    severity: 'pending',
  },
  USER_APPROVED: {
    name: 'Approved',
    mainColor: '#008000', // Green
    backgroundColor: '#CFFAD3',
    fontColor: '#099530',
    severity: 'complete',
  },
  SENT_TO_AGENT_FOR_UPDATES: {
    name: 'Returned to Agent',
    mainColor: '#800080', // Purple
    backgroundColor: '#F3E5F5',
    fontColor: '#4B0082',
    severity: 'utility',
  },
  DOCS_MISSING: {
    name: 'Documents Missing',
    mainColor: '#FF0000', // Red
    backgroundColor: '#FFE5E5',
    fontColor: '#8B0000',
    severity: 'error',
  },
  VALIDATION_FAILED: {
    name: 'Validation Failed',
    mainColor: '#FF0000', // Red
    backgroundColor: '#FFE5E5',
    fontColor: '#8B0000',
    severity: 'error',
  },
  AGENT_MAX_REVIEWING: {
    name: 'Pending Max Review',
    mainColor: '#FFA500', // Orange
    backgroundColor: '#FFF4E5',
    fontColor: '#8B4500',
    severity: 'pending',
  },
  AGENT_MAX_ERRORS_FOUND: {
    name: 'Errors Detected',
    mainColor: '#FF0000', // Red
    backgroundColor: '#FFE5E5',
    fontColor: '#8B0000',
    severity: 'error',
  },
  AGENT_MAX_APPROVED: {
    name: 'Max Approved',
    mainColor: '#FFA500', // Orange
    backgroundColor: '#FFF4E5',
    fontColor: '#8B4500',
    severity: 'pending',
  },
};

//TODO: Generalise the usage of one transaction milestone.
const transactionMilestoneMap: Record<
  string,
  {
    name: string;
    mainColor: string;
    backgroundColor: string;
    fontColor: string;
  }
> = {
  PRE_CONTRACT: {
    name: 'Pre-Contract',
    mainColor: '#808080', // Gray
    backgroundColor: '#F5F5F5',
    fontColor: '#505050',
  },
  UNDER_CONTRACT: {
    name: 'Under Contract',
    mainColor: '#0000FF', // Blue
    backgroundColor: '#E6F4FF',
    fontColor: '#003EB3',
  },
  CLOSING: {
    name: 'Closing',
    mainColor: '#FFA500', // Orange
    backgroundColor: '#FFF4E5',
    fontColor: '#8B4500',
  },
  CLOSED: {
    name: 'Closed',
    mainColor: '#008000', // Green
    backgroundColor: '#CFFAD3',
    fontColor: '#099530',
  },
  CLOSED_FILE_COMPLETE: {
    name: 'Closed - File Complete',
    mainColor: '#004D00', // Dark Green
    backgroundColor: '#D4F8DC',
    fontColor: '#003300',
  },
  WITHDRAWN: {
    name: 'Withdrawn',
    mainColor: '#800080', // Purple
    backgroundColor: '#F3E5F5',
    fontColor: '#4B0082',
  },
  TERMINATED: {
    name: 'Terminated',
    mainColor: '#FF0000', // Red
    backgroundColor: '#FFE5E5',
    fontColor: '#8B0000',
  },
  FALL_THROUGH: {
    name: 'Fall Through',
    mainColor: '#A52A2A', // Brown
    backgroundColor: '#FAE5E5',
    fontColor: '#660000',
  },
};

const taskStatusMap: Record<
  string,
  {
    name: string;
    mainColor: string;
    backgroundColor: string;
    fontColor: string;
  }
> = {
  PENDING: {
    name: 'Pending',
    mainColor: '#FFA500', // Orange
    backgroundColor: '#FFF4E5',
    fontColor: '#8B4500',
  },
  PROCESSING: {
    name: 'Processing',
    mainColor: '#0000FF', // Blue
    backgroundColor: '#E6F4FF',
    fontColor: '#003EB3',
  },
  COMPLETED: {
    name: 'Completed',
    mainColor: '#008000', // Green
    backgroundColor: '#CFFAD3',
    fontColor: '#099530',
  },
  FAILED: {
    name: 'Failed',
    mainColor: '#FF0000', // Red
    backgroundColor: '#FFE5E5',
    fontColor: '#8B0000',
  },
  CANCELLED: {
    name: 'Cancelled',
    mainColor: '#800080', // Purple
    backgroundColor: '#F3E5F5',
    fontColor: '#4B0082',
  },
  INCOMPLETE: {
    name: 'Incomplete',
    mainColor: '#A52A2A', // Brown
    backgroundColor: '#FAE5E5',
    fontColor: '#660000',
  },
  SYSTEM_CANCELLED: {
    name: 'System Cancelled',
    mainColor: '#808080', // Gray
    backgroundColor: '#F5F5F5',
    fontColor: '#505050',
  },
};

export {
  complianceTransactionStatusMap,
  transactionProcessingStatusMap,
  transactionMilestoneMap,
  taskStatusMap,
};
