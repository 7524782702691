import React, { useState } from 'react';
import FloatingButton from './components/FloatingButton';
import RemarksDrawer from './components/RemarksDrawer';
import { RemarksProps } from './types';

const Remarks: React.FC<RemarksProps> = ({
  entityId,
  entityType,
  position = 'bottom-right',
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [drawerKey, setDrawerKey] = useState(0); // Use this state to force re-render of all child states once closed
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
    setDrawerKey((prev) => prev + 1);
  };

  const handleButtonClick = () => {
    setIsDrawerVisible(true);
  };

  return (
    <>
      <FloatingButton onClick={handleButtonClick} position={position} />
      <RemarksDrawer
        key={drawerKey}
        entityId={entityId}
        entityType={entityType}
        visible={isDrawerVisible}
        onClose={handleDrawerClose}
      />
    </>
  );
};

export default Remarks;
