export interface DocumentMetadata {
  documentType: string;
  documentName: string;
  documentId: string;
}

export interface TagMetadata {
  documents?: DocumentMetadata[];
  missingTypes?: string[];
  [key: string]: any;
}

export interface Tag {
  tagId: string;
  tagName: string;
  tagType: string;
  description: string;
  entityType: string;
  metadata: TagMetadata;
  createdAt: string;
}

export interface TagsByEntity {
  [entityId: string]: Tag[];
}

export const TAG_ENTITY_TYPES = {
  TRANSACTION: 'TRANSACTION',
  DOCUMENT: 'DOCUMENT',
};

export const TAG_NAMES = {
  VALIDATION_FAILED: 'VALIDATION_FAILED',
  MISSING_DOCUMENT: 'MISSING_DOCUMENT',
  NEW_DOCUMENT_ADDED_TODAY: 'NEW_DOCUMENT_ADDED_TODAY',
  DOCUMENT_REJECTED_SENT_TO_AGENT: 'DOCUMENT_REJECTED_SENT_TO_AGENT',
  STAGNANT_TRANSACTION: 'STAGNANT_TRANSACTION',
  CHECK_DOCUMENT: 'CHECK_DOCUMENT',
  COMPLIANCE_TRANSACTION: 'COMPLIANCE_TRANSACTION',
};

export const TAG_DISPLAY_NAMES = {
  VALIDATION_FAILED: 'Validation Failed',
  MISSING_DOCUMENT: 'Missing Document',
  NEW_DOCUMENT_ADDED_TODAY: 'New Document Added',
  DOCUMENT_REJECTED_SENT_TO_AGENT: 'Document Rejected',
  STAGNANT_TRANSACTION: 'Dormant Transaction',
  CHECK_DOCUMENT: 'Check Transaction',
  COMPLIANCE_TRANSACTION: 'Compliance Transaction',
};
