import CircularProgress from '@mui/material/CircularProgress';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { forwardRef } from 'react';

const DotloopSyncStatusBar = forwardRef<
  HTMLDivElement,
  { transactionName: string; onClick: any }
>(({ transactionName, onClick }, ref) => (
  <div
    ref={ref}
    className="flex w-full h-fit p-3 bg-[#2F78EB] text-white box-border"
  >
    <div className="flex justify-center flex-1">
      Syncing {transactionName} with Dotloop
      <CircularProgress
        sx={{ margin: 'auto 0 auto 1rem', color: 'white' }}
        size={20}
      />
    </div>
    <button
      className="cursor-pointer text-white flex-shrink-0 mr-6 transition-transform duration-200 hover:scale-110"
      onClick={onClick}
    >
      <CloseOutlined className="text-white" />
    </button>
  </div>
));

export default DotloopSyncStatusBar;
