import React from 'react';
import { TaskStatusMap } from '../utils/taskUtils';
import { getSeverityClasses } from '../../../lib/severityColorMap';

interface TaskStatusBadgeProps {
  status: string;
}

const TaskStatusBadge: React.FC<TaskStatusBadgeProps> = ({ status }) => {
  const statusConfig = TaskStatusMap[status];

  return (
    <span
      className={`inline-flex items-center px-2.5 py-1 rounded-full text-sm font-medium ${
        statusConfig
          ? getSeverityClasses(statusConfig.severity)
          : 'bg-gray-100 text-gray-800'
      }`}
    >
      {statusConfig?.text || status}
    </span>
  );
};

export default TaskStatusBadge;
