import './TransactionFilterComponent.scss';
import { DatePicker, Drawer, Select, Tag } from 'antd';
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import FilterButton from 'app/components/common/Checks/FilterButton';
import { transactionProcessingStatusMap } from '../colorScheme';
import { TAG_DISPLAY_NAMES } from 'app/components/common/Tags/tagUtils';
import { useFlags } from 'flagsmith/react';
import { getTransactionMilestonesForPartner } from 'app/configs/transaction';
import { getCompliancePartnerID } from 'app/utils/localStorageHandler/userProfile';

const tagOptions: SelectOption[] = Object.entries(TAG_DISPLAY_NAMES).map(
  ([tagName, displayName]) => ({
    value: tagName,
    label: displayName,
  })
);

interface SelectOption {
  value: string;
  label: string;
}

const statuses: SelectOption[] = [
  {
    value: 'NOT_STARTED',
    label: transactionProcessingStatusMap.NOT_STARTED.name,
  },
  {
    value: 'PROCESSING',
    label: transactionProcessingStatusMap.PROCESSING.name,
  },
  {
    value: 'MAX_APPROVED',
    label: transactionProcessingStatusMap.MAX_APPROVED.name,
  },
  {
    value: 'USER_APPROVED',
    label: transactionProcessingStatusMap.USER_APPROVED.name,
  },
  {
    value: 'SENT_TO_AGENT_FOR_UPDATES',
    label: transactionProcessingStatusMap.SENT_TO_AGENT_FOR_UPDATES.name,
  },
  {
    value: 'DOCS_MISSING',
    label: transactionProcessingStatusMap.DOCS_MISSING.name,
  },
  {
    value: 'VALIDATION_FAILED',
    label: transactionProcessingStatusMap.VALIDATION_FAILED.name,
  },
];

interface TransactionFilterComponentProps {
  selectedStatuses: string[];
  selectedTransactionTypes: string[];
  selectedTransactionMilestones: string[];
  createdAtFilter: { gte: string; lte: string };
  closingDateFilter: { gte: string; lte: string };
  updatedAtFilter: { gte: string; lte: string };
  selectedTags: string[];
  onFilterChange: (
    statuses: string[],
    createdAtFilter: { gte: string; lte: string },
    closingDateFilter: { gte: string; lte: string },
    transactionTypes: string[],
    transactionMilestones: string[],
    updatedAtFilter: { gte: string; lte: string },
    tags: string[]
  ) => void;
  onClearFilters: () => void;
  activeFiltersCount: number;
}

const TransactionFilterComponent: React.FC<TransactionFilterComponentProps> = ({
  selectedStatuses,
  selectedTransactionTypes,
  selectedTransactionMilestones,
  createdAtFilter,
  closingDateFilter,
  updatedAtFilter,
  onFilterChange,
  onClearFilters,
  activeFiltersCount,
  selectedTags,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const transactionTypeOptions = [
    { value: 'PURCHASE', label: 'Buy' },
    { value: 'LISTING', label: 'List' },
    { value: 'LEASE_OFFER', label: 'Lease Offer' },
    { value: 'LEASE_LISTING', label: 'Lease List' },
  ];

  const transactionMilestonesOptions = Object.entries(
    getTransactionMilestonesForPartner(getCompliancePartnerID())
  ).map(([milestone, displayName]) => ({
    value: milestone,
    label: displayName,
  }));

  const tagFlags = useFlags(['show_tags']);
  const isTagEnabled = tagFlags?.show_tags?.enabled;

  const handleStatusSelect = (value: string[]) => {
    onFilterChange(
      value,
      createdAtFilter,
      closingDateFilter,
      selectedTransactionTypes,
      selectedTransactionMilestones,
      updatedAtFilter,
      selectedTags
    );
  };

  const handleTransactionTypeSelect = (value: string[]) => {
    onFilterChange(
      selectedStatuses,
      createdAtFilter,
      closingDateFilter,
      value,
      selectedTransactionMilestones,
      updatedAtFilter,
      selectedTags
    );
  };

  const handleTransactionMilestoneSelect = (value: string[]) => {
    onFilterChange(
      selectedStatuses,
      createdAtFilter,
      closingDateFilter,
      selectedTransactionTypes,
      value,
      updatedAtFilter,
      selectedTags
    );
  };

  const handleCreatedDateChange = (value: any) => {
    const newCreatedAtFilter = {
      gte: value?.[0]?.format('MM-DD-YYYY') || '',
      lte: value?.[1]?.format('MM-DD-YYYY') || '',
    };
    onFilterChange(
      selectedStatuses,
      newCreatedAtFilter,
      closingDateFilter,
      selectedTransactionTypes,
      selectedTransactionMilestones,
      updatedAtFilter,
      selectedTags
    );
  };

  const handleClosingDateChange = (value: any) => {
    const newClosingDateFilter = {
      gte: value?.[0]?.format('MM-DD-YYYY') || '',
      lte: value?.[1]?.format('MM-DD-YYYY') || '',
    };
    onFilterChange(
      selectedStatuses,
      createdAtFilter,
      newClosingDateFilter,
      selectedTransactionTypes,
      selectedTransactionMilestones,
      updatedAtFilter,
      selectedTags
    );
  };

  const handleUpdatedDateChange = (value: any) => {
    const newUpdatedAtFilter = {
      gte: value?.[0]?.format('MM-DD-YYYY') || '',
      lte: value?.[1]?.format('MM-DD-YYYY') || '',
    };
    onFilterChange(
      selectedStatuses,
      createdAtFilter,
      closingDateFilter,
      selectedTransactionTypes,
      selectedTransactionMilestones,
      newUpdatedAtFilter,
      selectedTags
    );
  };

  const selectTagRender: any = (props: any) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: '0.25rem',
          padding: '0.3rem',
          fontSize: '0.9rem',
          borderRadius: '16px',
        }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div className="filter-component">
      <FilterButton
        onClick={toggleDropdown}
        clearFilters={onClearFilters}
        selectedCount={activeFiltersCount}
      />

      <Drawer
        title={<Typography fontSize="1.5rem">Filters</Typography>}
        open={showDropdown}
        onClose={toggleDropdown}
        width={350}
      >
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Status
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Status"
            value={selectedStatuses}
            onChange={handleStatusSelect}
            options={statuses}
            allowClear
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Transaction Type
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Transaction Type"
            value={selectedTransactionTypes}
            onChange={handleTransactionTypeSelect}
            options={transactionTypeOptions}
            allowClear
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Transaction Milestone
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Transaction Milestone"
            value={selectedTransactionMilestones}
            onChange={handleTransactionMilestoneSelect}
            options={transactionMilestonesOptions}
            allowClear
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Created Date
          </Typography>
          <DatePicker.RangePicker
            disabledDate={(current) => current > dayjs()}
            value={
              createdAtFilter.gte && createdAtFilter.lte
                ? [dayjs(createdAtFilter.gte), dayjs(createdAtFilter.lte)]
                : null
            }
            onChange={handleCreatedDateChange}
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Closing Date
          </Typography>
          <DatePicker.RangePicker
            value={
              closingDateFilter.gte && closingDateFilter.lte
                ? [dayjs(closingDateFilter.gte), dayjs(closingDateFilter.lte)]
                : null
            }
            onChange={handleClosingDateChange}
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Last Updated Date
          </Typography>
          <DatePicker.RangePicker
            value={
              updatedAtFilter.gte && updatedAtFilter.lte
                ? [dayjs(updatedAtFilter.gte), dayjs(updatedAtFilter.lte)]
                : null
            }
            onChange={handleUpdatedDateChange}
          />
        </div>

        {isTagEnabled ? (
          <div className="filter-section">
            <Typography fontSize="1rem" marginBottom="0.5rem">
              Tags
            </Typography>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%', marginBottom: '1rem' }}
              placeholder="Select Tags"
              value={selectedTags}
              onChange={(values) => {
                onFilterChange(
                  selectedStatuses,
                  createdAtFilter,
                  closingDateFilter,
                  selectedTransactionTypes,
                  selectedTransactionMilestones,
                  updatedAtFilter,
                  values
                );
              }}
              options={tagOptions}
              tagRender={selectTagRender}
            />
          </div>
        ) : null}
      </Drawer>
    </div>
  );
};

export default TransactionFilterComponent;
