import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  CheckCircleOutlined,
  ErrorRounded,
  RefreshOutlined,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import {
  TransactionMilestone,
  excludedMilestonesPerPartnerPerTransactionType,
  milestoneToSeverity,
  transactionMilestones,
} from 'app/configs/transaction';
import { getSeverityClasses, severity } from 'app/lib/severityColorMap';
import { useLocation, useNavigate } from 'react-router-dom';

import { AGENT_TAB_CONFIG } from './config/AgentTransactionTabConfig';
import { AppURLs } from 'app/utils/appURLs';
import { ComplianceService } from 'app/services/compliance';
import { TRANSACTION_TYPE_OPTIONS } from 'app/pages/Compliance/Dashboard/pages/utils/transactionTypeMap';
import { capitalizeFirstLetter } from 'app/utils/string';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getMMDDYYYYString } from 'app/utils/datetime';
import { transactionProcessingStatusMap } from 'app/pages/Compliance/Dashboard/components/colorScheme';
import { getCompliancePartnerID } from 'app/utils/localStorageHandler/userProfile';
import { partners } from 'app/configs/partner';

const { Title, Text } = Typography;

interface HeaderProps {
  transaction: any;
  activeTab?: string;
  onTabChange?: (tab: string) => void;
  verifyDocumentView?: boolean;
  getTransaction?: () => void;
}

type LoadingStatusKey = 'mls' | 'milestone';

export const Header: React.FC<HeaderProps> = ({
  transaction,
  activeTab,
  onTabChange,
  verifyDocumentView = false,
  getTransaction,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loadingStatuses, setLoadingStatuses] = useState<
    Record<LoadingStatusKey, boolean>
  >({
    mls: false,
    milestone: false,
  });
  const [mlsStatus, setMlsStatus] = useState<string | null>(null);
  const setLoadingStatus = (key: LoadingStatusKey, value: boolean) => {
    setLoadingStatuses((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    fetchMlsStatus();
  }, []);

  const fetchMlsStatus = async () => {
    try {
      setLoadingStatus('mls', true);
      const response = await ComplianceService.getUpdatedMlsStatus(
        transaction?._id
      );
      setMlsStatus(response.data?.mlsDocument?.mlsStatus);
      return response.data;
    } catch (error) {
      console.error('Error fetching transaction details:', error);
      return null;
    } finally {
      setLoadingStatus('mls', false);
    }
  };

  const updateTransactionMilestone = async (milestone: string) => {
    setLoadingStatus('milestone', true);
    try {
      await ComplianceService.updateTransactionMilestone(
        transaction._id,
        milestone
      );
      message.success('Milestone updated successfully');
      getTransaction?.();
    } catch (error) {
      console.error('Error updating milestone:', error);
    } finally {
      setLoadingStatus('milestone', false);
    }
  };

  const HEADER_INFO_ITEMS = [
    {
      label: 'Agent',
      getValue: (t: any) =>
        t?.agents?.length > 0 &&
        t?.agents?.map((agent: any) => agent?.details?.name).join(', '),
    },
    {
      label: 'Closing date',
      getValue: (t: any) => getMMDDYYYYString(t?.closingDate),
    },
    {
      label: '',
      getValue: (t: any) => {
        const missingDocs = t?.processingStatus?.missingDocs || [];
        const missingDocsCount = missingDocs.length;

        if (missingDocsCount > 0) {
          return (
            <Tooltip
              arrow={false}
              overlayInnerStyle={{
                backgroundColor: 'white',
                color: 'rgba(0, 0, 0, 0.88)',
                padding: '1rem',
                minWidth: '16rem',
              }}
              placement="leftBottom"
              title={
                <div>
                  {missingDocs.map((doc: string, index: number) => (
                    <div key={index} className="flex items-center">
                      <Typography className="text-sm">
                        {index + 1}. {documentNameMap?.[doc] || doc}
                      </Typography>
                    </div>
                  ))}
                </div>
              }
            >
              <div className="flex items-center cursor-pointer hover:scale-105 transition-transform duration-200">
                <ErrorRounded className="h-5 w-5 text-[#FF4D4F]" />
                <span className="ml-1">
                  <b>{missingDocsCount}</b> Documents Missing
                </span>
              </div>
            </Tooltip>
          );
        }
        return (
          <div className="flex items-center">
            <CheckCircleOutlined className="text-[#52C41A] text-lg" />
            <span className="ml-1">All Documents Uploaded</span>
          </div>
        );
      },
      customRender: true,
    },
  ];

  const HEADER_SUBINFO_ITEMS = [
    {
      label: 'Transaction Milestone',
      getValue: (t: any) => (
        <Select
          loading={loadingStatuses.milestone}
          defaultValue={t?.milestone || undefined}
          value={t?.milestone || undefined}
          onChange={(value) => updateTransactionMilestone(value)}
          bordered={false}
          className={`!h-6 leading-6`}
        >
          {Object.entries(transactionMilestones)
            .filter(([key, value]) => {
              const compliancePartnerID = getCompliancePartnerID();
              const transactionType = t?.type ?? '';

              const excludedMilestones =
                excludedMilestonesPerPartnerPerTransactionType[
                  compliancePartnerID as keyof typeof excludedMilestonesPerPartnerPerTransactionType
                ]?.[transactionType ?? ''] ||
                excludedMilestonesPerPartnerPerTransactionType[
                  compliancePartnerID as keyof typeof excludedMilestonesPerPartnerPerTransactionType
                ]?.['PURCHASE'] ||
                [];
              return !excludedMilestones.includes(key);
            })
            .map(([key, value]) => (
              <Select.Option key={value} value={value}>
                {capitalizeFirstLetter(value.toLowerCase().replace(/_/g, ' '))}
              </Select.Option>
            ))}
        </Select>
      ),
      getTagColor: (t: any) =>
        getSeverityClasses(
          milestoneToSeverity[t?.milestone as TransactionMilestone],
          false
        ),
      customRender: true,
    },
    {
      label: 'Review Status',
      getValue: (t: any) =>
        transactionProcessingStatusMap[t?.processingStatus?.status]?.name ||
        '-',
      getTagColor: (t: any) => {
        const status = t?.processingStatus?.status;
        if (!status) return '';
        const severity = transactionProcessingStatusMap[status]
          .severity as severity;
        return getSeverityClasses(severity);
      },
    },
    {
      label: 'Transaction Type',
      getValue: (t: any) => TRANSACTION_TYPE_OPTIONS(t?.type) || '-',
    },
    getCompliancePartnerID() !== partners.JMG && {
      label: 'MLS Status',
      getValue: (t: any) =>
        loadingStatuses.mls
          ? 'Loading...'
          : mlsStatus || 'No active listing in last 6 months',
      buttonOnLabel: true,
      buttonOnLabelIcon: loadingStatuses.mls ? (
        <LoadingOutlined spin />
      ) : (
        <RefreshOutlined style={{ fontSize: 'large' }} />
      ),
      onButtonOnLabelClick: () => {
        if (!loadingStatuses.mls) {
          fetchMlsStatus();
        }
      },
    },
  ];

  const generateBreadcrumbs = () => {
    const breadcrumbs = [
      {
        label: transaction.name,
        onClick: () => {
          navigate(AppURLs.agentDashboardMainTransactions());
        },
        className: 'cursor-pointer',
      },
      {
        label: 'Documents',
        onClick: () => {
          navigate(AppURLs.agentDashboardTransaction(transaction._id));
        },
        className: 'cursor-pointer',
      },
      {
        label: (
          <Tooltip title={transaction?.document?.title}>
            <span className="max-w-[20vw] inline-block truncate">
              {transaction?.document?.title}
            </span>
          </Tooltip>
        ),
        onClick: () => {},
      },
    ];

    return breadcrumbs;
  };

  if (!transaction) return null;

  return (
    <div className="bg-primary p-6 pb-4">
      <div className="border-b border-[#E5E7EB]/20 pb-4">
        <div className="mb-5 flex flex-wrap items-center">
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            className="text-white hover:text-white/80 w-10"
            onClick={() => {
              if (
                location.pathname === '/agent/dashboard/transactions/detail'
              ) {
                navigate(AppURLs.agentDashboardMainTransactions());
              } else {
                navigate(AppURLs.agentDashboardTransaction(transaction._id));
              }
            }}
          />
          <div className="flex flex-wrap flex-1 justify-between">
            {verifyDocumentView ? (
              <>
                <Breadcrumb className="flex items-center [&_.ant-breadcrumb-separator]:text-white">
                  {generateBreadcrumbs().map((breadcrumb, index, array) => (
                    <Breadcrumb.Item key={index} onClick={breadcrumb.onClick}>
                      <Text
                        className={
                          `text-white ${index === array.length - 1 ? 'font-bold' : ''}` +
                            ' ' +
                            breadcrumb?.className || ''
                        }
                      >
                        {breadcrumb.label}
                      </Text>
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              </>
            ) : (
              <Title level={4} className="!text-white !mb-0">
                {transaction.name}
              </Title>
            )}

            <Space wrap className="gap-3">
              {HEADER_INFO_ITEMS.map(({ label, getValue }) => (
                <Text key={label} className="text-white/80">
                  {label ? `${label}: ` : ''}
                  <span className="text-white">{getValue(transaction)}</span>
                </Text>
              ))}
            </Space>
          </div>
        </div>

        {!verifyDocumentView && (
          <Space size="large" className="flex flex-wrap gap-4 justify-start">
            {HEADER_SUBINFO_ITEMS.filter(Boolean).map(
              ({
                label,
                getValue,
                onButtonOnLabelClick,
                buttonOnLabelIcon,
                buttonOnLabel,
                getTagColor,
              }: any) => (
                <Text key={label} className="text-white/80 flex ">
                  {label}{' '}
                  {buttonOnLabel && (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        onButtonOnLabelClick && onButtonOnLabelClick();
                      }}
                      className="cursor-pointer flex items-center ml-1 mr-1"
                    >
                      {buttonOnLabelIcon}
                    </div>
                  )}
                  :{' '}
                  <span
                    className={`ml-2 !h-6 leading-6 rounded-xl inline-flex items-center ${
                      getTagColor?.(transaction) || 'bg-default'
                    } ${getTagColor?.(transaction) ? 'px-2' : ''}`}
                  >
                    {getValue(transaction)}
                  </span>
                </Text>
              )
            )}
          </Space>
        )}
      </div>

      {!verifyDocumentView && onTabChange && (
        <div className="mt-2 flex flex-wrap gap-2">
          {Object.values(AGENT_TAB_CONFIG).map(({ key, displayName }) => (
            <Button
              key={key}
              type={key === activeTab ? 'primary' : 'text'}
              className={`
                ${
                  key === activeTab
                    ? 'bg-dark-blue text-white hover:text-white/80 rounded-xl'
                    : 'text-white hover:text-white/80'
                }
              `}
              onClick={() => onTabChange(key)}
            >
              {displayName}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};
