import { Chip } from '@mui/material';

export const REVIEW_STATUS_OPTIONS = [
  'NOT_STARTED',
  'PROCESSING',
  'VALIDATION_FAILED',
  'MAX_APPROVED',
  'USER_APPROVED',
  'NOT_APPLICABLE',
  'READY_TO_BE_SENT_TO_AGENT',
  'SENT_TO_AGENT_FOR_UPDATES',
];

const allStatusChips: any = {
  NOT_STARTED: {
    agent: (
      <Chip
        label="Not Submitted"
        size="small"
        sx={{
          backgroundColor: '#EDEBEB',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Not Submitted"
        size="small"
        sx={{
          backgroundColor: '#EDEBEB',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
  },
  AGENT_MAX_REVIEWING: {
    agent: (
      <Chip
        label="Pending Max Review"
        size="small"
        sx={{
          backgroundColor: '#FFD198',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Not Submitted"
        size="small"
        sx={{
          backgroundColor: '#EDEBEB',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
  },
  AGENT_MAX_ERRORS_FOUND: {
    agent: (
      <Chip
        label="Errors Detected"
        size="small"
        sx={{
          backgroundColor: '#FFA8A8',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Not Submitted"
        size="small"
        sx={{
          backgroundColor: '#EDEBEB',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
  },
  AGENT_MAX_APPROVED: {
    agent: (
      <Chip
        label="Max Approved"
        size="small"
        sx={{
          backgroundColor: '#7FD6FF',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Not Submitted"
        size="small"
        sx={{
          backgroundColor: '#EDEBEB',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
  },
  PROCESSING: {
    agent: (
      <Chip
        label="Under Review"
        size="small"
        sx={{
          backgroundColor: '#FFD198',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Pending Max Review"
        size="small"
        sx={{
          backgroundColor: '#FFD198',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
  },
  VALIDATION_FAILED: {
    agent: (
      <Chip
        label="Under Review"
        size="small"
        sx={{
          backgroundColor: '#FFD198',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Errors Detected"
        size="small"
        sx={{
          backgroundColor: '#FFA8A8',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
  },
  MAX_APPROVED: {
    agent: (
      <Chip
        label="Under Review"
        size="small"
        sx={{
          backgroundColor: '#FFD198',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Max Approved"
        size="small"
        sx={{
          backgroundColor: '#7FD6FF',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
  },
  USER_APPROVED: {
    agent: (
      <Chip
        label="Approved"
        size="small"
        sx={{
          backgroundColor: '#6CFF9D',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Approved"
        size="small"
        sx={{
          backgroundColor: '#6CFF9D',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
  },
  READY_TO_BE_SENT_TO_AGENT: {
    agent: (
      <Chip
        label="Under Review"
        size="small"
        sx={{
          backgroundColor: '#FFD198',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Ready for Agent Review"
        size="small"
        sx={{
          backgroundColor: '#E2FFA8',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
  },
  SENT_TO_AGENT_FOR_UPDATES: {
    agent: (
      <Chip
        label="Document Returned"
        size="small"
        sx={{
          backgroundColor: '#E2FFA8',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Returned to Agent"
        size="small"
        sx={{
          backgroundColor: '#E2FFA8',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
  },
  NOT_APPLICABLE: {
    agent: (
      <Chip
        label="Not Applicable"
        size="small"
        sx={{
          backgroundColor: '#EDEBEB',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Not Applicable"
        size="small"
        sx={{
          backgroundColor: '#EDEBEB',
          color: '#1A1A1A',
          borderRadius: '4px',
        }}
      />
    ),
  },
  NULL: {
    agent: (
      <Chip
        label="Null"
        size="small"
        sx={{
          backgroundColor: '#FFF1F0',
          color: '#A8071A',
          borderRadius: '4px',
        }}
      />
    ),
    compliance: (
      <Chip
        label="Null"
        size="small"
        sx={{
          backgroundColor: '#FFF1F0',
          color: '#A8071A',
          borderRadius: '4px',
        }}
      />
    ),
  },
};

export const StatusChip = ({
  status,
  role,
}: {
  status?: string;
  role?: string;
}) => {
  if (role === 'agent') {
    if (!status) {
      return allStatusChips['NULL'].agent;
    }
    return (
      allStatusChips?.[status].agent || (
        <Chip sx={{ borderRadius: '4px' }} label={status} size="small" />
      )
    );
  } else if (role === 'compliance') {
    if (!status) {
      return allStatusChips['NULL'].compliance;
    }
    return (
      allStatusChips?.[status].compliance || (
        <Chip sx={{ borderRadius: '4px' }} label={status} size="small" />
      )
    );
  } else {
    return (
      <Chip
        label="Null"
        size="small"
        sx={{
          backgroundColor: '#FFF1F0',
          color: '#A8071A',
          borderRadius: '4px',
        }}
      />
    );
  }
};
