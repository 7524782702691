import api from 'app/utils/api';
import moment from 'moment';

export class TaskService {
  static async getTasksByTransaction(
    transactionID: string,
    roles: string = 'Compliance'
  ) {
    try {
      const response = await api.get(`/task/${transactionID}?roles=${roles}`);
      return response;
    } catch (error) {
      return error;
    }
  }

  static async updateTaskMetadata(taskId: string, updates: any) {
    try {
      const response = await api.patch(
        `/task/edit-metadata/${taskId}`,
        updates
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static async updateTaskRecipients(taskId: string, recipients: string[]) {
    try {
      const response = await api.patch(`/task/edit-recipients/${taskId}`, {
        recipients,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async updateTaskStatus(
    taskId: string,
    status: string,
    errors?: string[]
  ) {
    try {
      const response = await api.patch(`/task/status/${taskId}`, {
        status,
        errors,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async sendEmailInstantly(taskId: string) {
    try {
      const response = await api.post(`/task/send-now/${taskId}`);
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getTasksByTriggerDate(queryParams?: any, roles?: string) {
    let url = '';
    if (roles?.includes('Agent')) {
      url = '/task/by-trigger-date-agent';
    } else {
      url = '/task/by-trigger-date';
    }
    try {
      const clientDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      const response = await api.get(url, {
        params: {
          ...queryParams,
          clientDate,
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  public static completeManualTask(taskId: string, data: { text?: string }) {
    return api.post(`/task/complete-manual-task/${taskId}`, data);
  }

  static async deleteTask(taskId: string) {
    try {
      const response = await api.delete(`/task/${taskId}`);
      return response;
    } catch (error) {
      return error;
    }
  }

  static async updateTaskTriggerDate(taskId: string, triggerDate: any) {
    try {
      const response = await api.patch(`/task/trigger-date/${taskId}`, {
        triggerDate,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
}
