import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Button, List, Typography, Spin, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { remarksService } from '../../services';
import { User } from '../../types';
import './styles.scss';
import useDebounce from 'app/hooks/UseDebounce';

interface RemarksDrawerProps {
  entityId: string;
  entityType: string;
  visible: boolean;
  onClose: () => void;
}

const initialStates = {
  remarks: [] as any[],
  inputValue: '',
  users: [] as User[],
  cursorPosition: 0,
  displayValue: '',
  selectedUsers: [] as User[],
  loadingStates: {
    remarks: false,
    users: false,
    submit: false,
  },
};

const RemarksDrawer: React.FC<RemarksDrawerProps> = ({
  entityId,
  entityType,
  visible,
  onClose,
}) => {
  const [remarks, setRemarks] = useState(initialStates.remarks);
  const [inputValue, setInputValue] = useState(initialStates.inputValue);
  const [users, setUsers] = useState(initialStates.users);
  const [cursorPosition, setCursorPosition] = useState(
    initialStates.cursorPosition
  );
  const [displayValue, setDisplayValue] = useState(initialStates.displayValue);
  const [selectedUsers, setSelectedUsers] = useState(
    initialStates.selectedUsers
  );
  const [loadingStates, setLoadingStates] = useState(
    initialStates.loadingStates
  );

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const lastRemarkRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (visible) {
      loadRemarks();
    }
  }, [visible, entityId, entityType]);

  useEffect(() => {
    if (remarks.length > 0) {
      lastRemarkRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [remarks]);

  const loadRemarks = async () => {
    setLoadingStates((prev) => ({ ...prev, remarks: true }));
    try {
      const data = await remarksService.getRemarks(entityId, entityType);
      // Validate response data
      if (!Array.isArray(data)) {
        throw new Error('Invalid response format');
      }
      setRemarks(data);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to load remarks';
      message.error(errorMessage);
      setRemarks([]);
    } finally {
      setLoadingStates((prev) => ({ ...prev, remarks: false }));
    }
  };

  const searchUsers = async (searchTerm: string) => {
    if (!searchTerm.trim()) {
      setUsers([]);
      return;
    }

    setLoadingStates((prev) => ({ ...prev, users: true }));
    try {
      const users = await remarksService.searchUsers(
        searchTerm,
        entityId,
        entityType
      );
      // Validate response data
      if (!Array.isArray(users)) {
        throw new Error('Invalid users response format');
      }
      setUsers(users);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to search users';
      message.error(errorMessage);
      setUsers([]);
    } finally {
      setLoadingStates((prev) => ({ ...prev, users: false }));
    }
  };

  const renderHighlightContent = (text: string) => {
    // Match @name + surname but not the text after
    const parts = text.split(/(@\S+(?:\s+\S+)?)/).filter(Boolean);
    return parts.map((part, index) => {
      if (part.match(/^@\S+(?:\s+\S+)?/)) {
        return (
          <span key={index} className="mention-highlight">
            {part}
          </span>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newDisplayValue = e.target.value;
    if (newDisplayValue.length > 350) {
      message.warning('Maximum 350 characters allowed in remarks');
      return;
    }
    const newCursorPosition = e.target.selectionStart || 0;

    // Find all complete mentions in the display value
    const mentions = newDisplayValue.match(/@\S+(?:\s+\S+)?(?=\s|$)/g) || [];

    // Create the storage format by replacing mentions with their storage format
    let newInputValue = newDisplayValue;
    for (const mention of mentions) {
      const userName = mention.slice(1).trim(); // Remove @ and trim
      const user = [...users, ...selectedUsers].find(
        (u) => u.name === userName
      );

      if (user) {
        newInputValue = newInputValue.replace(
          mention,
          `@[${user.name}](${user._id})`
        );
      }
    }

    setInputValue(newInputValue);
    setDisplayValue(newDisplayValue);
    setCursorPosition(newCursorPosition);

    // Check if we need to trigger user search
    const beforeCursor = newDisplayValue.slice(0, newCursorPosition);
    const lastAtSymbol = beforeCursor.lastIndexOf('@');

    if (lastAtSymbol !== -1) {
      const textAfterAt = beforeCursor.slice(lastAtSymbol + 1);
      if (textAfterAt && !textAfterAt.includes(' ')) {
        searchUsers(textAfterAt);
      } else {
        setUsers([]);
      }
    }
  };

  const insertMention = (user: User) => {
    const beforeCursor = displayValue.slice(0, cursorPosition);
    const afterCursor = displayValue.slice(cursorPosition);
    const lastAtSymbol = beforeCursor.lastIndexOf('@');

    if (lastAtSymbol !== -1) {
      const mentionText = `@${user.name} `;
      const storageText = `@[${user.name}](${user._id}) `;

      const newDisplayValue =
        beforeCursor.slice(0, lastAtSymbol) + mentionText + afterCursor;
      const newInputValue =
        beforeCursor.slice(0, lastAtSymbol) + storageText + afterCursor;

      setDisplayValue(newDisplayValue);
      setInputValue(newInputValue);

      // Set cursor position after the mention and space
      const newPosition = lastAtSymbol + mentionText.length;
      setCursorPosition(newPosition);

      setTimeout(() => {
        if (textAreaRef.current) {
          textAreaRef.current.focus();
          textAreaRef.current.setSelectionRange(newPosition, newPosition);
        }
      }, 0);
      setSelectedUsers((prev) => [...prev, user]); // Add selected user to tracking array
      setUsers([]);
    }
  };

  const handleSubmit = async () => {
    const trimmedInput = inputValue.trim();
    if (!trimmedInput) {
      message.warning('Please enter a remark');
      return;
    }

    if (trimmedInput.length > 350) {
      message.warning('Remark exceeds maximum length of 350 characters');
      return;
    }

    setLoadingStates((prev) => ({ ...prev, submit: true }));

    try {
      const response = await remarksService.addRemark(
        entityId,
        entityType,
        trimmedInput
      );
      // Validate response
      if (!response) {
        throw new Error('Failed to add remark');
      }

      setInputValue('');
      setDisplayValue('');
      setSelectedUsers([]); // Reset selected users
      await loadRemarks(); // Reload remarks after successful submission
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to add remark';
      message.error(errorMessage);
    } finally {
      setLoadingStates((prev) => ({ ...prev, submit: false }));
    }
  };

  const renderContent = (text: string) => {
    if (!text) return '';
    // Split the text into parts that are mentions and regular text
    const parts = text.split(/(@\[[^\]]+\]\([^)]+\))/g);

    return parts.map((part, index) => {
      // Check if this part is a mention
      const mentionMatch = part.match(/@\[([^\]]+)\]\(([^)]+)\)/);

      if (mentionMatch) {
        // mentionMatch[1] contains the name
        return (
          <span key={index} className="mention">
            @{mentionMatch[1]}
          </span>
        );
      }

      // Regular text
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <Drawer
      title="Remarks"
      placement="right"
      width={550}
      onClose={onClose}
      open={visible}
      className="remarks-drawer"
    >
      <div className="remarks-container">
        <div className="remarks-list">
          {loadingStates.remarks ? (
            <div className="loading-container">
              <Spin />
            </div>
          ) : (
            <List
              dataSource={remarks}
              renderItem={(remark: any, index: any) => (
                <List.Item
                  className="remark-item"
                  ref={index === remarks.length - 1 ? lastRemarkRef : undefined}
                >
                  <List.Item.Meta
                    avatar={<UserOutlined />}
                    title={
                      <div className="remark-header">
                        <span>{remark?.author?.name || '-'}</span>
                        <Typography.Text type="secondary">
                          {dayjs(remark.createdAt).format('MMM D, YYYY h:mm A')}
                        </Typography.Text>
                      </div>
                    }
                    description={
                      <div className="remark-content">
                        {renderContent(remark.displayText)}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          )}
        </div>
        <div className="remarks-input">
          <div className="textarea-container">
            <div className="textarea-highlight-container">
              <div className="textarea-highlight">
                {renderHighlightContent(displayValue)}
              </div>
            </div>
            <textarea
              ref={textAreaRef}
              value={displayValue}
              onChange={handleInputChange}
              placeholder="Type @ to mention someone..."
              className="remarks-textarea"
              disabled={loadingStates.submit || loadingStates.remarks}
            />
            {(loadingStates.users || users.length > 0) && (
              <div className="mentions-dropdown">
                <List
                  size="small"
                  dataSource={users}
                  loading={loadingStates.users}
                  renderItem={(user) => (
                    <List.Item
                      className="mention-item"
                      onClick={() => insertMention(user)}
                    >
                      <List.Item.Meta
                        avatar={<UserOutlined />}
                        title={user.name}
                      />
                    </List.Item>
                  )}
                />
              </div>
            )}
          </div>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loadingStates.submit}
            disabled={loadingStates.submit || loadingStates.remarks}
          >
            Add Remark
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default RemarksDrawer;
