import './FilterComponent.scss';
import { DatePicker, Drawer, Select } from 'antd';
import React, { useState } from 'react';
import {
  Chip,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { STATUS_MAPPING } from './types';
import FilterButton from './FilterButton';
import dayjs from 'dayjs';
import { OfficeFilter, OfficeType } from '../OfficeFilter/OfficeFilter';
import { FilterState, useFilters } from './FilterContext';
import { ClearIcon } from '@mui/x-date-pickers';
import { Search } from '@mui/icons-material';
import { documentNameMap } from 'app/configs/documentNameMap';

interface FilterComponentProps {
  isCompliance: boolean;
}

const getStatusLabel = (value: string) => {
  return (
    Object.entries(STATUS_MAPPING).find(([_, v]) => v === value)?.[0] || value
  );
};

const getStatusValue = (label: string) => {
  return STATUS_MAPPING[label as keyof typeof STATUS_MAPPING] || label;
};

const checkTypes = [
  { value: 'IL_BW_CC', label: 'Commission Check' },
  { value: 'IL_BW_EMR', label: 'Earnest Money' },
  { value: 'IL_BW_EMR_A', label: 'Additional Earnest Money' },
  { value: 'IL_BW_EMP', label: 'Earnestly' },
];

const readyToScanOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

interface FilterLabelsProps {
  filters: FilterState;
}

export const FilterLabels = ({ filters }: FilterLabelsProps) => {
  const getFilterLabelObjects = () => {
    const labelObjects: { label: string; count: number }[] = [];

    filters.statuses.forEach((status) => {
      const label = getStatusLabel(status);
      labelObjects.push({ label, count: 1 });
    });

    filters.checkTypes.forEach((type) => {
      labelObjects.push({ label: documentNameMap[type], count: 1 });
    });

    filters.transactionOffices.forEach((office) => {
      labelObjects.push({ label: `Branch: ${office.name}`, count: 1 });
    });

    filters.receivingOffices.forEach((office) => {
      labelObjects.push({ label: `Receiving: ${office.name}`, count: 1 });
    });

    if (filters.readyToScan !== null) {
      labelObjects.push({
        label: `Ready to Submit: ${filters.readyToScan ? 'Yes' : 'No'}`,
        count: 1,
      });
    }

    if (filters.dateFilter.gte && filters.dateFilter.lte) {
      const startDate = filters.dateFilter.gte
        ? dayjs(filters.dateFilter.gte).format('MM/DD/YYYY')
        : '';
      const endDate = filters.dateFilter.lte
        ? dayjs(filters.dateFilter.lte).format('MM/DD/YYYY')
        : '';
      labelObjects.push({
        label: `Created Date: ${startDate}${endDate ? ` - ${endDate}` : ''}`,
        count: 1,
      });
    }

    if (filters.depositDateFilter.gte && filters.depositDateFilter.lte) {
      const startDate = filters.depositDateFilter.gte
        ? dayjs(filters.depositDateFilter.gte).format('MM/DD/YYYY')
        : '';
      const endDate = filters.depositDateFilter.lte
        ? dayjs(filters.depositDateFilter.lte).format('MM/DD/YYYY')
        : '';
      labelObjects.push({
        label: `Approval Date: ${startDate}${endDate ? ` - ${endDate}` : ''}`,
        count: 1,
      });
    }

    if (filters.activeButtonIndex !== null) {
      const today = dayjs().format('MM/DD/YYYY');
      labelObjects.push({
        label:
          filters.activeButtonIndex === 0
            ? `Submitted Today: ${today}`
            : `Ready Today: ${today}`,
        count: 1,
      });
    }

    return labelObjects;
  };

  const filterLabelObjects = getFilterLabelObjects();

  return (
    <div className="flex flex-wrap gap-3 py-2 ">
      {filterLabelObjects.map((labelObj, index) => (
        <div key={index} className="relative inline-block">
          <Chip label={labelObj.label} />
        </div>
      ))}
    </div>
  );
};

const FilterComponent: React.FC<FilterComponentProps> = ({ isCompliance }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    filters,
    setStatuses,
    setCheckTypes,
    setReadyToScan,
    setDateFilter,
    setDepositDateFilter,
    clearFilters,
    setActiveButtonIndex,
    setSearchTerm,
  } = useFilters();

  const activeFiltersCount = [
    filters.statuses.length,
    filters.checkTypes.length,
    filters.transactionOffices.length,
    filters.receivingOffices.length,
    filters.dateFilter.gte || filters.dateFilter.lte ? 1 : 0,
    filters.readyToScan ? 1 : 0,
    filters.depositDateFilter.gte || filters.depositDateFilter.lte ? 1 : 0,
  ].reduce((a, b) => a + b, 0);

  const buttonFilters = [
    {
      label: 'Submitted Today',
      gte: dayjs().startOf('day').format('YYYY-MM-DD'),
      lte: '',
      isDepositDateFilter: false,
    },
    {
      label: 'Ready Today',
      gte: dayjs().startOf('day').format('YYYY-MM-DD'),
      lte: '',
      isDepositDateFilter: true,
    },
  ];

  const handleButtonFilterClick = (index: number) => {
    if (filters.activeButtonIndex === index) {
      // If clicking the same button, clear the filter
      if (buttonFilters[index].isDepositDateFilter) {
        setDepositDateFilter({ gte: '', lte: '' });
        setReadyToScan(false);
      } else {
        setDateFilter({ gte: '', lte: '' });
      }
      setActiveButtonIndex(null);
    } else {
      // Set new filter
      const filter = buttonFilters[index];
      if (filter.isDepositDateFilter) {
        setDepositDateFilter({ gte: filter.gte, lte: filter.lte });
      } else {
        setDateFilter({ gte: filter.gte, lte: filter.lte });
      }
      setActiveButtonIndex(index);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between items-center">
        <div className="flex">
          {buttonFilters.map((filter, i) => (
            <div
              className={`border border-[#d8d8d8] p-3 text-sm cursor-pointer first:rounded-l-[10px] last:rounded-r-[10px] ${
                i === filters.activeButtonIndex ? 'bg-[#d8dce373]' : ''
              }`}
              key={i}
              onClick={() => handleButtonFilterClick(i)}
            >
              {filter.label}
            </div>
          ))}
        </div>
        <div className="filter-component flex gap-4 items-center">
          <TextField
            placeholder="Search for property"
            value={filters.searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: 'max-content',
              '& .MuiInputBase-root': { height: '48px' },
              background: 'white',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: filters.searchTerm && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setSearchTerm('')}
                    edge="end"
                    size="small"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FilterButton
            onClick={() => setShowDropdown(true)}
            clearFilters={clearFilters}
            selectedCount={activeFiltersCount}
          />

          <Drawer
            title={
              <div className="flex justify-between items-center">
                <Typography fontSize="1.5rem">Filters</Typography>
              </div>
            }
            open={showDropdown}
            onClose={() => setShowDropdown(false)}
            width={350}
          >
            <div className="filter-section">
              <Typography fontSize="1rem" marginBottom="0.5rem">
                Status
              </Typography>
              <Select
                mode="multiple"
                className="select-component"
                placeholder="Select Status"
                value={filters.statuses.map(getStatusLabel)}
                onChange={(labels: string[]) => {
                  const values = labels.map(getStatusValue);
                  setStatuses(values);
                }}
                options={Object.keys(STATUS_MAPPING).map((label) => ({
                  value: label,
                  label: label,
                }))}
                allowClear
              />
            </div>

            <div className="filter-section">
              <Typography fontSize="1rem" marginBottom="0.5rem">
                Check Type
              </Typography>
              <Select
                mode="multiple"
                className="select-component"
                placeholder="Select Check Type"
                value={filters.checkTypes}
                onChange={setCheckTypes}
                options={checkTypes}
                allowClear
              />
            </div>

            <OfficeFilter type={OfficeType.TRANSACTION} />

            <OfficeFilter type={OfficeType.RECEIVING} />

            <div className="filter-section">
              <Typography fontSize="1rem" marginBottom="0.5rem">
                Ready to Submit
              </Typography>
              <Select
                className="select-component"
                placeholder="Select Ready to Submit"
                value={filters.readyToScan}
                onChange={setReadyToScan}
                options={readyToScanOptions}
                allowClear
              />
            </div>

            <div className="filter-section">
              <Typography fontSize="1rem" marginBottom="0.5rem">
                Created Date
              </Typography>
              <DatePicker.RangePicker
                disabledDate={(current) => current > dayjs()}
                value={
                  filters.dateFilter.gte && filters.dateFilter.lte
                    ? [
                        dayjs(filters.dateFilter.gte),
                        dayjs(filters.dateFilter.lte),
                      ]
                    : null
                }
                onChange={(dates) =>
                  setDateFilter({
                    gte: dates?.[0]?.format('YYYY-MM-DD') || '',
                    lte: dates?.[1]?.format('YYYY-MM-DD') || '',
                  })
                }
              />
            </div>

            <div className="filter-section">
              <Typography fontSize="1rem" marginBottom="0.5rem">
                Approval Date
              </Typography>
              <DatePicker.RangePicker
                value={
                  filters.depositDateFilter.gte && filters.depositDateFilter.lte
                    ? [
                        dayjs(filters.depositDateFilter.gte),
                        dayjs(filters.depositDateFilter.lte),
                      ]
                    : null
                }
                onChange={(dates) =>
                  setDepositDateFilter({
                    gte: dates?.[0]?.format('YYYY-MM-DD') || '',
                    lte: dates?.[1]?.format('YYYY-MM-DD') || '',
                  })
                }
              />
            </div>
          </Drawer>
        </div>
      </div>
      <FilterLabels filters={filters} />
    </div>
  );
};

export default FilterComponent;
