import { Profile, ProfileObject } from 'app/interfaces/UserProfile';

/**
 * Get the agent profile from the local storage
 * @returns The agent profile stored in the local storage or null if not found
 */
export const getAgentProfile = (): Profile | null => {
  const profile = localStorage.getItem('Agent');
  return profile && profile !== undefined ? JSON.parse(profile) : null;
};

/**
 * Get the compliance profile from the local storage
 * @returns The compliance profile stored in the local storage or null if not found
 */
export const getComplianceProfile = (): Profile | null => {
  const profile = localStorage.getItem('Compliance');
  return profile && profile !== undefined ? JSON.parse(profile) : null;
};

export const getOfficeAdminProfile = (): Profile | null => {
  const profile = localStorage.getItem('OfficeAdmin');
  return profile && profile !== undefined ? JSON.parse(profile) : null;
};

export const isOfficeAdmin = (): boolean => {
  const isOfficeAdmin =
    localStorage.getItem('OfficeAdmin') && !localStorage.getItem('Admin');
  return isOfficeAdmin ? true : false;
};

export const getOfficeAdminOffice = (): Record<string, any> | null => {
  const officeAdminProfile = getOfficeAdminProfile();
  return officeAdminProfile ? officeAdminProfile?.office || null : null;
};

/**
 * Set the user profile in the local storage
 * @param profile The user profile to be stored
 * @returns void
 */
export const setProfiles = (profileObjects: ProfileObject[]) => {
  localStorage.setItem('profiles', JSON.stringify(profileObjects));
  const partnerId =
    profileObjects &&
    profileObjects[0] &&
    profileObjects[0]?.profile?.partnerID;
  localStorage.setItem('partnerId', JSON.stringify(partnerId));
  for (let profileObject of profileObjects) {
    const role = profileObject.role;
    const profile = profileObject.profile;
    const email = profile.email;
    localStorage.setItem('email', JSON.stringify(email));
    localStorage.setItem(role, JSON.stringify(profile));
  }
};

/**
 * Get the name of the user from the user profile stored in the local storage
 * @returns The name of the user or null if not found
 */
export const getAgentName = (): string | null => {
  const profile = getAgentProfile();
  return profile ? profile.name : null;
};

/**
 * Get the partner ID of the partner the user is a part of
 * @returns The partner ID or null if not found
 */
export const getAgentPartnerID = (): string | null => {
  const profile = getAgentProfile();
  return profile ? profile.partnerID : null;
};

export const getCompliancePartnerID = (): string | null => {
  const profile = getComplianceProfile();
  return profile ? profile.partnerID : null;
};

export const getOfficeAdminPartnerID = (): string | null => {
  const profile = getOfficeAdminProfile();
  return profile ? profile.partnerID : null;
};

// TODO: InitTasks are actually for agent. Reorganize it properly
/**
 * Get the user's initial tasks from the local storage
 * @returns The initial tasks of the user or null if not found
 */
export const getUserInitTasks = (): Record<string, boolean> | null => {
  const tasks = localStorage.getItem('initTasks');
  return tasks ? JSON.parse(tasks) : {};
};

/**
 * Get the user's initial tasks from the local storage
 * @returns The initial tasks of the user or null if not found
 */
export const setUserInitTasks = (tasks: Record<string, boolean>) => {
  localStorage.setItem('initTasks', JSON.stringify(tasks));
};

export const setUser = (id: string) => {
  localStorage.setItem('userId', id);
};

export const setDataConsentScreenShown = (dataTrackingScreenShown: boolean) => {
  localStorage.setItem(
    'dataTrackingScreenShown',
    JSON.stringify(dataTrackingScreenShown)
  );
};

export const getDataConsentScreenShown = () => {
  const dataTrackingScreenShown = localStorage.getItem(
    'dataTrackingScreenShown'
  );
  return dataTrackingScreenShown ? JSON.parse(dataTrackingScreenShown) : false;
};

export const getComplianceAdminProfile = () => {
  const profile = localStorage.getItem('ComplianceAdmin');
  return profile && profile !== undefined ? JSON.parse(profile) : null;
};

export const getAdminProfile = () => {
  const profile = localStorage.getItem('Admin');
  return profile && profile !== undefined ? JSON.parse(profile) : null;
};

export const getUserEmail = () => {
  const email = localStorage.getItem('email');
  return email && email !== undefined ? JSON.parse(email) : null;
};

export const getUserProfiles = () => {
  const profiles = localStorage.getItem('profiles');
  return profiles && profiles !== undefined && profiles !== '[]'
    ? JSON.parse(profiles)
    : [];
};
