import { ComplianceTransaction } from './interfaces/ComplianceTransaction';
import { ErrorField } from './interfaces/Document';
import { atom } from 'recoil';
import { Template } from './components/common/Templates/types';

export const documentIDsAtom = atom<string[]>({
  key: 'documentIDs',
  default: [],
});

type DocumentErrors = Record<string, ErrorField[] | string[]>;
export type ErrorsByTransaction = Record<string, DocumentErrors>;

export const errorsAtom = atom<ErrorsByTransaction>({
  key: 'errorsAtom',
  default: {},
});

export const errorDocumentIdsAndActionsListAtom = atom<
  Record<string, Record<string, string>[] | string[]>
>({
  key: 'errorDocumentIds',
  default: {},
});

export const selectedTemplateAtom = atom<Template | null>({
  key: 'selectedTemplateAtom',
  default: null,
});