interface TabConfig {
  key: string;
  displayName: string;
}

export const AGENT_TAB_CONFIG: Record<string, TabConfig> = {
  documents: {
    key: 'documents',
    displayName: 'Documents',
  },
  tasks: {
    key: 'tasks',
    displayName: 'Tasks',
  },
  'key-transaction-facts': {
    key: 'key-transaction-facts',
    displayName: 'Key Transaction Facts',
  },
  'activity-log': {
    key: 'activity-log',
    displayName: 'Activity Log',
  },
  contacts: {
    key: 'contacts',
    displayName: 'Contacts',
  },
  'transaction-template-documents': {
    key: 'transaction-template-documents',
    displayName: 'Template Documents',
  },
} as const;
