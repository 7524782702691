import { ClockCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { taskStatusMap } from 'app/pages/Compliance/Dashboard/components/colorScheme';

const CompactTasksCard = ({
  task,
  getActionMenu,
}: {
  task: any;
  getActionMenu: any;
}) => {
  const getClosingDateText = (closingDate: string) => {
    if (!closingDate) return null;

    const closing = new Date(closingDate);
    const today = new Date();

    today.setHours(0, 0, 0, 0);
    closing.setHours(0, 0, 0, 0);

    const diffTime = closing.getTime() - today.getTime();
    const daysLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (daysLeft < 0) {
      return (
        <span className="text-red-500">
          Overdue ({Math.abs(daysLeft)} days ago)
        </span>
      );
    }

    if (daysLeft === 0) {
      return <span>Due Today</span>;
    }

    return `${daysLeft} days left`;
  };

  const closingText = getClosingDateText(task.trigger?.date);

  return (
    <div className="rounded-lg border border-gray-300 shadow-sm bg-white p-4 flex flex-col justify-between relative pb-4">
      <div className="flex items-center justify-between">
        <div>
          <span
            className="px-3 py-1 text-sm font-medium rounded-full"
            style={{
              backgroundColor: taskStatusMap[task.status]?.backgroundColor,
              color: taskStatusMap[task.status]?.fontColor,
            }}
          >
            {taskStatusMap[task.status]?.name}
          </span>
        </div>
        <div className="flex items-start justify-end gap-4">
          <div>
            {closingText && (
              <div className="flex items-center text-gray-500 font-semibold text-sm mt-2">
                <ClockCircleOutlined className="mr-2" />
                <span>{closingText}</span>
              </div>
            )}
          </div>
          <div className="no-preview" onClick={(e) => e.stopPropagation()}>
            <Dropdown
              menu={{ items: getActionMenu(task) }}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button type="text" icon={<MoreOutlined />} />
            </Dropdown>
          </div>
        </div>
      </div>

      <div>
        <h6 className="text-lg font-semibold mt-2">
          {task.metaData?.description}
        </h6>

        <p className="text-gray-500 font-semibold text-sm uppercase">
          {task.metaData?.data?.propertyAddress}
        </p>
      </div>
    </div>
  );
};

export default CompactTasksCard;
