import { Box } from '@mui/material';
import {
  Button,
  Dropdown,
  Modal,
  Popconfirm,
  message,
  Menu,
  Tooltip,
} from 'antd';
import {
  CHECK_STATUSES,
  CheckStatusMap,
  CheckStatusMapOriginal,
} from './types';
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { CheckBuilder } from 'app/services/checkBuilder';
import { ComplianceService } from 'app/services/compliance';
import { DownloadCheckReport } from './util/DownloadCheckReport';
import FilterComponent from './FilterComponent';
import UploadDocumentComponent from './UploadDocumentComponent';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getShortDateString } from 'app/utils/datetime';
import { AppURLs } from 'app/utils/appURLs';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { useGlobalContext } from 'app/Context/GlobalContext';
import LoadingProgressBar from '../LoadingProgressBar';
import { useFilters } from './FilterContext';

interface DropdownItem {
  label: string;
  value: any;
  isButton?: boolean;
  isLink?: boolean;
  isModal?: boolean;
  text?: string;
  check?: any;
}

interface ComplianceChecksPageProps {
  isCompliance?: boolean;
}

const ComplianceChecksPage = ({ isCompliance }: ComplianceChecksPageProps) => {
  const context = useGlobalContext();
  const checkResponse = context.getCheckResponse();
  const { filters, setPage, clearFilters } = useFilters();
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalPdfUrl, setModalPdfUrl] = useState<string | null>(null);
  const [selectAll, setSelectAll] = useState(false);

  const [editingDocument, setEditingDocument] = useState<any | null>(null);
  const navigate = useNavigate();

  const [loadingStates, setLoadingStates] = useState({
    table: true,
    generatingReport: false,
    editing: false,
    viewingDocument: false,
  });

  // Update loading state setter helper
  const setLoadingState = (key: string, value: boolean) => {
    setLoadingStates((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeStatus = async (check: any, status: string) => {
    try {
      await ComplianceService.patchCheck(
        check?._id,
        check?.readyToDeposit,
        check?.notes,
        status
      );

      const updatedChecks = checkResponse.data.map((c: any) =>
        c._id === check._id
          ? { ...c, processingStatus: { ...c.processingStatus, status } }
          : c
      );
      context.setCheckResponse({
        ...checkResponse,
        data: updatedChecks,
      });
      message.success(
        `Check status updated to  ${CheckStatusMapOriginal[status]}!`
      );
    } catch (error: any) {
      message.error('Error updating status:', error);
    }
  };

  const isAdmin = localStorage.getItem('Admin');

  const generateDropdownData = (check: any): DropdownItem[][] => {
    return [
      [
        { label: 'Branch Office', value: check?.transactionOffice?.name },
        { label: 'Receiving Office', value: check?.receivingOffice?.name },
        ...(check.processingStatus.status ===
          CHECK_STATUSES.VALIDATION_FAILED ||
        check.processingStatus.status === CHECK_STATUSES.COMPLETED ||
        check.processingStatus.status === CHECK_STATUSES.LOOP_NOT_FOUND ||
        check.processingStatus.status === CHECK_STATUSES.DOCS_MISSING ||
        check.processingStatus.status === CHECK_STATUSES.DEPOSITED ||
        check.processingStatus.status === CHECK_STATUSES.PRISM_NOT_FOUND ||
        isAdmin
          ? [
              {
                label: 'View Validation',
                value: `${isCompliance ? '/compliance' : '/office-admin'}/dashboard/validation?checkID=${check?._id}`,
                isButton: true,
              },
            ]
          : []),
      ],
      [
        { label: 'Agent Name', value: check?.agentName },
        {
          label: 'Dotloop Link',
          text: check?.externalSourceID ? 'https://www.dotloop.com' : 'N/A',
          value: check?.externalSourceID
            ? `https://www.dotloop.com/m/loop?viewId=${check?.externalSourceID}`
            : 'N/A',
          isLink: check?.externalSourceID ? true : false,
        },
        {
          label: isCompliance
            ? loadingStates.viewingDocument
              ? 'Fetching Document...'
              : 'View Document'
            : 'View Documents',
          value: isCompliance ? handleViewPdf : handleViewDocumentsPage,
          check: check,
          isModal: true,
        },
      ],
    ];
  };

  const TableDropdownItem = ({ item, record }: { item: any; record: any }) => {
    if (item.isButton) {
      return (
        <Button
          type="primary"
          className="w-max"
          onClick={() => navigate(item.value)}
        >
          {item.label}
        </Button>
      );
    }

    if (item.isModal) {
      return (
        <Button
          type="primary"
          className="w-max"
          onClick={() => item.value(record)}
        >
          {item.label}
        </Button>
      );
    }

    return (
      <>
        <span className="text-[#2B3340] text-sm font-medium">{item.label}</span>
        {item.isLink ? (
          <div>
            <a href={item.value} target="_blank" rel="noopener noreferrer">
              {item.text}
            </a>
          </div>
        ) : (
          <span className="text-[#57677E] text-sm font-medium">
            {item.value || '-'}
          </span>
        )}
      </>
    );
  };

  const handleSelectAll = (selected: boolean) => {
    setSelectAll(selected);
    if (selected) {
      setSelectedRowIds(checkResponse?.data?.map((_: any, i: number) => i));
    } else {
      setSelectedRowIds([]);
    }
  };

  const handleDownloadReport = async () => {
    try {
      setLoadingState('generatingReport', true);
      let checksToDownload;

      if (selectAll) {
        const checkBuilder = new CheckBuilder.builder()
          .setPage(1)
          .setLimit(checkResponse?.total)
          .setStatus(filters.statuses.join(','))
          .setCheckType(filters.checkTypes.join(','))
          .setTransactionOfficeID(
            filters.transactionOffices.map((o) => o.id).join(',')
          )
          .setReceivingOfficeID(
            filters.receivingOffices.map((o) => o.id).join(',')
          )
          .setCreatedAtGte(filters.dateFilter.gte)
          .setCreatedAtLte(filters.dateFilter.lte)
          .setSearch(filters.searchTerm)
          .setReadyToDeposit(filters.readyToScan)
          .setReadyToDepositMarkedAtGte(filters.depositDateFilter.gte)
          .setReadyToDepositMarkedAtLte(filters.depositDateFilter.lte)
          .build();

        const response = await checkBuilder.fetchNext();
        checksToDownload = response.data;
      } else {
        checksToDownload =
          selectedRowIds.length > 0
            ? checkResponse?.data?.filter((check: any) =>
                selectedRowIds.includes(check._id)
              )
            : checkResponse?.data;
      }

      DownloadCheckReport(checksToDownload);
      setSelectedRowIds([]);
      setSelectAll(false);
    } catch (error) {
      message.error('Failed to generate report');
      console.error('Error generating report:', error);
    } finally {
      setLoadingState('generatingReport', false);
    }
  };

  const fetchTransactionDocument = async (
    docId: string,
    transactionId: string
  ) => {
    try {
      setLoadingState('viewingDocument', true);
      const response = await ComplianceService.getDocumentByID(
        transactionId,
        docId
      );
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      return URL.createObjectURL(pdfBlob);
    } catch {
      return 'No Document found';
    } finally {
      setLoadingState('viewingDocument', false);
    }
  };

  const handleViewPdf = async (check: any) => {
    const doc = await fetchTransactionDocument(
      check.document,
      check.transactionId
    );
    setModalPdfUrl(doc);
    setIsModalVisible(true);
  };

  const handleViewDocumentsPage = async (check: any) => {
    navigate(AppURLs.officeAdminDocuments(check.transactionId));
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    if (modalPdfUrl) URL.revokeObjectURL(modalPdfUrl);
  };

  const refreshTableData = (source?: string) => {
    setLoadingState('table', true);
    const isUpload = source === 'upload';
    if (isUpload) {
      clearFilters();
    }
    new CheckBuilder.builder()
      .setPage(isUpload ? 1 : filters.page)
      .setLimit(10)
      .setStatus(filters.statuses.join(','))
      .setCheckType(filters.checkTypes.join(','))
      .setTransactionOfficeID(
        filters.transactionOffices.map((o) => o.id).join(',')
      )
      .setReceivingOfficeID(filters.receivingOffices.map((o) => o.id).join(','))
      .setCreatedAtGte(filters.dateFilter.gte)
      .setCreatedAtLte(filters.dateFilter.lte)
      .setSearch(filters.searchTerm)
      .setReadyToDeposit(filters.readyToScan)
      .setReadyToDepositMarkedAtGte(filters.depositDateFilter.gte)
      .setReadyToDepositMarkedAtLte(filters.depositDateFilter.lte)
      .build()
      .fetchNext()
      .then((response) => {
        context.setCheckResponse(response);
        setLoadingState('table', false);
        setSelectedRowIds([]);
        setSelectAll(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        context.setCheckResponse({ data: [], total: 0, hasMore: false });
        setLoadingState('table', false);
      });
  };

  const handleDeleteCheck = async (id: string) => {
    try {
      const response = await ComplianceService.deleteCheck(id);
      if (response.status === 204 || response.status === 200) {
        message.success('Check deleted successfully!');
        refreshTableData();
      }
    } catch (error: any) {
      message.error('Error deleting check');
    }
  };

  const handleEditClick = (check: any) => {
    setEditingDocument(check);
    setLoadingState('editing', true);
  };

  const handleUploadSuccess = () => {
    refreshTableData('upload');
    setLoadingState('editing', false);
  };

  const getActionMenu = (check: any): any => {
    const menuItems = [
      {
        key: '1',
        label: (
          <div className="flex items-center gap-2">
            <EditOutlined />
            <span>Edit</span>
          </div>
        ),
        onClick: () => handleEditClick(check),
      },
      {
        type: 'divider',
      },
      {
        key: '2',
        label: (
          <Popconfirm
            className="no-toggle"
            title="Are you sure you want to delete this check?"
            onConfirm={() => handleDeleteCheck(check?._id)}
            okText="Yes"
            cancelText="No"
            onPopupClick={(e) => e.stopPropagation()}
          >
            <div
              className="flex items-center gap-2 text-red-600"
              onClick={(e) => e.stopPropagation()}
            >
              <DeleteOutlined />
              <span>Delete</span>
            </div>
          </Popconfirm>
        ),
        className: 'ant-dropdown-menu-item-danger no-preview',
      },
    ];

    return <Menu className="p-3" items={menuItems as any} />;
  };

  const columns = [
    {
      title: 'Property Address',
      dataIndex: 'transactionName',
      key: 'transactionName',
      className: `${isCompliance ? 'w-[15%]' : 'w-[30%]'}`,
      render: (text: string) => <span>{text || '-'}</span>,
    },
    {
      title: 'Branch Office',
      dataIndex: ['transactionOffice', 'name'],
      className: 'w-[10%]',
      key: 'branchOffice',
    },
    {
      title: 'Receiving Office',
      dataIndex: ['receivingOffice', 'name'],
      className: 'w-[10%]',
      key: 'receivingOffice',
    },
    {
      title: 'Type of Check',
      dataIndex: 'type',
      key: 'type',
      className: 'w-[10%]',
      render: (type: string) => documentNameMap[type],
    },
    {
      title: 'Submission Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      className: 'w-[10%]',
      render: (date: string) => getShortDateString(date),
    },
    {
      title: 'Ready to Submit',
      dataIndex: 'readyToDeposit',
      className: 'w-[10%]',
      key: 'readyToDeposit',
      render: (ready: boolean) => (ready ? 'Yes' : 'No'),
    },
    ...(isCompliance
      ? [
          {
            title: 'Status',
            dataIndex: ['processingStatus', 'status'],
            key: 'status',
            className: 'w-[15%]',
            render: (status: string, record: any) => {
              const menu = {
                items: Object.entries(CheckStatusMapOriginal).map(
                  ([key, value]) => ({
                    key,
                    label: value,
                    onClick: () => handleChangeStatus(record, key),
                  })
                ),
              };

              return (
                <Dropdown menu={menu} trigger={['click']} disabled={!isAdmin}>
                  <span className="cursor-pointer">
                    {CheckStatusMap[status] || '-'}
                  </span>
                </Dropdown>
              );
            },
          },
        ]
      : []),
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      className: 'w-[15%]',
      render: (text: string) => (
        <Tooltip title={text || '-'}>
          <div className="line-clamp-3 overflow-hidden">{text || '-'}</div>
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      className: 'w-[5%]',
      render: (_: any, record: any) => (
        <div className="no-toggle" onClick={(event) => event.stopPropagation()}>
          <Dropdown
            overlay={getActionMenu(record)}
            trigger={['click']}
            placement="bottomRight"
          >
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        </div>
      ),
    },
  ];

  useEffect(() => {
    refreshTableData();
  }, [filters]);

  const component = (
    <>
      <Box width="100%" boxSizing="border-box">
        <div className="p-4">
          <div className="text-2xl font-medium flex justify-between items-center w-full">
            Overview
          </div>
          <div className="flex flex-col border border-[#d8d8d8] rounded-xl mt-4">
            <div className="flex justify-between items-center p-4 border-b border-[#d8d8d8] font-medium text-lg">
              Checks uploaded
              {isCompliance ? (
                <Button
                  disabled={selectedRowIds.length === 0}
                  className="h-10 rounded-xl"
                  type="primary"
                  onClick={handleDownloadReport}
                  loading={loadingStates.generatingReport}
                >
                  {loadingStates.generatingReport
                    ? 'Generating Report...'
                    : 'Download Report'}
                </Button>
              ) : (
                <div className="flex gap-4 justify-center">
                  <Button
                    disabled={selectedRowIds.length === 0}
                    style={{ height: '40px', borderRadius: '12px' }}
                    type="primary"
                    onClick={handleDownloadReport}
                    loading={loadingStates.generatingReport}
                  >
                    {loadingStates.generatingReport
                      ? 'Generating Report...'
                      : 'Download Report'}
                  </Button>
                  <UploadDocumentComponent
                    onSuccess={() => {
                      refreshTableData('upload');
                    }}
                  />
                </div>
              )}
            </div>

            <div className="p-4 pb-2 border-b border-[#d8d8d8]">
              <FilterComponent isCompliance={isCompliance || false} />
            </div>

            <div className="p-4">
              {loadingStates.table && <LoadingProgressBar />}
              <Table
                dataSource={checkResponse?.data || []}
                rowKey="_id"
                className="[&_.ant-table-cell]:!p-2.5 [&_.ant-table-row-expand-icon-cell]:!hidden [&_.ant-table-row-expand-icon-cell]:!w-0 [&_.ant-table-row-expand-icon-cell]:!p-0 [&_.ant-table-expanded-row_.ant-table-cell]:!bg-white [&_.ant-table-expanded-row_.ant-table-cell]:!p-0 [&_.ant-table-expanded-row_.ant-btn-primary]:  [&_.ant-table-expanded-row_.ant-btn-primary]:!h-8 [&_.ant-table-expanded-row_.ant-btn-primary]:!px-4 [&_.ant-table-expanded-row_.ant-btn-primary]:!py-1 [&_.ant-table-expanded-row_.ant-btn-primary]:!text-sm"
                pagination={{
                  current: filters.page,
                  total: checkResponse?.total || 0,
                  pageSize: 10,
                  onChange: (newPage) => setPage(newPage), // Use the context's setPage
                  showSizeChanger: false,
                }}
                columns={columns}
                expandable={{
                  expandIcon: () => null,
                  expandedRowRender: (record: any) => {
                    const dropdownData = generateDropdownData(record);
                    return (
                      <div className="bg-[#f9f9f9] my-4 mx-8 flex flex-col justify-between">
                        {dropdownData.map((row, rowIndex) => (
                          <div
                            key={rowIndex}
                            className={`flex px-8 py-4 ${rowIndex === 0 ? 'border-b-1 border-[#dfe3eb]' : ''}`}
                          >
                            {row.map((item, index) => (
                              <div
                                key={index}
                                className="flex flex-col gap-2 w-1/3 max-w-1/3"
                              >
                                <TableDropdownItem
                                  item={item}
                                  record={record}
                                />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    );
                  },
                  expandRowByClick: true,
                }}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: selectedRowIds,
                  onChange: (selectedKeys: React.Key[]) =>
                    setSelectedRowIds(selectedKeys as number[]),
                  onSelectAll: (selected: boolean) => handleSelectAll(selected),
                }}
              />
            </div>
            {loadingStates.editing && (
              <UploadDocumentComponent
                onSuccess={handleUploadSuccess}
                initialDocument={editingDocument || null}
              />
            )}
          </div>
        </div>
      </Box>
      <Modal
        title="Document Preview"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
        width="80%"
      >
        {modalPdfUrl ? (
          <embed
            src={`${modalPdfUrl}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        ) : (
          <p>No document found</p>
        )}
      </Modal>
    </>
  );

  return component;
};

export default ComplianceChecksPage;
