import './FilterButton.scss';

import { Close, Tune } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import filter from 'assets/icons/filter-lines.svg';

interface FilterButtonProps {
  onClick: () => void;
  clearFilters: () => void;
  selectedCount: number;
  label?: string;
}

/**
 *
 * @param onClick: Function to be called when the button is clicked
 * @param clearFilters: Function to be called when the button is clicked
 * @param selectedCount: Number of selected filters
 * @param label: Label for the button
 *
 * @returns
 *  Filter Button Component with the filter icon, label, count of selected filters and close icon
 *
 */
const FilterButton: React.FC<FilterButtonProps> = ({
  onClick,
  clearFilters,
  selectedCount,
  label = 'Filters',
}) => {
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      className="filter-button"
      style={{ justifyContent: 'space-evenly', borderRadius: '5px' }}
    >
      {selectedCount > 0 && (
        <>
          <span className="filter-button__count">{selectedCount}</span>
        </>
      )}
      <span className="filter-button__label">{label}</span>
      <Tune className="filter-button__icon" />
      {selectedCount > 0 && (
        <IconButton
          className="filter-button__close-icon"
          onClick={(event) => {
            event.stopPropagation();
            clearFilters();
          }}
        >
          <Close
            className="filter-button__close-icon-button"
            style={{ height: '1rem', width: '1rem' }}
          />
        </IconButton>
      )}
    </div>
  );
};

export default FilterButton;
