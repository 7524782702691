import { severity } from 'app/lib/severityColorMap';
import { partners } from './partner';

export const transactionTypes = {
  PURCHASE: 'PURCHASE',
  LISTING: 'LISTING',
  LEASE_OFFER: 'LEASE_OFFER',
  LEASE_LISTING: 'LEASE_LISTING',
};

export const transactionMilestones = {
  PRE_CONTRACT: 'PRE_CONTRACT',
  UNDER_CONTRACT: 'UNDER_CONTRACT',
  CLOSING: 'CLOSING',
  CLOSED: 'CLOSED',
  CLOSED_FILE_COMPLETE: 'CLOSED_FILE_COMPLETE',
  TERMINATED: 'TERMINATED',
  FALL_THROUGH: 'FALL_THROUGH',
  WITHDRAWN: 'WITHDRAWN',
} as const;

export const transactionMilestonesMap = {
  PRE_CONTRACT: 'Pre-Contract',
  UNDER_CONTRACT: 'Under Contract',
  CLOSING: 'Closing',
  CLOSED: 'Closed',
  CLOSED_FILE_COMPLETE: 'Closed File Complete',
  TERMINATED: 'Terminated',
  FALL_THROUGH: 'Fall Through',
  WITHDRAWN: 'Withdrawn',
} as const;

export const excludedMilestonesPerPartnerPerTransactionType: any = {
  [partners.BAIRD_AND_WARNER]: {
    PURCHASE: ['CLOSED_FILE_COMPLETE'],
    LISTING: ['CLOSED_FILE_COMPLETE'],
    LEASE_LISTING: ['CLOSED_FILE_COMPLETE', 'CLOSING'],
    LEASE_OFFER: ['CLOSED_FILE_COMPLETE', 'CLOSING'],
  },
  [partners.KEYES]: {
    PURCHASE: [],
    LISTING: [],
    LEASE_LISTING: ['CLOSING'],
    LEASE_OFFER: ['CLOSING'],
  },
  [partners.BARRON_TEAM]: {
    PURCHASE: [],
    LISTING: [],
    LEASE_LISTING: ['CLOSING'],
    LEASE_OFFER: ['CLOSING'],
  },
  [partners.JMG]: {
    PURCHASE: [],
    LISTING: [],
    LEASE_LISTING: ['CLOSING'],
    LEASE_OFFER: ['CLOSING'],
  },
  [partners.MAXHOME]: {
    PURCHASE: [],
    LISTING: [],
    LEASE_LISTING: ['CLOSING'],
    LEASE_OFFER: ['CLOSING'],
  },
} as const;

export const getTransactionMilestonesForPartner = (
  partnerId: string | null
) => {
  if (!partnerId) return transactionMilestonesMap;
  const excludedMilestones =
    excludedMilestonesPerPartnerPerTransactionType[partnerId]?.['PURCHASE'] ||
    [];
  return Object.fromEntries(
    Object.entries(transactionMilestonesMap).filter(
      ([key]) => !excludedMilestones.includes(key)
    )
  );
};

export type TransactionMilestone = keyof typeof transactionMilestones;

export const milestoneToSeverity: Record<TransactionMilestone, severity> = {
  PRE_CONTRACT: 'pending',
  UNDER_CONTRACT: 'progress',
  CLOSING: 'progress',
  CLOSED: 'complete',
  CLOSED_FILE_COMPLETE: 'complete',
  TERMINATED: 'error',
  FALL_THROUGH: 'error',
  WITHDRAWN: 'error',
} as const;
