import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { severity } from 'app/lib/severityColorMap';

export const TASK_STATUS_TYPES = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  INCOMPLETE: 'INCOMPLETE',
  SYSTEM_CANCELLED: 'SYSTEM_CANCELLED',
};

interface TaskStatus {
  color: string;
  text: string;
  icon: typeof ClockCircleOutlined;
  severity: severity;
}

export const TaskStatusMap: Record<string, TaskStatus> = {
  [TASK_STATUS_TYPES.PENDING]: {
    color: '#1890ff',
    text: 'Pending',
    icon: ClockCircleOutlined,
    severity: 'ready',
  },
  [TASK_STATUS_TYPES.PROCESSING]: {
    color: '#1890ff',
    text: 'Processing',
    icon: SyncOutlined,
    severity: 'progress',
  },
  [TASK_STATUS_TYPES.COMPLETED]: {
    color: '#52c41a',
    text: 'Completed',
    icon: CheckCircleOutlined,
    severity: 'complete',
  },
  [TASK_STATUS_TYPES.CANCELLED]: {
    color: '#ff4d4f',
    text: 'Cancelled',
    icon: StopOutlined,
    severity: 'error',
  },
  [TASK_STATUS_TYPES.SYSTEM_CANCELLED]: {
    color: '#ff4d4f',
    text: 'System Cancelled',
    icon: StopOutlined,
    severity: 'error',
  },
  [TASK_STATUS_TYPES.FAILED]: {
    color: '#ff4d4f',
    text: 'Failed',
    icon: CloseCircleOutlined,
    severity: 'error',
  },
  [TASK_STATUS_TYPES.INCOMPLETE]: {
    color: '#faad14',
    text: 'Incomplete',
    icon: ExclamationCircleOutlined,
    severity: 'inactive',
  },
};

export default TaskStatusMap;
