import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'antd';
import { CloseRounded } from '@mui/icons-material';
import { ComplianceService } from 'app/services/compliance';
import { ComplianceTransaction } from 'app/interfaces/ComplianceTransaction';
import ErrorsDrawer from './ErrorsDrawer';
import { getCustomIdFromUrl } from 'app/utils/url';
import useTransactionDocumentErrorsManager from 'app/atomManagers/transactionDocumentErrorsManager';

const ErrorsStacker = () => {
  const location = useLocation();

  const errorsManager = useTransactionDocumentErrorsManager();
  const { errors, clearErrorsForTransaction } = errorsManager;
  const [isErrorsModalVisible, setIsErrorsModalVisible] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [transactionData, setTransactionData] = useState<ComplianceTransaction>(
    {} as ComplianceTransaction
  );

  const [bannerVisble, setBannerVisible] = useState(false);

  useEffect(() => {
    console.log('use effect triggered');

    // Get the transactionId from the URL: transactionID=<string>
    const transactionIdUrlParam = getCustomIdFromUrl('transactionID');
    setTransactionId(transactionIdUrlParam || '');

    // If transactionIdUrlParam exists and if there are errors, then show the banner else hide it
    if (transactionIdUrlParam && transactionIdUrlParam !== '') {
      // If there are errors for the transaction, then show the banner
      if (errorsStackedInTransactionCount(transactionIdUrlParam) > 0) {
        setBannerVisible(true);
      }
    } else {
      setBannerVisible(false);
    }

    // Get the transaction data from the transactionId
    ComplianceService.getTransactionByID(transactionIdUrlParam || '')
      .then((response) => {
        setTransactionData(response.data);
        console.log('Transaction ID: ', transactionIdUrlParam);
        console.log('Transaction Data: ', response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [location, errors]); // Changed to use location object directly

  const errorsStackedInTransactionCount = (transactionId: string) => {
    let errorCount = 0;
    if (
      transactionId &&
      transactionId !== '' &&
      errors &&
      errors[transactionId]
    ) {
      Object.keys(errors[transactionId]).forEach((documentType) => {
        errorCount += (errors[transactionId][documentType] || []).length;
      });
    }
    return errorCount;
  };

  const resetTransactionErrors = () => {
    clearErrorsForTransaction(transactionId);
    setBannerVisible(false);
  };

  return (
    <>
      {bannerVisble &&
        transactionId !== '' &&
        transactionData &&
        errors?.[transactionId] && (
          <Box
            display="flex"
            width="100%"
            color="white"
            bgcolor="#2F78EB"
            padding="0.5rem"
            alignItems="center"
          >
            <Typography
              variant="h6"
              style={{ flexGrow: 1, textAlign: 'center' }}
            >
              {Object.keys(errors).length > 0 &&
                (() => {
                  const getErrorCount = (transactionErrors: any) => {
                    return Object.keys(transactionErrors).reduce(
                      (acc, key) =>
                        key === '_missingDocuments'
                          ? acc + 1
                          : acc + transactionErrors[key].length,
                      0
                    );
                  };

                  const count = getErrorCount(errors[transactionId]);
                  return `Reviewing ${count} ${count === 1 ? 'Error' : 'Errors'}: Action Required`;
                })()}
            </Typography>
            <Button
              style={{ margin: '0.25rem 1rem' }}
              onClick={() => {
                setIsErrorsModalVisible(true);
              }}
            >
              View Errors
            </Button>
            <Tooltip title="Clear Stacked Errors">
              <IconButton
                onClick={() => resetTransactionErrors()}
                style={{ color: 'white', marginRight: '1rem' }}
              >
                <CloseRounded />
              </IconButton>
            </Tooltip>
          </Box>
        )}

      <ErrorsDrawer
        visible={isErrorsModalVisible}
        onClose={() => {
          setIsErrorsModalVisible(false);
        }}
        transactionData={transactionData}
      />
    </>
  );
};

export default ErrorsStacker;
