import React, { useState } from 'react';
import {
  Modal,
  Typography,
  Button,
  Tooltip,
  Input,
  message,
  Space,
  Checkbox,
  DatePicker,
  MenuProps,
  Dropdown,
  Spin,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  ArrowRightOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { TaskService } from '../services';
import '../styles/TaskPreviewModal.scss';
import { AppURLs } from 'app/utils/appURLs';
import { ComplianceService } from 'app/services/compliance';
import dayjs from 'dayjs';
import TaskStatusBadge from './TaskStatusBadge';
import { Edit, PlusCircle } from 'lucide-react';

const { Text } = Typography;

interface SOTFieldEditorProps {
  transactionId: string;
  emptyFields: string[];
  onFieldUpdate?: () => void;
}

interface TextTaskEditorProps {
  task: any;
  onComplete: (text: string) => Promise<void>;
}

interface TaskPreviewModalProps {
  task: any;
  onClose: () => void;
  fetchTasks: () => void;
  getActionMenu: (task: any) => MenuProps['items'];
  loader: boolean;
}

interface ChecklistTaskEditorProps {
  task: any;
  onComplete: (text: string) => Promise<void>;
}

const modalTitle = (task: any) => <TaskStatusBadge status={task.status} />;

const modalActions = (
  task: any,
  getActionMenu: (task: any) => MenuProps['items']
) => (
  <div className="flex items-center justify-between py-2">
    <div className="flex items-center gap-2">
      <span className="text-gray-500 text-base">Task:</span>
      <span className="font-medium text-base">{task.name}</span>
    </div>
    <Dropdown menu={{ items: getActionMenu(task) }} trigger={['click']}>
      <Button>
        <div className="flex items-center gap-2">
          Select action
          <MoreOutlined />
        </div>
      </Button>
    </Dropdown>
  </div>
);
const taskErrorComponent = (task: any) =>
  task?.taskErrors &&
  Object.keys(task.taskErrors).length > 0 && (
    <div className="task-preview-modal__section">
      <div className="section-header">
        <Typography.Title level={5} style={{ fontWeight: 500 }}>
          Task Errors
          <a
            href={AppURLs.complianceDashboardTransactionFacts(
              task.transactionId
            )}
            target="_blank"
            rel="noopener noreferrer"
            className="navigate-link"
          >
            <ArrowRightOutlined />
          </a>
        </Typography.Title>
      </div>
      <div className="task-preview-modal__error">
        {Object.values(task.taskErrors).map((error: any, index: number) => (
          <div key={index} className="error-item">
            <ExclamationCircleOutlined className="error-icon" />
            {error?.description}
          </div>
        ))}
      </div>
    </div>
  );

const TextTaskEditor: React.FC<TextTaskEditorProps> = ({
  task,
  onComplete,
}) => {
  const [text, setText] = useState(
    task.status === 'COMPLETED' ? task.metaData?.completedData?.text || '' : ''
  );
  const [loading, setLoading] = useState(false);
  const isCompleted = task.status === 'COMPLETED';

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onComplete(text);
      message.success('Task completed successfully');
    } catch (error: any) {
      message.error(error.message || 'Failed to complete task');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="text-task-editor">
      <Input.TextArea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Enter your response"
        rows={4}
        disabled={loading || isCompleted}
        style={{ width: '-webkit-fill-available' }}
      />
      <Button
        type="primary"
        onClick={handleSubmit}
        loading={loading}
        disabled={!text.trim() || isCompleted}
        className="mt-4"
      >
        Complete Task
      </Button>
    </div>
  );
};

const ChecklistTaskEditor: React.FC<ChecklistTaskEditorProps> = ({
  task,
  onComplete,
}) => {
  const [text, setText] = useState(
    task.status === 'COMPLETED' ? task.metaData?.completedData?.text || '' : ''
  );
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const isCompleted = task.status === 'COMPLETED';

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onComplete(text);
      message.success('Task completed successfully');
    } catch (error: any) {
      message.error(error.message || 'Failed to complete task');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="checklist-task-editor">
      <div className="mb-2">
        <Checkbox
          checked={isCompleted || isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
          disabled={isCompleted}
        >
          {task.metaData.subject}
        </Checkbox>
      </div>
      <Input.TextArea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Additional comments (optional)"
        rows={3}
        className="mt-4"
        disabled={loading || isCompleted}
        style={{ width: '-webkit-fill-available' }}
      />
      <Button
        type="primary"
        onClick={handleSubmit}
        loading={loading}
        disabled={!isChecked || isCompleted}
        className="mt-4"
      >
        Complete Task
      </Button>
    </div>
  );
};

export const SOTFieldEditor: React.FC<SOTFieldEditorProps> = ({
  transactionId,
  emptyFields,
  onFieldUpdate,
}) => {
  const [fieldValues, setFieldValues] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  const formatFieldName = (field: string) => {
    return field
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleFieldChange = (field: string, value: string) => {
    setFieldValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Check if all required fields are filled
  const areAllFieldsFilled = () => {
    return emptyFields.every((field) => {
      const value = fieldValues[field];
      return value && value.trim().length > 0;
    });
  };

  const handleSaveAll = async () => {
    if (!areAllFieldsFilled()) {
      const missingFields = emptyFields
        .filter((field) => !fieldValues[field]?.trim())
        .map(formatFieldName);
      message.warning(
        `Please fill in all required fields: ${missingFields.join(', ')}`
      );
      return;
    }

    setLoading(true);
    try {
      // Create an array of updates for bulk update
      const updates = Object.entries(fieldValues).map(([fieldKey, value]) => ({
        fieldKey,
        value,
      }));

      await ComplianceService.bulkUpdateSourceOfTruth(transactionId, updates);
      message.success('All fields updated successfully');
      onFieldUpdate?.();
    } catch (error: any) {
      message.error(error.message || 'Failed to update fields');
    } finally {
      setLoading(false);
    }
  };
  const renderField = (field: string) => {
    if (field === 'homeInspectionDate') {
      return (
        <div key={field} className="sot-field-item">
          <div className="field-header">
            <Text strong>{formatFieldName(field)}:</Text>
          </div>
          <div className="field-value">
            <DatePicker
              value={fieldValues[field] ? dayjs(fieldValues[field]) : null}
              onChange={(date) =>
                handleFieldChange(field, date ? date.format('MM-DD-YYYY') : '')
              }
              format="MM-DD-YYYY"
              placeholder={`Select ${formatFieldName(field)}`}
              disabled={loading}
              style={{ width: '100%' }}
              status={!fieldValues[field]?.trim() ? 'error' : ''}
            />
          </div>
        </div>
      );
    }

    return (
      <div key={field} className="sot-field-item">
        <div className="field-header">
          <Text strong>{formatFieldName(field)}:</Text>
        </div>
        <div className="field-value">
          <Input
            value={fieldValues[field] || ''}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            placeholder={`Enter ${formatFieldName(field)}`}
            disabled={loading}
            status={!fieldValues[field]?.trim() ? 'error' : ''}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="sot-field-editor">
      <div className="sot-field-editor__header">
        <Space
          align="center"
          className="flex items-center justify-between mb-4"
        >
          <Text
            type="warning"
            className={`text-sm ${areAllFieldsFilled() ? 'opacity-0' : 'opacity-100'}`}
          >
            Please fill in all required fields
          </Text>
          <Button
            type="primary"
            onClick={handleSaveAll}
            loading={loading}
            disabled={!areAllFieldsFilled() || emptyFields.length === 0}
          >
            Save All Changes
          </Button>
        </Space>
      </div>
      <div className="sot-field-editor__fields">
        {emptyFields.map((field) => renderField(field))}
      </div>
    </div>
  );
};

const TaskPreviewModal: React.FC<TaskPreviewModalProps> = ({
  task,
  fetchTasks,
  onClose,
  getActionMenu,
  loader,
}) => {
  const [editingSubject, setEditingSubject] = useState(false);
  const [editingRecipients, setEditingRecipients] = useState(false);
  const [recipients, setRecipients] = useState<string[]>(
    task?.recipient?.filter(Boolean) || []
  );
  const [subject, setSubject] = useState(task?.emailSubject);
  const [loading, setLoading] = useState(false);

  const handleSaveRecipients = async () => {
    setLoading(true);
    try {
      const updatedRecipients = recipients.filter(Boolean);
      await TaskService.updateTaskRecipients(task._id, updatedRecipients);
      setEditingRecipients(false);
      message.success('Recipients updated successfully');
      fetchTasks();
    } catch (error) {
      message.error('Failed to update recipients');
    } finally {
      setLoading(false);
    }
  };

  const handleRecipientsChange = (value: string) => {
    const emailList = value.split(',').map((email) => email.trim());
    // Remove the special handling of primary recipient
    setRecipients(emailList);
  };

  const handleSaveSubject = async () => {
    setLoading(true);
    try {
      await TaskService.updateTaskMetadata(task._id, { subject: subject });
      setEditingSubject(false);
      message.success('Subject updated successfully');
      fetchTasks();
    } catch (error) {
      message.error('Failed to update subject');
    } finally {
      setLoading(false);
    }
  };

  if (task.type === 'SOT_TASK') {
    return (
      <Modal
        title={modalTitle(task)}
        open={true}
        onCancel={onClose}
        width="75vw"
        footer={null}
        className="task-preview-modal"
      >
        <Spin spinning={loader}>
          <div className="task-preview-modal__content">
            {modalActions(task, getActionMenu)}
            <SOTFieldEditor
              transactionId={task.transactionId}
              emptyFields={task.metaData.sotFields.emptyFields}
              onFieldUpdate={async () => {
                fetchTasks();
                onClose();
              }}
            />
          </div>
        </Spin>
      </Modal>
    );
  }

  if (task.type === 'TEXT_TASK') {
    return (
      <Modal
        title={modalTitle(task)}
        open={true}
        onCancel={onClose}
        width="75vw"
        footer={null}
        className="task-preview-modal"
      >
        <Spin spinning={loader}>
          <div className="task-preview-modal__content">
            {modalActions(task, getActionMenu)}
            <TextTaskEditor
              task={task}
              onComplete={async (text) => {
                await TaskService.completeManualTask(task._id, { text });
                fetchTasks();
                onClose();
              }}
            />
          </div>
        </Spin>
      </Modal>
    );
  }

  if (task.type === 'CHECKLIST_TASK') {
    return (
      <Modal
        title={modalTitle(task)}
        open={true}
        onCancel={onClose}
        width="75vw"
        footer={null}
        className="task-preview-modal"
      >
        <Spin spinning={loader}>
          <div className="task-preview-modal__content">
            {modalActions(task, getActionMenu)}
            <ChecklistTaskEditor
              task={task}
              onComplete={async (text) => {
                await TaskService.completeManualTask(task._id, { text });
                fetchTasks();
                onClose();
              }}
            />
          </div>
        </Spin>
      </Modal>
    );
  }

  if (task.type === 'UPLOAD_DOC_TASK') {
    return (
      <Modal
        title={modalTitle(task)}
        open={true}
        onCancel={onClose}
        width="75vw"
        footer={null}
        className="task-preview-modal"
      >
        <Spin spinning={loader}>
          <div className="task-preview-modal__content">
            {modalActions(task, getActionMenu)}
            {taskErrorComponent(task)}
            <Button
              type="primary"
              onClick={() =>
                (window.location.href = AppURLs.agentDashboardTransaction(
                  task.transactionId,
                  true
                ))
              }
              disabled={task.status === 'COMPLETED'}
            >
              Upload Documents
            </Button>
          </div>
        </Spin>
      </Modal>
    );
  }

  return (
    <Modal
      title={modalTitle(task)}
      open={true}
      onCancel={onClose}
      width="75vw"
      footer={null}
      className="task-preview-modal"
    >
      <Spin spinning={loader}>
        <div className="task-preview-modal__content">
          {modalActions(task, getActionMenu)}
          {task?.recipient && (
            <div className="task-preview-modal__email-header">
              <div className="header-row">
                <Text strong>Recipients:</Text>
                {editingRecipients ? (
                  <div className="edit-field">
                    <Input
                      value={recipients.join(', ')}
                      onChange={(e) => handleRecipientsChange(e.target.value)}
                      placeholder="Enter email addresses separated by commas"
                      disabled={false}
                    />
                    <Button
                      type="text"
                      icon={<CheckOutlined />}
                      onClick={handleSaveRecipients}
                      loading={loading}
                      className="action-button save"
                    />
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={() => {
                        setEditingRecipients(false);
                        setRecipients(task?.recipient?.filter(Boolean) || []);
                      }}
                      className="action-button cancel"
                    />
                  </div>
                ) : (
                  <>
                    <Text>{recipients.join(', ')}</Text>
                    <Tooltip title="Edit Recipients">
                      <Button
                        type="text"
                        icon={<PlusCircle size={22} />}
                        onClick={() => setEditingRecipients(true)}
                        className="edit-button"
                        size="small"
                      />
                    </Tooltip>
                  </>
                )}
              </div>
              {task.emailSubject && (
                <div className="header-row">
                  <Text strong>Subject:</Text>
                  {editingSubject ? (
                    <div className="edit-field">
                      <Input
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        placeholder="Enter email subject"
                      />
                      <Button
                        type="text"
                        icon={<CheckOutlined />}
                        onClick={handleSaveSubject}
                        loading={loading}
                        className="action-button save"
                      />
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={() => {
                          setEditingSubject(false);
                          setSubject(task?.emailSubject);
                        }}
                        className="action-button cancel"
                      />
                    </div>
                  ) : (
                    <>
                      <Text>{subject}</Text>
                      <Tooltip title="Edit Subject">
                        <Button
                          type="text"
                          size="small"
                          icon={<Edit size={22} />}
                          onClick={() => setEditingSubject(true)}
                          className="edit-button"
                        />
                      </Tooltip>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
          {taskErrorComponent(task)}
          {task?.htmlContent && (
            <div className="task-preview-modal__html-content">
              <div
                className="html-content-wrapper"
                dangerouslySetInnerHTML={{ __html: task?.htmlContent }}
              />
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default TaskPreviewModal;
