import { AppURLs } from 'app/utils/appURLs';
import api from 'app/utils/api';
import { removeAuthToken } from 'app/utils/localStorageHandler/auth';

export class UserService {
  public async validateInvite(inviteCode: string) {
    return api.get(`/user/validate-invite/${inviteCode}`);
  }

  public async createAgentAccount(
    name: string,
    phone: string,
    password: string,
    inviteCode: string
  ) {
    return api.post(`/user/signup`, {
      name,
      phone,
      password,
      inviteCode,
      role: 'Agent',
    });
  }

  public static createUser(formData: {
    name: string;
    email: string;
    rolesList: string[];
    partnerID: string;
    officeID: string | null;
  }) {
    return api.post('/user/create-user', formData);
  }

  public async userLogin(email: string, password: string) {
    return api.post(`/user/login`, {
      email,
      password,
    });
  }

  public userLogout() {
    // TODO: In case API call is required, add it
    removeAuthToken();
    // window.location.href = '/v2/agent/login';
    window.location.href = AppURLs.agentLogin();
  }

  public async getUserProfile() {
    return api.get(`/user/profile`);
  }

  public static getRoles(partnerId?: string) {
    const query = partnerId ? `?partnerId=${partnerId}` : '';
    return api.get(`/user/roles/${query}`);
  }

  static async updateUserDataTracker() {
    return api.patch(`/user/data-tracking`);
  }

  public async registerUserFCMToken(token: string, device: string) {
    return api.post(`/user/register-fcm-token`, {
      token,
      device,
    });
  }
}
