import React, { createContext } from 'react';

export const GlobalContext = createContext<any | undefined>(undefined);

export class GlobalProvider extends React.Component<
  { children: React.ReactNode },
  any
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = {
      checkResponse: null,
      getCheckResponse: this.getCheckResponse,
      setCheckResponse: this.setCheckResponse,
    };
  }

  getCheckResponse = (): any => {
    return this.state.checkResponse;
  };

  setCheckResponse = (response: any): void => {
    this.setState({ checkResponse: response });
  };

  render() {
    return (
      <GlobalContext.Provider value={this.state}>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

export function useGlobalContext() {
  const context = React.useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
}
