import React from 'react';
import { Button } from 'antd';
import './styles.scss';
import { MessageCircle } from 'lucide-react';

interface FloatingButtonProps {
  onClick: () => void;
  position?: 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left';
}

const FloatingButton: React.FC<FloatingButtonProps> = ({
  onClick,
  position = 'bottom-right',
}) => {
  return (
    <div className={`floating-remarks-button ${position}`}>
      <Button
        type="primary"
        shape="round"
        size="large"
        icon={<MessageCircle size={22} />}
        onClick={onClick}
      >
        Remarks
      </Button>
    </div>
  );
};

export default FloatingButton;
