import { AppURLs } from './appURLs';
import axios from 'axios';
import environment from 'app/config/environment';
import { getFirebaseAccessToken } from './firebase';

const api = axios.create({
  // baseURL: 'https://maxhome.ai/api',
  baseURL: environment.api.API_BASE_URL,
});

const pendingRequests = new Map();

const getRequestKey = (config: any) => {
  const { url, method } = config;
  // Remove params
  const baseUrl = url.split('?')[0];
  return `${method}:${baseUrl}`;
};

api.interceptors.request.use(
  async (config: any) => {
    const token = await getFirebaseAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    if (
      config?.method?.toLowerCase() === 'get' &&
      !config?.url?.includes('/user/profile') &&
      !config?.url?.includes('/office')
    ) {
      const requestKey = getRequestKey(config);

      // Cancel previous requests with the same signature
      const pending = pendingRequests.get(requestKey);
      if (pending) {
        console.log('Cancelling previous request:', requestKey);
        pending.cancel('Request superseded by newer request');
        pendingRequests.delete(requestKey);
      }

      // Create cancel token
      const cancelTokenSource = axios.CancelToken.source();
      config.cancelToken = cancelTokenSource.token;

      // Store the cancel function
      pendingRequests.set(requestKey, {
        cancel: cancelTokenSource.cancel,
      });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    const requestKey = getRequestKey(response.config);
    pendingRequests.delete(requestKey);
    return response;
  },
  (error) => {
    if (error.config) {
      const requestKey = getRequestKey(error.config);
      pendingRequests.delete(requestKey);
    }

    if (axios.isCancel(error)) {
      console.log('Request cancelled:', error.message);
      return new Promise(() => {});
    }

    if (error.response && error.response.status === 401) {
      localStorage.removeItem('authToken');
      if (error.config.url.includes('/compliance/')) {
        window.location.href = AppURLs.agentLoginSessionExpired();
      } else if (error.config.url.includes('/agent/')) {
        window.location.href = AppURLs.agentLoginSessionExpired();
      } else if (error.config.url.includes('/office-admin')) {
        window.location.href = AppURLs.agentLoginSessionExpired();
      }
    }
    return Promise.reject(error);
  }
);

export default api;
