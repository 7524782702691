import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  Button,
  Dropdown,
  message,
  Pagination,
  Spin,
  Empty,
  DatePicker,
} from 'antd';
import {
  MoreOutlined,
  SendOutlined,
  StopOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import type { MenuProps } from 'antd';
import { getSimpleDateString } from 'app/utils/datetime';
import { getCustomIdFromUrl } from 'app/utils/url';
import { getAdminProfile } from 'app/utils/localStorageHandler/userProfile';
import { AppURLs } from 'app/utils/appURLs';
import { TaskService } from '../services';
import TaskPreviewModal from './TaskPreviewModal';
import TaskStatusBadge from './TaskStatusBadge';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CompactTasksCard from 'app/AgentDetails/CompactTasksCard';

import '../styles/TaskTable.scss';

dayjs.extend(utc);
interface TaskTableProps {
  roles?: string[];
  view?: 'table' | 'card';
  limit?: number;
}

interface LoadingState {
  data: boolean;
  modal: boolean;
}

const TaskTable: React.FC<TaskTableProps> = ({
  roles,
  view = 'table',
  limit = 10,
}) => {
  const navigate = useNavigate();
  const transactionID = getCustomIdFromUrl('transactionID');

  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [tasks, setTasks] = useState<any>([]);
  const [loading, setLoading] = useState<LoadingState>({
    data: false,
    modal: false,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: limit,
    total: 0,
  });

  const fetchTasks = async (page?: number, pageSize?: number) => {
    setLoading({ ...loading, data: true });
    try {
      // Use transaction ID if available, otherwise fetch by trigger date
      if (transactionID) {
        const result: any = await TaskService.getTasksByTransaction(
          transactionID,
          roles?.join(',')
        );
        setTasks(result?.data?.tasks);
      } else {
        const result: any = await TaskService.getTasksByTriggerDate(
          {
            page: page || pagination.current,
            limit: pageSize || pagination.pageSize,
          },
          roles?.join(',')
        );
        console.log('result', result);
        setTasks(result?.data?.tasks || []);
        setPagination({
          ...pagination,
          current: page || pagination.current,
          pageSize: pageSize || pagination.pageSize,
          total: result?.data?.total,
        });
      }
    } catch (error) {
      message.error('Failed to fetch tasks');
    } finally {
      setLoading({ ...loading, data: false });
    }
  };

  // Action handlers
  const handleMenuClick = async (action: string, task: any) => {
    setLoading({ ...loading, data: true, modal: true });
    try {
      switch (action) {
        case 'sendNow':
          await TaskService.sendEmailInstantly(task._id);
          message.success('Email sent successfully');
          break;
        case 'cancel':
          await TaskService.updateTaskStatus(task._id, 'CANCELLED');
          message.success('Task cancelled successfully');
          break;
        case 'delete':
          await TaskService.deleteTask(task._id);
          message.success('Task deleted successfully');
          break;
      }
      setSelectedTask(null);
      await fetchTasks();
    } catch (error) {
      message.error('Failed to process task action');
      console.error('Task action error:', error);
    } finally {
      setLoading({ ...loading, data: false, modal: false });
    }
  };

  const getActionMenu = (task: any): MenuProps['items'] => [
    {
      key: 'sendNow',
      label: 'Send Now',
      icon: <SendOutlined />,
      className: 'task-action-send no-preview',
      onClick: () => handleMenuClick('sendNow', task),
    },
    {
      type: 'divider',
    },
    {
      key: 'cancel',
      label: 'Cancel Email',
      icon: <StopOutlined />,
      className: 'task-action-cancel no-preview',
      danger: true,
      disabled: task.status === 'CANCELLED',
      onClick: () => handleMenuClick('cancel', task),
    },
    {
      type: 'divider',
    },
    ...(getAdminProfile()
      ? [
          {
            key: 'delete',
            label: 'Delete Task',
            icon: <DeleteOutlined />,
            className: 'task-action-delete no-preview',
            danger: true,
            onClick: () => handleMenuClick('delete', task),
          },
        ]
      : []),
  ];

  const handleDateChange = async (taskId: string, date: dayjs.Dayjs | null) => {
    if (!date) return;
    setLoading({ ...loading, data: true });
    try {
      const localDate = date.startOf('day');
      await TaskService.updateTaskTriggerDate(taskId, localDate);
      message.success('Task date updated successfully');
      await fetchTasks();
    } catch (error) {
      message.error('Failed to update task date');
    } finally {
      setLoading({ ...loading, data: false });
    }
  };

  const getScheduledSentDisplay = (record: any) => {
    const date = record?.completedAt
      ? getSimpleDateString(record?.completedAt)
      : !record?.trigger?.date
        ? 'To be decided'
        : getSimpleDateString(record?.trigger?.date);

    if (record?.status === 'COMPLETED' || record?.status === 'CANCELLED') {
      return <span className="pl-3 text-[14px]">{date}</span>;
    }

    return (
      <div className="no-preview">
        <DatePicker
          defaultValue={
            record?.trigger?.date
              ? dayjs.utc(record.trigger.date) // UTC is used to have a consistent date across different timezones
              : undefined
          }
          onChange={(newDate) => handleDateChange(record._id, newDate)}
          bordered={false}
          suffixIcon={<EditOutlined />}
          format="MMMM D, YYYY"
          style={{ minWidth: 160 }}
          dropdownClassName="no-preview"
          allowClear={false}
          placeholder="To be decided"
          disabledDate={(current) =>
            current && current < dayjs().startOf('day')
          }
        />
      </div>
    );
  };

  const handleRowClick = (record: any, e: React.MouseEvent) => {
    if (!(e.target as Element).closest('.no-preview')) {
      setSelectedTask(record);
    }
  };

  const columns: ColumnsType<any> = [
    ...(!transactionID
      ? [
          {
            title: 'Address',
            key: 'propertyAddress',
            width: '30%',
            render: (_: any, record: any) =>
              record?.metaData?.data?.propertyAddress,
          },
        ]
      : []),
    {
      title: 'Task',
      key: 'description',
      width: '30%',
      render: (_, record) => record?.metaData?.description,
    },
    {
      title: 'Scheduled/Sent',
      dataIndex: ['trigger', 'date'],
      key: 'scheduledDate',
      width: '20%',
      render: (_, record) => getScheduledSentDisplay(record),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status) => <TaskStatusBadge status={status} />,
    },
    {
      title: 'Actions',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <div className="no-preview">
          <Dropdown
            menu={{ items: getActionMenu(record) }}
            trigger={['click']}
            placement="bottomRight"
          >
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        </div>
      ),
    },
  ];

  // View rendering
  const renderCardView = () => {
    return (
      <Spin spinning={loading.data}>
        {tasks.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-medium mb-2">Your Tasks</h2>
            <div className="text-sm text-gray-600">
              You have a total of{' '}
              <span className="text-blue-500 font-medium">
                {pagination.total} tasks
              </span>{' '}
              across your transactions.
            </div>
          </div>
        )}
        <div className="flex justify-between items-center mb-6 mt-1">
          <span className="text-xl font-semibold text-[#003F88]">
            DUE TODAY & UPCOMING
          </span>
          <Button
            variant="outlined"
            className="rounded-3xl normal-case text-black bg-white border-black h-9"
            onClick={() => navigate(AppURLs.agentDashboardMainTasks())}
          >
            View All
          </Button>
        </div>
        <div className="flex flex-col gap-4">
          {tasks.length > 0 ? (
            tasks.map((task: any) => (
              <div
                key={task._id}
                onClick={(e) => handleRowClick(task, e)}
                className="cursor-pointer block w-full hover:shadow-lg transition-shadow duration-300 rounded-lg"
              >
                <CompactTasksCard task={task} getActionMenu={getActionMenu} />
              </div>
            ))
          ) : (
            <div className="w-full">
              <Empty description="No Upcoming Tasks!" />
            </div>
          )}
          {tasks.length ? (
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              defaultCurrent={1}
              total={pagination.total}
              onChange={(page, pageSize) => fetchTasks(page, pageSize)}
              className="mt-4 flex justify-center"
            />
          ) : null}
        </div>
      </Spin>
    );
  };

  const renderTaskList = () => {
    if (view === 'card') {
      return renderCardView();
    }

    return (
      <Table
        loading={loading.data}
        columns={columns}
        dataSource={tasks}
        pagination={
          !transactionID
            ? {
                ...pagination,
                onChange: (page, pageSize) => fetchTasks(page, pageSize),
              }
            : false
        }
        rowKey="_id"
        onRow={(record) => ({
          onClick: (e) => handleRowClick(record, e),
        })}
      />
    );
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <div className="task-table">
      {renderTaskList()}
      {selectedTask && (
        <TaskPreviewModal
          task={{
            _id: selectedTask?._id,
            name: selectedTask?.metaData.description,
            status: selectedTask?.status,
            scheduledDate: selectedTask?.trigger.date,
            recipient: selectedTask?.recipient,
            emailSubject: selectedTask?.metaData?.subject,
            htmlContent: selectedTask?.metaData?.body,
            taskErrors: selectedTask?.taskErrors,
            type: selectedTask?.type,
            metaData: selectedTask?.metaData,
            transactionId: selectedTask?.transactionId,
            // Add other required fields
          }}
          onClose={() => setSelectedTask(null)}
          fetchTasks={fetchTasks}
          getActionMenu={getActionMenu}
          loader={loading.modal}
        />
      )}
    </div>
  );
};

export default TaskTable;
