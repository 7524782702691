export const TRANSACTION_TYPE_OPTIONS = (type: string) => {
  switch (type) {
    case 'PURCHASE':
      return 'Buy';
    case 'LISTING':
      return 'List';
    case 'LEASE_OFFER':
      return 'Lease Offer';
    case 'LEASE_LISTING':
      return 'Lease List';
    default:
      return type || '-';
  }
};

export const TRANSACTION_TYPE_MAP =
  process.env.NODE_ENV === 'test'
    ? {
        PURCHASE: 'Buy',
        LISTING: 'List',
        LEASE_OFFER: 'Lease Offer',
        LEASE_LISTING: 'Lease List',
      }
    : {
        PURCHASE: 'Buy',
        LISTING: 'List',
      };
