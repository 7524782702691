import './ErrorCard.scss';

import { Box, IconButton, Typography } from '@mui/material';
import { Button, Card, Tooltip, message } from 'antd';
import { CheckRounded, CloseRounded, FlagOutlined } from '@mui/icons-material';

import { ErrorActions } from '../utils/ErrorActions';
import { ErrorSeverityChips } from './ErrorSeverityChip';
import { useState } from 'react';

export const ErrorCardComponent = ({
  error,
  index,
  onClick = () => {},
  selectedError = {},
  errorActionsList = [],
  setErrorActionsList = {},
  verifyDocumentView = true,
}: {
  error: any;
  index: number;
  onClick?: any;
  selectedError?: any;
  errorActionsList?: any;
  setErrorActionsList?: any;
  verifyDocumentView?: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Card
      onClick={onClick}
      style={{
        cursor: 'pointer',
        borderColor: `${selectedError.name === error.name ? '#2F78EB' : 'white'}`,
        color: 'black',
        backgroundColor: isHovered
          ? error?.severity === 'optional'
            ? '#fff3e6'
            : '#ffefea'
          : 'white',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip
      // title={
      //   (error?.severity &&
      //     capitalizeFirstLetter(error?.severity?.toLowerCase())) ||
      //   'Required'
      // }
      >
        {ErrorSeverityChips[error?.severity || 'required']}
        <Box
          display="flex"
          margin="0.5rem 0rem 0.5rem 0rem"
          alignItems="center"
        >
          <Typography fontSize="1.1rem" fontWeight={600}>
            {error.errorTitle}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" marginBottom="0.5rem">
          <Typography fontSize="0.8rem" fontWeight={400}>
            <div
              dangerouslySetInnerHTML={{
                __html: error?.descriptionFormattedString || error?.description,
              }}
            />
          </Typography>
        </Box>

        {verifyDocumentView && (
          <Box
            className="error-card-actions"
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Box display="flex">
              <Button
                shape="round"
                type={
                  errorActionsList.length > 0 &&
                  errorActionsList?.[index]?.action === ErrorActions.IGNORE
                    ? 'primary'
                    : 'default'
                }
                style={
                  errorActionsList.length > 0 &&
                  errorActionsList?.[index]?.action === ErrorActions.IGNORE
                    ? { backgroundColor: '#2F78EB', color: 'white' }
                    : undefined
                }
                className="error-card-button"
                onClick={() => {
                  console.log('Ignoring error: ', error);
                  let newErrorActions = [...errorActionsList];
                  newErrorActions[index] = { action: ErrorActions.IGNORE };
                  console.log('New Error Actions: ', newErrorActions);
                  setErrorActionsList(newErrorActions);
                }}
              >
                Ignore
              </Button>
              <Button
                shape="round"
                type={
                  errorActionsList.length > 0 &&
                  errorActionsList?.[index]?.action === ErrorActions.FIX
                    ? 'primary'
                    : 'default'
                }
                style={
                  errorActionsList.length > 0 &&
                  errorActionsList?.[index]?.action === ErrorActions.FIX
                    ? { backgroundColor: '#2F78EB', color: 'white' }
                    : undefined
                }
                className="error-card-button"
                onClick={() => {
                  console.log('Fixing error: ', error);
                  let newErrorActions = [...errorActionsList];
                  newErrorActions[index] = { action: ErrorActions.FIX };
                  console.log('New Error Actions: ', newErrorActions);
                  setErrorActionsList(newErrorActions);
                }}
              >
                Fix
              </Button>
            </Box>
            <IconButton
              className="flag-button"
              onClick={() => {
                message.info('Max will look into this issue');
              }}
            >
              <FlagOutlined />
            </IconButton>
          </Box>
        )}
      </Tooltip>
    </Card>
  );
};

export default ErrorCardComponent;
