import './index.scss';

import {
  Box,
  Chip,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Empty,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Spin,
  Table,
  Upload,
  UploadFile,
  message,
} from 'antd';
import {
  CheckCircleOutlined,
  DownloadOutlined,
  FileOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Delete,
  Edit as EditIcon,
} from '@mui/icons-material';
import { ContentCopy, ErrorRounded } from '@mui/icons-material';
import {
  REVIEW_STATUS_OPTIONS,
  StatusChip,
} from './components/ReviewStatusChip';
import {
  complianceTransactionStatusMap,
  transactionProcessingStatusMap,
} from '../../components/colorScheme';
import {
  getAdminProfile,
  getCompliancePartnerID,
} from 'app/utils/localStorageHandler/userProfile';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { AppURLs } from 'app/utils/appURLs';
import { ComplianceService } from 'app/services/compliance';
import { Document } from 'app/interfaces/Document';
import DotloopSyncStatusBar from 'app/components/common/DotloopSyncStatusBar';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { Info, ListPlus, Loader2, RefreshCw } from 'lucide-react';
import LoadingProgressBar from 'app/components/common/LoadingProgressBar';
import Remarks from 'app/modules/remarks';
import { TRANSACTION_TYPE_OPTIONS } from '../utils/transactionTypeMap';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import { capitalizeFirstLetter } from 'app/utils/string';
import { documentIDsAtom } from 'app/atoms';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getCustomIdFromUrl } from 'app/utils/url';
import { getSimpleDateString } from 'app/utils/datetime';
import { partners } from 'app/configs/partner';
import { setDocumentIDsInLocalStorage } from 'app/utils/localStorageHandler/document';
import {
  excludedMilestonesPerPartnerPerTransactionType,
  transactionMilestones,
} from 'app/configs/transaction';
import { transactionSourceAtom } from 'app/atomManagers/transactionSourceAtom';
import useDebounce from 'app/hooks/UseDebounce';
import { useFlags } from 'flagsmith/react';
import useTransactionDocumentErrorsManager from 'app/atomManagers/transactionDocumentErrorsManager';
import { TAG_ENTITY_TYPES } from 'app/components/common/Tags/tagUtils';
import { useEntityTags } from 'app/components/common/Tags/useTags';
import TagDisplay from 'app/components/common/Tags/tagDisplay';

const { Option } = Select;

// TODO: Move these interfaces to the interface folder
interface Loop {
  _id: string;
  syncingTransactionWithDotloop?: boolean;
  dotloopSyncStartedAt?: number;
  dotloopSyncCompletedAt?: number;
  data: Record<string, any>;
  placeId?: string | null;
  transactionId?: string;
}

interface TransactionDetailsProps {
  _id: string;
  name: string;
  clientName: string;
  status: string;
  type: string;
  documents: Document[];
  sourceOfTruth: Record<string, any>; // TODO: Once the schema is final, write a proper interface for this
  realtorAgentID: string;
  realtorAgent: Record<string, any>;
  createdAt: string;
  updatedAt: string;
  agents: Record<string, any>[];
  processingStatus: any;
  closingDate?: string;
  externalSourceID?: string;
  milestone?: string;
  loop?: Loop;
}

interface TransactionCompletesnessResponse {
  data: {
    allDocumentsPresent: boolean;
    missingDocuments: string[];
    presentCount: number;
    missingCount: number;
  };
}

interface Agent {
  _id: string;
  name: string;
  email: string;
}

// interface CheckValidationResult {
//   checkType: string;
//   isValid: boolean;
//   presentDocuments: string[];
//   missingDocuments: string[];
// }

const ComplianceTransactionDetailsPage = () => {
  const navigate = useNavigate();

  const isAdmin = localStorage.getItem('Admin');

  const transactionSource = useRecoilValue(transactionSourceAtom);
  console.log('Transaction Source: ', transactionSource);

  const errorsManager = useTransactionDocumentErrorsManager();

  const transactionID = getCustomIdFromUrl('transactionID') || '';
  const [documentIDs, setDocumentIDs] = useRecoilState(documentIDsAtom);
  const [assignedAgent, setAssignedAgent] = useState<string>(''); // TODO: Fetch this from the backend
  const realtorSelectorRef = useRef<HTMLSelectElement>(null);

  const [loading, setLoading] = useState(true);
  const [progressBarLoading, setProgressBarLoading] = useState(false);

  const [showTransactionOverview, setShowTransactionOverview] = useState(false);
  const [transactionOverviewGroups, setTransactionOverviewGroups] = useState<
    string[]
  >([]);

  const [downloadingId, setDownloadingId] = useState<string | null>(null);
  const [editingDocId, setEditingDocId] = useState<string | null>(null);
  const [editedTitle, setEditedTitle] = useState<string>('');
  const [savingTitle, setSavingTitle] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const flags = useFlags(['show_document_signature']);
  const isDocumentSignatureEnabled = flags?.show_document_signature?.enabled;
  const tagFlags = useFlags(['show_tags']);
  const isTagEnabled = tagFlags?.show_tags?.enabled;
  const [updatingMilestone, setUpdatingMilestone] = useState<boolean>(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [updatingAgents, setUpdatingAgents] = useState(false);
  const [searchingAgents, setSearchingAgents] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [deleteConfirmModal, setDeleteConfirmModal] = useState<{
    visible: boolean;
    recordId: string | null;
  }>({ visible: false, recordId: null });
  const [showDotloopSyncBanner, setShowDotloopSyncBanner] = useState(false);
  const debouncedSearch = useDebounce(searchText, 500);

  const transactionIds = useMemo(() => [transactionID], [transactionID]);

  const { tagsByEntity, isLoadingForEntity } = useEntityTags(
    transactionIds,
    TAG_ENTITY_TYPES.TRANSACTION
  );

  const updateTransactionAgents = async (selectedAgents: string[]) => {
    setUpdatingAgents(true);
    try {
      const agentObjects = selectedAgents.map((agentId) => ({
        _id: agentId,
      }));

      await ComplianceService.updateTransactionAgent(
        transactionID,
        agentObjects
      );
      message.success('Agents updated successfully');
      getTransaction(false);
    } catch (error: any) {
      console.error('Error updating agents:', error);
      message.error(
        error?.response?.data?.message || 'Failed to update agents'
      );
    } finally {
      setUpdatingAgents(false);
      setSearchText('');
    }
  };

  const fetchTransactionDocument = async (
    docId: string,
    transactionId: string
  ) => {
    try {
      const response = await ComplianceService.getDocumentByID(
        transactionId,
        docId
      );
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      return URL.createObjectURL(pdfBlob);
    } catch {
      return null;
    }
  };

  const handleDownloadPdf = async (doc: any) => {
    try {
      const documentUrl = await fetchTransactionDocument(
        doc._id,
        getCustomIdFromUrl('transactionID')
      );
      if (documentUrl) {
        const link = document.createElement('a');
        link.href = documentUrl;
        link.download = `${doc.title || 'document'}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(documentUrl);
      } else {
        message.error('Failed to download the document');
      }
    } catch (error) {
      message.error('An error occurred while downloading the document');
    }
  };

  const handleStatusChange = async (documentId: string, newStatus: string) => {
    try {
      setProgressBarLoading(true);
      await ComplianceService.updateDocumentReviewStatus(documentId, newStatus);
      message.success('Document status updated successfully');
      getTransaction(false); // Refresh the data
    } catch (error: any) {
      console.error('Error updating document status:', error);
      message.error(
        error?.response?.data?.message || 'Failed to update document status'
      );
    } finally {
      setProgressBarLoading(false);
    }
  };

  const handleDownload = async (record: Document) => {
    if (downloadingId) return; // Skip if already downloading
    try {
      setDownloadingId(record._id);
      await handleDownloadPdf(record);
    } finally {
      setDownloadingId(null);
    }
  };

  const handleDelete = async (record: Document) => {
    try {
      const response = await ComplianceService.checkDocument(record._id);

      if (response.status === 200) {
        setDeleteConfirmModal({
          visible: true,
          recordId: record._id,
        });
      } else if (response.status === 404) {
        try {
          await ComplianceService.deleteDocument(record._id);
          message.success('Document deleted successfully');
          getTransaction(false);
        } catch (error: any) {
          message.error(
            error?.response?.data?.message || 'Failed to delete this document.'
          );
        }
      }
    } catch (error: any) {
      console.error('Error checking document:', error);
      if (error?.response?.status === 404) {
        try {
          await ComplianceService.deleteDocument(record._id);
          message.success('Document deleted successfully');
          getTransaction(false);
        } catch (error: any) {
          message.error(
            error?.response?.data?.message || 'Failed to delete this document.'
          );
        }
        return;
      }

      message.error(
        error?.response?.data?.message ||
          'Failed to check document for deletion'
      );
    }
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const cursorPosition = e.target.selectionEnd;
    setEditedTitle(e.target.value);
    // Restore cursor position after state update
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.selectionStart = cursorPosition;
        textareaRef.current.selectionEnd = cursorPosition;
      }
    }, 0);
  };

  const renderDocumentTitle = (title: string, record: Document) => {
    const handleSave = async () => {
      if (!editedTitle.trim()) {
        message.error('Title cannot be empty');
        return;
      }
      try {
        setSavingTitle(true);
        await ComplianceService.updateDocumentTitle(record._id, editedTitle);
        message.success('Document title updated successfully');
        setEditingDocId(null);
        getTransaction(false);
      } catch (error: any) {
        console.error('Error updating document title:', error);
        message.error(
          error?.response?.data?.message || 'Failed to update document title'
        );
      } finally {
        setSavingTitle(false);
      }
    };

    const startEditing = (e: any) => {
      e.stopPropagation();
      setEditingDocId(record._id);
      setEditedTitle(title);
    };

    const cancelEditing = () => {
      setEditingDocId(null);
      setEditedTitle('');
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSave();
      } else if (e.key === 'Escape') {
        cancelEditing();
      }
    };

    return (
      <Box display="flex" alignItems="center" gap={1} sx={{ width: '100%' }}>
        {editingDocId === record._id ? (
          // Edit Mode
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            width="100%"
            onClick={(e) => e.stopPropagation()}
          >
            <textarea
              ref={textareaRef}
              value={editedTitle}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              autoFocus
              style={{
                minWidth: '30rem',
                fontSize: '0.9rem',
                padding: '0.75rem',
                borderRadius: '4px',
                border: '1px solid #d9d9d9',
                resize: 'none',
                fontFamily: 'inherit',
              }}
              onFocus={(e) => {
                // Place cursor at the end when focused
                const length = e.target.value.length;
                e.target.selectionStart = length;
                e.target.selectionEnd = length;
              }}
            />
            <IconButton
              size="small"
              onClick={handleSave}
              color="primary"
              disabled={!editedTitle.trim() || savingTitle}
            >
              {savingTitle ? (
                <LoadingOutlined spin />
              ) : (
                <CheckIcon fontSize="small" />
              )}
            </IconButton>
            <IconButton
              size="small"
              onClick={cancelEditing}
              color="error"
              disabled={savingTitle}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        ) : (
          // View Mode
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            sx={{
              '&:hover .edit-button': {
                opacity: 1,
              },
            }}
          >
            {title}
            <IconButton
              className="edit-button"
              size="small"
              onClick={(e) => startEditing(e)}
              sx={{
                opacity: 0,
                transition: 'opacity 0.2s',
                ml: 1,
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };

  const [complianceTransaction, setComplianceTransaction] =
    useState<TransactionDetailsProps>({} as TransactionDetailsProps);

  const columns = [
    ...(isDocumentSignatureEnabled
      ? [
          {
            title: (
              <Checkbox
                checked={
                  selectedRows.length > 0 &&
                  selectedRows.length ===
                    complianceTransaction?.documents?.filter(
                      (doc) => !doc.isSeparator
                    ).length
                }
                indeterminate={
                  selectedRows.length > 0 &&
                  selectedRows.length <
                    complianceTransaction?.documents?.filter(
                      (doc) => !doc.isSeparator
                    ).length
                }
                onChange={(e) => handleSelectAll(e.target.checked, e)}
                onClick={(e) => e.stopPropagation()}
              />
            ),
            key: 'selection',
            width: 50,
            render: (_: any, record: Document) =>
              !record.isSeparator && (
                <Checkbox
                  checked={selectedRows.includes(record._id)}
                  onChange={(e) => handleSelection(record._id, e)}
                  onClick={(e) => e.stopPropagation()}
                />
              ),
          },
        ]
      : []),
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title: string, record: Document) =>
        renderDocumentTitle(title, record),
    },
    {
      title: 'Status',
      dataIndex: 'displayStatus',
      key: 'displayStatus',
      render: (displayStatus: string, record: Document) => {
        return (
          <Select
            value={
              REVIEW_STATUS_OPTIONS.includes(displayStatus)
                ? displayStatus
                : 'NOT_STARTED'
            }
            onChange={(value) => handleStatusChange(record._id, value)}
            onClick={(e) => e.stopPropagation()}
            dropdownMatchSelectWidth={false}
            style={{ minWidth: '6rem' }}
            bordered={false}
          >
            {REVIEW_STATUS_OPTIONS.map((status) => (
              <Option key={status} value={status}>
                <StatusChip status={status} role={'compliance'} />
              </Option>
            ))}
          </Select>
        );
      },
    },
    // TODO: Add this on the backend
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date: string) => {
        const formattedDate = new Date(date).toLocaleString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
        return formattedDate;
      },
    },
    {
      title: 'Critical Errors',
      dataIndex: 'errorFields',
      key: 'errorFields',
      render: (_: any, record: Document) => {
        return record.displayStatus !== 'PROCESSING' &&
          record.errorFields?.length > 0 ? (
          <Box
            borderRadius="100%"
            sx={{
              fontSize: '0.75rem',
              fontWeight: 500,
              color: 'white',
              backgroundColor: '#FF4D4F',
              padding: '0.25rem',
              width: '1rem',
              height: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {record?.errorFields?.filter(
              (errorField) => errorField && errorField.severity === 'required'
            )?.length || 0}
          </Box>
        ) : null;
      },
    },
    {
      title: 'Download',
      key: 'download',
      width: 100,
      render: (_: any, record: Document) => (
        <Button
          type="text"
          onClick={(e) => {
            e.stopPropagation();
            handleDownload(record);
          }}
          disabled={downloadingId !== null}
          icon={
            downloadingId === record._id ? (
              <LoadingOutlined spin />
            ) : (
              <DownloadOutlined />
            )
          }
        />
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      width: 100,
      render: (_: any, record: Document) => (
        <Popconfirm
          className="no-toggle"
          title="Are you sure you want to delete this document?"
          onConfirm={() => handleDelete(record)}
          okText="Yes"
          cancelText="No"
          onPopupClick={(e) => e.stopPropagation()}
        >
          <Button
            type="text"
            onClick={(e) => {
              e.stopPropagation();
            }}
            icon={<Delete />}
          />
        </Popconfirm>
      ),
    },
  ];

  const handleSelectAll = (checked: boolean, e: any) => {
    e.stopPropagation();
    if (checked) {
      // Select all documents except separators
      const allDocIds =
        complianceTransaction?.documents
          ?.filter((doc) => !doc.isSeparator)
          .map((doc) => doc._id) || [];
      setSelectedRows(allDocIds);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelection = (recordId: string, e: any) => {
    // Stop event propagation to prevent row click
    e.stopPropagation();

    if (e.target.checked) {
      setSelectedRows([...selectedRows, recordId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== recordId));
    }
  };

  const handleBulkAction = (action: string) => {
    switch (action) {
      case 'request':
        navigate(
          `/compliance/dashboard/transactions/request-signature?transactionID=${transactionID}`,
          {
            state: {
              documents:
                complianceTransaction?.documents?.filter((doc) =>
                  selectedRows.includes(doc._id)
                ) || [],
            },
          }
        );
        break;
      case 'remove':
        setSelectedRows([]);
        break;
      default:
        break;
    }
  };

  const MultiSelectActions = () => {
    const isDisabled = selectedRows.length === 0;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 16,
          paddingRight: 16,
          opacity: isDisabled ? 0.5 : 1,
          transition: 'opacity 0.2s ease',
        }}
      >
        <Typography
          style={{ marginRight: 8, display: 'flex', alignItems: 'center' }}
        >
          Multi-select actions :
        </Typography>
        <Select
          defaultValue="Select an option"
          onChange={handleBulkAction}
          disabled={isDisabled}
          style={{ width: 200 }}
        >
          <Option value="request">Request Signature</Option>
          <Option value="remove">Deselect</Option>
        </Select>
      </div>
    );
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>([]);
  const [faultyFiles, setFaultyFiles] = useState<string[]>([]);

  // const [uploadFileType, setUploadFileType] = useState('il_bw_rtsma');
  const [view, setView] = useState('file');

  const [folders, setFolders] = useState<any[]>([]);
  const [foldersCollapsed, setFoldersCollapsed] = useState<
    Record<string, boolean>
  >({});

  const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
  const [missingDocuments, setMissingDocuments] = useState<string[]>([]);
  const [complianceTransactionType, setComplianceTransactionType] =
    useState<string>('');
  const [remarks, setRemarks] = useState<string>('');
  const [savingRemarks, setSavingRemarks] = useState(false);
  const adminProfile = getAdminProfile();
  const uniqueAgentIds = new Set();
  const agentOptions = [
    // Add existing transaction agents
    ...(complianceTransaction?.agents?.map((agent) => {
      const agentId = agent.details?._id || agent?._id;
      uniqueAgentIds.add(agentId);
      return {
        value: agentId,
        label: `${agent?.details?.name || agent?.name} (${agent?.details?.email || agent?.email})`,
      };
    }) || []),
    // Add fetched agents if they're not already included
    ...agents
      ?.filter((agent) => !uniqueAgentIds.has(agent?._id))
      .map((agent) => ({
        value: agent?._id,
        label: `${agent?.name} (${agent?.email})`,
      })),
  ];

  // TODO: See if this needs to keep updating or so, ideally shouldnt be an issue
  // Remove this since it's not needed
  useEffect(() => {
    const fetchAgents = async () => {
      if (!debouncedSearch && debouncedSearch !== '') return;

      setSearchingAgents(true);
      try {
        const response = await ComplianceService.getAgents(debouncedSearch);
        setAgents(response.data?.data || []);
      } catch (error) {
        console.error('Error fetching agents:', error);
        message.error('Failed to fetch agents');
      } finally {
        setSearchingAgents(false);
      }
    };

    fetchAgents();
  }, [debouncedSearch]);

  useEffect(() => {
    // Fetch transaction details here
    console.log('Transaction ID', transactionID);
    getTransaction();
  }, [transactionID, documentIDs]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (complianceTransaction?.loop?.syncingTransactionWithDotloop) {
      const syncStartTime =
        complianceTransaction?.loop?.dotloopSyncStartedAt || 0;
      const twentyMinutesInMs = 20 * 60 * 1000;
      const currentTime = Date.now();
      const hasBeenTwentyMinutes =
        currentTime - syncStartTime > twentyMinutesInMs;

      // Only keep polling if it has NOT been 20 minutes yet
      if (!hasBeenTwentyMinutes) {
        intervalId = setInterval(() => {
          console.log('Waiting for Dotloop sync to complete...');
          setShowDotloopSyncBanner(true);
          getTransaction(false, true);
        }, 10000);
      } else {
        console.log("It's been 20 minutes. Stopping the polling.");
        setShowDotloopSyncBanner(false);
      }
    } else {
      setShowDotloopSyncBanner(false);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [complianceTransaction]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    uploadFiles();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFilesToUpload([]);
  };

  const updateRemarks = () => {
    if (!remarks) {
      message.error('Please enter remarks');
      return;
    }
    setSavingRemarks(true);
    ComplianceService.updateRemarks(transactionID, remarks)
      .then(
        (response) => {
          console.log('Remarks updated:', response.data);
          message.success('Remarks updated successfully');
        },
        (error) => {
          console.error('Error updating remarks:', error);
          message.error('Failed to update remarks');
        }
      )
      .finally(() => {
        setSavingRemarks(false);
      });
  };

  const getTransaction = (showLoading = true, hideAllLoaders = false) => {
    if (!hideAllLoaders) {
      if (showLoading) {
        setLoading(true);
      } else {
        setProgressBarLoading(true);
      }
    }
    ComplianceService.getTransactionByID(transactionID).then(
      (response) => {
        console.log('Transaction Details:', response.data);
        // Add a key field to each document
        const transactionWithKeys = {
          ...response.data,
          documents: response.data.documents.map((doc: any, index: number) => ({
            ...doc,
            key: index,
          })),
        };
        organizeDocumentsIntoFolders(response.data.documents);
        setComplianceTransaction(transactionWithKeys);
        setRemarks(response.data.remarks || '');

        // Set up the completeness check
        setMissingDocuments(response.data?.processingStatus?.missingDocs);
        setMissingDocumentsCount(
          response.data?.processingStatus?.missingDocs?.length || 0
        );

        if (transactionWithKeys?.sourceOfTruth?.data) {
          let sourceOfTruthGroups: string[] = [];
          // Iterate through complianceTransaction?.sourceOfTruth?.data object
          // Iterate over the key value pairs. In the value, check for the 'group' key
          // If the group key is not present in the transactionOverviewGroups array, add it
          // If the group key is present, skip
          Object.entries(transactionWithKeys.sourceOfTruth.data).forEach(
            ([key, value]) => {
              if (
                typeof value === 'object' &&
                value !== null &&
                'group' in value
              ) {
                if (
                  typeof value.group === 'string' &&
                  !sourceOfTruthGroups.includes(value.group)
                ) {
                  sourceOfTruthGroups.push(value.group);
                }
              }
            }
          );
          setTransactionOverviewGroups(sourceOfTruthGroups);
        }
        // setAssignedAgent(
        //   response?.data?.agents[response?.data?.agents?.length - 1]?._id
        // );
        setLoading(false);
        setProgressBarLoading(false);
      },
      (error) => {
        console.error('Error fetching transaction details:', error);
        message.error(`Error fetching transaction details: ${error}`);
        setLoading(false);
        setProgressBarLoading(false);
      }
    );
  };

  const beforeUpload = (file: UploadFile, fileList: Array<UploadFile>) => {
    console.log('In before Upload');
    const isPdf = file.type === 'application/pdf';
    console.log(`- File => ${file.name}: ${isPdf ? 'Accepted' : 'Rejected'}`);

    if (!isPdf) {
      // Optionally, add file to a list of faulty files for UI feedback
      setFaultyFiles([...faultyFiles, file.name]);
      Modal.error({
        title: 'Incorrect file format',
        content: `${file.name} is not a PDF. Please remove it and try again.`,
      });
      return Upload.LIST_IGNORE; // Prevents adding the file to the list
    }

    // TODO: See if we need to widen the scope of this check
    // Check for duplicate files based on name and size
    const isDuplicate = filesToUpload.some(
      (existingFile) =>
        existingFile.name === file.name && existingFile.size === file.size
    );

    if (isDuplicate) {
      Modal.warning({
        title: 'Duplicate file detected',
        content: `${file.name} already exists. Please upload a different file.`,
      });
      return Upload.LIST_IGNORE; // Prevents adding the duplicate file to the list
    }

    // Filter out the faulty file if it's being retried
    setFaultyFiles(
      faultyFiles.filter((faultyFileName) => faultyFileName !== file.name)
    );

    // Add the file to the list for upload
    setFilesToUpload([
      ...filesToUpload,
      ...fileList.filter(
        (file) =>
          file.type === 'application/pdf' &&
          !filesToUpload.some(
            (existingFile) =>
              existingFile.name === file.name && existingFile.size === file.size
          )
      ),
    ]);

    return false; // return false to prevent auto uploading
  };

  const uploadFiles = () => {
    if (filesToUpload && filesToUpload.length > 0) {
      setLoading(true);
      ComplianceService.uploadDocuments(transactionID, filesToUpload).then(
        (response) => {
          console.log('Documents uploaded:', response.data);
          let updatedDocumentIDs = [...documentIDs];
          for (let i = 0; i < response.data.data.documentIDs.length; i++) {
            if (!documentIDs.includes(response.data.data.documentIDs[i])) {
              updatedDocumentIDs.push(response.data.data.documentIDs[i]);
            }
          }
          setDocumentIDs(updatedDocumentIDs);
          setDocumentIDsInLocalStorage(updatedDocumentIDs);
          setFilesToUpload([]);
          setIsModalVisible(false);
          getTransaction();
          setLoading(false);
        },
        (error) => {
          console.error('Error uploading document:', error);
          setLoading(false);
          Modal.error({
            title: error?.response?.data?.message || 'Upload Failed',
            content: (
              <ul>
                {error?.response?.data?.unknownFiles?.map((file: string) => (
                  <li key={file}>{file}</li>
                ))}
              </ul>
            ),
          });
        }
      );
    } else {
      message.error('Please select a file to upload.');
    }
  };

  const handleDocumentClick = (document: Document) => {
    // If Realtor has not been assigned, don't allow
    if (document.displayStatus === 'PROCESSING') {
      return;
    }
    if (!complianceTransaction?.agents[0]) {
      message.error('Please assign an Agent before verifying documents.');
      // TODO: The following line does not work. Debug it later.
      realtorSelectorRef.current?.focus();
      return;
    }
    navigate(
      AppURLs.complianceDashboardTransactionVerifyDocument(
        transactionID,
        document._id
      )
    );
  };

  const goToTransactions = () => {
    if (transactionSource === 'compliance-transactions') {
      navigate(AppURLs.complianceDashboardComplianceTransactions());
    } else {
      navigate(AppURLs.complianceDashboardTransactions());
    }
  };

  const handleViewChange = (e: any) => {
    setView(e.target.value);
  };

  const organizeDocumentsIntoFolders = (documents: Document[]) => {
    const folders: any[] = [];
    documents.forEach((doc) => {
      const folderDetails = doc.folder;
      if (!folderDetails) {
        // TODO: Refine this
        if (!folders.some((folder) => folder.type === 'UNKNOWN')) {
          folders.push({
            name: 'No Folder',
            documents: [doc],
            status: 'NULL',
            internalStatus: 'NULL',
            refreshNeeded: false,
            createdAt: 'UNDEFINED',
            updatedAt: 'UNDEFINED',
            type: 'UNKNOWN',
          });
        } else {
          const folderIndex = folders.findIndex(
            (folder) => folder.type === 'UNKNOWN'
          );
          folders[folderIndex].documents.push(doc);
        }
      } else {
        if (folders.some((folder) => folder.type === folderDetails.type)) {
          const folderIndex = folders.findIndex(
            (folder) => folder.type === folderDetails.type
          );
          folders[folderIndex].documents.push(doc);
        } else {
          folders.push({
            ...folderDetails,
            documents: [doc],
          });
        }
      }
    });
    // return folders;
    setFolders(folders);

    let updatedFoldersCollapsed = { ...foldersCollapsed };
    folders.map((folder) => {
      updatedFoldersCollapsed[folder.type] = false;
      return null;
    });
    setFoldersCollapsed(updatedFoldersCollapsed);
  };

  const toggleFolder = (folderType: string) => {
    const newFoldersCollapsed = { ...foldersCollapsed };
    newFoldersCollapsed[folderType] = !newFoldersCollapsed[folderType];
    setFoldersCollapsed(newFoldersCollapsed);
  };

  const updateComplianceTransactionStatus = (status: string) => {
    ComplianceService.updateStatus(transactionID, status).then(
      (response) => {
        console.log('Transaction status updated:', response.data);
        message.success(
          'Transaction status updated to ' +
            complianceTransactionStatusMap[status].name
        );
        getTransaction();
      },
      (error) => {
        console.error('Error updating transaction status:', error);
        message.error(`Error updating transaction status: ${error}`);
      }
    );
  };

  const assignRealtor = (agentID: string) => {
    setProgressBarLoading(true);
    ComplianceService.assignRealtor(transactionID, agentID, 'UNKNOWN').then(
      (response) => {
        console.log('Agent assigned:', response.data);
        message.success(
          `Transaction assigned to ${agents.find((agent) => agent._id === agentID)?.name}`
        );
        setProgressBarLoading(false);
        getTransaction(false);
      },
      (error) => {
        console.error('Error assigning agent:', error);
        message.error(`Error assigning agent: ${error}`);
        setProgressBarLoading(false);
      }
    );
  };

  const triggerDocumentValidation = () => {
    setProgressBarLoading(true);
    ComplianceService.validateDocuments(transactionID).then(
      (response) => {
        setProgressBarLoading(false);
        getTransaction();
        console.log('Cross Document Validation:', response.data);
        message.success('Successfully validated documents');
      },
      (error) => {
        setProgressBarLoading(false);
        console.error('Error validating documents:', error);
        message.error(`Error validating documents: ${error}`);
      }
    );
  };

  const sortDocuments = (documents: any[]) => {
    const regularDocs = documents.filter(
      (doc) => doc.type !== 'USER_UPLOADED_MULTI_DOC'
    );
    const multiDocs = documents.filter(
      (doc) => doc.type === 'USER_UPLOADED_MULTI_DOC'
    );

    return [
      ...regularDocs,
      // Add a separator object if there are both types of documents
      ...(regularDocs.length > 0 && multiDocs.length > 0
        ? [
            {
              _id: 'separator',
              isSeparator: true,
            },
          ]
        : []),
      ...multiDocs,
    ];
  };

  /**
   * Use this function to add the missing documents error to the errors manager
   * The 'documentType' key will be '_missingDocuments'
   * This will act as a special key and handled differently.
   */
  const addDocumentsMissingError = () => {
    if (missingDocumentsCount > 0) {
      errorsManager.addErrors(
        transactionID,
        '_missingDocuments',
        missingDocuments
      );
    }
  };

  const updateTransactionMilestone = (milestone: string) => {
    setUpdatingMilestone(true);
    ComplianceService.updateTransactionMilestone(transactionID, milestone).then(
      () => {
        setUpdatingMilestone(false);
        message.success('Milestone updated successfully');
        getTransaction(false);
      },
      (error) => {
        setUpdatingMilestone(false);
        message.error('Failed to update milestone');
      }
    );
  };

  const renderExternalSourceID = () => {
    const externalSourceID = complianceTransaction?.externalSourceID;

    if (getCompliancePartnerID() === partners.KEYES) {
      // Show deal number with copy button for Keyes
      return (
        <Box display="flex" alignItems="center">
          <Typography>{externalSourceID || '-'}</Typography>
          {externalSourceID && (
            <IconButton
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${externalSourceID} ${complianceTransaction?.name || ''}`
                );
                message.success('Deal number copied to clipboard');
              }}
              sx={{ ml: 1 }}
            >
              <ContentCopy sx={{ fontSize: 16 }} />
            </IconButton>
          )}
        </Box>
      );
    } else {
      // Show hyperlink for B/W
      return externalSourceID ? (
        <a
          href={`https://www.dotloop.com/m/loop?viewId=${externalSourceID}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {externalSourceID}
        </a>
      ) : (
        '-'
      );
    }
  };

  // const FolderView = () => {
  //   return (
  //     <Box
  //       width="100%"
  //       borderRadius="8px"
  //       padding="2rem"
  //       sx={{ backgroundColor: 'white' }}
  //       boxSizing="border-box"
  //     >
  //       {/* Header */}
  //       <Box
  //         display="flex"
  //         width="100%"
  //         justifyContent="space-between"
  //         alignItems="center"
  //       >
  //         <Box flex={1} paddingRight="2rem">
  //           <Typography fontSize="0.9rem" fontWeight={550}>
  //             NAME
  //           </Typography>
  //         </Box>
  //         <Box flex={1}>
  //           <Typography fontSize="0.9rem" fontWeight={550}>
  //             STATUS
  //           </Typography>
  //         </Box>
  //         <Box flex={0.1}></Box>
  //       </Box>
  //       <Divider style={{ margin: '1rem 0 1rem 0' }} />

  //       {/* Folders */}
  //       {folders.length > 0 ? (
  //         folders.map((folder: any, index: number) => (
  //           <Box
  //             width="100%"
  //             sx={{
  //               cursor: 'pointer',
  //             }}
  //             key={index}
  //           >
  //             <Box
  //               display="flex"
  //               width="100%"
  //               justifyContent="space-between"
  //               alignItems="center"
  //             >
  //               <Box
  //                 flex={1}
  //                 display="flex"
  //                 alignItems="center"
  //                 paddingRight="2rem"
  //                 onClick={() => {
  //                   toggleFolder(folder.type);
  //                 }}
  //               >
  //                 <IconButton
  //                   onClick={() => toggleFolder(folder.type)}
  //                   style={{
  //                     color: foldersCollapsed[folder.type]
  //                       ? '#2F78EB'
  //                       : 'inherit',
  //                   }}
  //                 >
  //                   {foldersCollapsed[folder.type] ? (
  //                     <FolderOpenOutlined />
  //                   ) : (
  //                     <FolderOutlined />
  //                   )}
  //                 </IconButton>
  //                 {folder.name}
  //               </Box>
  //               <Box
  //                 flex={1}
  //                 onClick={() => {
  //                   toggleFolder(folder.type);
  //                 }}
  //               >
  //                 {/* TODO: Unhide this after fixing the backend */}
  //                 {/* <StatusChip status={folder.status || 'NULL'} /> */}
  //               </Box>
  //               <Box flex={0.1}>
  //                 <IconButton>
  //                   <MoreOutlined />
  //                 </IconButton>
  //               </Box>
  //             </Box>
  //             {foldersCollapsed[folder.type] &&
  //               folder?.documents.length > 0 && (
  //                 <Box marginTop="1rem" sx={{ cursor: 'pointer' }}>
  //                   {folder.documents.map((doc: Document, index: number) => (
  //                     <Box
  //                       display="flex"
  //                       width="100%"
  //                       justifyContent="space-between"
  //                       alignItems="center"
  //                       key={index}
  //                       // margin="0.75rem 0 0.75rem 0"
  //                       padding="1rem 0 1rem 0"
  //                       sx={{
  //                         transition: 'background-color 0.3s ease',
  //                         '&:hover': {
  //                           backgroundColor: '#f5f5f5', // Light grey background on hover
  //                           // Add any other hover styles or animations here
  //                         },
  //                       }}
  //                       onClick={() => {
  //                         handleDocumentClick(doc._id);
  //                       }}
  //                     >
  //                       <Box
  //                         flex={1}
  //                         display="flex"
  //                         paddingLeft="2rem"
  //                         alignItems="center"
  //                       >
  //                         <Typography fontSize="0.9rem">{doc.title}</Typography>
  //                       </Box>
  //                       <Box flex={1}>
  //                         <StatusChip status={doc.reviewStatus} />
  //                       </Box>
  //                       <Box flex={0.1}>
  //                         <Box
  //                           borderRadius="100%"
  //                           sx={{
  //                             // margin: 'auto 1rem auto auto',
  //                             fontSize: '0.75rem',
  //                             fontWeight: 500,
  //                             color: 'white',
  //                             backgroundColor: '#FF4D4F',
  //                             padding: '0.25rem',
  //                             width: '1rem',
  //                             height: '1rem',
  //                             display: 'flex',
  //                             alignItems: 'center',
  //                             justifyContent: 'center',
  //                           }}
  //                         >
  //                           {doc?.errorFields.length}
  //                         </Box>
  //                         {/* <IconButton><MoreOutlined /></IconButton> */}
  //                       </Box>
  //                     </Box>
  //                   ))}
  //                 </Box>
  //               )}
  //             <Divider style={{ margin: '1rem 0 1rem 0' }} />
  //           </Box>
  //         ))
  //       ) : (
  //         <Box
  //           display="flex"
  //           width="100%"
  //           justifyContent="center"
  //           alignItems="center"
  //         >
  //           <Empty description="No Files/Folders found" />
  //         </Box>
  //       )}
  //     </Box>
  //   );
  // };

  // Disabled the color coding for now - Replacing it with just red for missing/not-found fields
  const getRowClassName = (record: any) => {
    // if (record?.priority === 1) {
    //   return 'high-confidence-row';
    // } else if (record?.priority === 2) {
    //   return 'medium-confidence-row';
    // } else if (record?.priority > 2) {
    //   return 'low-confidence-row';
    // }
    // return '';
    if (!record?.value || record?.value === 'not found') {
      return 'low-confidence-row';
    }
    return '';
  };

  const component = (
    <Box width="100%">
      {progressBarLoading && <LoadingProgressBar />}

      <Remarks
        entityId={transactionID}
        entityType="Transaction"
        position="bottom-right"
      />

      {showDotloopSyncBanner && (
        <DotloopSyncStatusBar
          transactionName={complianceTransaction.name}
          onClick={() => {}}
        />
      )}

      {/* <Drawer
        title="Transaction Overview"
        open={showTransactionOverview}
        closable={true}
        onClose={() => setShowTransactionOverview(false)}
        width="650px"
      >
        {transactionOverviewGroups.map((group, index) => (
          <Box key={index}>
            <Collapse
              items={[
                {
                  key: index,
                  label: capitalizeFirstLetter(group),
                  children: (
                    <Table
                      dataSource={
                        complianceTransaction?.sourceOfTruth?.data
                          ? Object.entries(
                              complianceTransaction?.sourceOfTruth?.data
                            )
                              .filter(([key, value]) => {
                                const typedValue = value as { group: string }; // Type assertion
                                return typedValue.group === group;
                              })
                              .map(([key, value]) => {
                                if (
                                  typeof value === 'object' &&
                                  value !== null
                                ) {
                                  return {
                                    ...value,
                                    key,
                                  };
                                } else {
                                  return {
                                    key,
                                  };
                                }
                              })
                          : []
                      }
                      columns={[
                        {
                          title: 'Title',
                          dataIndex: 'title',
                          key: 'title',
                          render: (title: string, record: any) => {
                            return (
                              <Tooltip title={record.description}>
                                <Typography fontWeight={450} fontSize="0.9rem">
                                  {title}
                                </Typography>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: 'Value',
                          dataIndex: 'value',
                          key: 'value',
                          render: (value: string, record: any) => {
                            return (
                              <Typography fontSize="0.9rem">{value}</Typography>
                            );
                          },
                        },
                        {
                          title: 'Source',
                          dataIndex: 'source',
                          key: 'source',
                          render: (source: string) => {
                            return (
                              <Typography fontSize="0.9rem">
                                {documentNameMap[source]}
                              </Typography>
                            );
                          },
                        },
                      ]}
                      pagination={false}
                      rowClassName={getRowClassName}
                    />
                  ),
                },
              ]}
            />
            <Divider />
          </Box>
        ))}
      </Drawer> */}

      <Box width="100%" padding="1rem" boxSizing="border-box">
        <Box
          display="flex"
          width="100%"
          marginBottom="0.5rem"
          justifyContent="center"
          alignItems="center"
        >
          <Breadcrumb style={{ margin: '1rem 0 1rem 0', display: 'flex' }}>
            {/* <Breadcrumb.Item>Home</Breadcrumb.Item> */}
            <Breadcrumb.Item onClick={() => goToTransactions()}>
              <Typography fontSize="0.9rem" style={{ cursor: 'pointer' }}>
                {' '}
                Transactions{' '}
              </Typography>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Typography fontSize="0.9rem">
                {getCompliancePartnerID() === partners.KEYES &&
                  complianceTransaction?.externalSourceID &&
                  `${complianceTransaction.externalSourceID} - `}
                {complianceTransaction.name}
              </Typography>
            </Breadcrumb.Item>
          </Breadcrumb>

          <Box display="flex" marginLeft="auto">
            {/* <Radio.Group
              value={view}
              onChange={handleViewChange}
              style={{ margin: '0 0.5rem 0 auto' }}
            >
              <Radio.Button value="file" style={{ marginBottom: '1rem' }}>
                <FileOutlined />
              </Radio.Button>
              <Radio.Button value="folder" style={{ marginBottom: '1rem' }}>
                <FolderOutlined />
              </Radio.Button>
            </Radio.Group> */}

            {adminProfile ? (
              <Button
                type="default"
                onClick={triggerDocumentValidation}
                disabled={progressBarLoading}
                style={{ marginRight: '0.5rem' }}
              >
                Validate Documents
              </Button>
            ) : null}

            {adminProfile ? (
              <Button
                type="primary"
                style={{ marginLeft: 'auto' }}
                onClick={showModal}
              >
                Upload Document
              </Button>
            ) : null}
          </Box>
        </Box>

        <Box display="flex">
          {Object.keys(complianceTransaction).length > 0 && (
            <Card style={{ marginBottom: '1rem', width: '100%' }}>
              <Box display="flex">
                <Box>
                  <Typography
                    fontSize="1.5rem"
                    fontWeight={500}
                    display="flex"
                    alignItems="center"
                  >
                    {complianceTransaction.name}
                    {transactionOverviewGroups.length > 0 && (
                      <IconButton
                        onClick={() => {
                          setShowTransactionOverview(true);
                        }}
                      >
                        <InfoCircleOutlined />
                      </IconButton>
                    )}
                  </Typography>
                  <Typography fontSize="1.0rem" fontWeight={350}>
                    {complianceTransaction?.sourceOfTruth?.data?.buyer
                      ?.value && (
                      <>
                        {' '}
                        <b>Buyer: </b>{' '}
                        {Array.isArray(
                          complianceTransaction?.sourceOfTruth?.data?.buyer
                            ?.value
                        ) ? (
                          complianceTransaction?.sourceOfTruth?.data?.buyer?.value.map(
                            (buyer: string, index: number) => (
                              <span key={index}>
                                {buyer}
                                {index <
                                  complianceTransaction?.sourceOfTruth?.data
                                    ?.buyer?.value.length -
                                    1 && ', '}
                              </span>
                            )
                          )
                        ) : (
                          <span>
                            {
                              complianceTransaction?.sourceOfTruth?.data?.buyer
                                ?.value
                            }
                          </span>
                        )}
                      </>
                    )}
                    &nbsp;&nbsp;
                    {complianceTransaction?.sourceOfTruth?.data?.seller
                      ?.value && (
                      <>
                        <b>Seller: </b>
                        {Array.isArray(
                          complianceTransaction?.sourceOfTruth?.data?.seller
                            ?.value
                        ) ? (
                          complianceTransaction?.sourceOfTruth?.data?.seller?.value.map(
                            (buyer: string, index: number) => (
                              <span key={index}>
                                {buyer}
                                {index <
                                  complianceTransaction?.sourceOfTruth?.data
                                    ?.seller?.value.length -
                                    1 && ', '}
                              </span>
                            )
                          )
                        ) : (
                          <span>
                            {
                              complianceTransaction?.sourceOfTruth?.data?.seller
                                ?.value
                            }
                          </span>
                        )}
                      </>
                    )}
                  </Typography>
                </Box>
                {missingDocumentsCount > 0 && missingDocuments.length > 0 && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    margin="0 0 auto auto"
                  >
                    <Tooltip
                      title={
                        <Box>
                          {missingDocuments.map((doc, index) => (
                            <Box key={index} display="flex" alignItems="center">
                              <Typography fontSize="0.9rem">
                                {index + 1}. {documentNameMap?.[doc] || doc}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      }
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          cursor: 'pointer',
                          transition: 'transform 0.2s ease-in-out',
                          '&:hover': {
                            transform: 'scale(1.05)',
                          },
                        }}
                      >
                        <ErrorRounded
                          sx={{
                            height: '2rem',
                            width: '2rem',
                            color: '#FF4D4F',
                          }}
                        />
                        <Typography marginLeft="0.25rem">
                          <b>{missingDocumentsCount}</b>
                          {missingDocumentsCount === 1
                            ? ' Document Missing'
                            : ' Documents Missing'}
                        </Typography>
                      </Box>
                    </Tooltip>
                    <Tooltip title="Add to Errors">
                      <IconButton
                        onClick={() => {
                          addDocumentsMissingError();
                        }}
                      >
                        <ListPlus className="ml-1 text-2xl cursor-pointer" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                {missingDocumentsCount === 0 && (
                  <Box
                    margin="0 0 auto auto"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CheckCircleOutlined
                      style={{ color: '#52C41A', fontSize: '2rem' }}
                    />
                    <Typography marginLeft="0.25rem" marginRight="0.25rem">
                      All Documents Uploaded
                    </Typography>
                  </Box>
                )}
              </Box>
              <Divider style={{ margin: '0.5rem 0 0.5rem 0' }} />
              <Box
                display="flex"
                gap={3}
                marginTop="1rem"
                sx={{
                  '@media (max-width: 900px)': {
                    flexDirection: 'column',
                  },
                }}
              >
                {/* Left Side - Transaction Details */}
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  sx={{
                    flex: '1',
                    minWidth: 0, // Prevents flex items from overflowing
                  }}
                >
                  {/* Top Row */}
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(3, 1fr)"
                    gap={2}
                  >
                    <Box className="detail-group">
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Review Status
                      </Typography>
                      <Typography>
                        {transactionProcessingStatusMap[
                          complianceTransaction?.processingStatus?.status
                        ]?.name || '-'}
                      </Typography>
                    </Box>

                    <Box className="detail-group">
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Closing Date
                      </Typography>
                      <Typography>
                        {getSimpleDateString(
                          complianceTransaction?.closingDate
                        )}
                      </Typography>
                    </Box>

                    <Box className="detail-group">
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          gutterBottom
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          {getCompliancePartnerID() === partners.KEYES
                            ? 'Deal Number'
                            : 'Dotloop Link'}
                          {complianceTransaction?.loop && (
                            <div className="relative group inline-block">
                              {isAdmin ? (
                                <button
                                  className="p-1 rounded-full hover:bg-gray-100 transition-transform duration-200 ease-in-out hover:scale-110 hover:text-blue-500 focus:outline-none"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    Modal.confirm({
                                      title: 'Sync with Dotloop',
                                      content:
                                        'Are you sure you want to sync this transaction with Dotloop?',
                                      onOk: async () => {
                                        try {
                                          setProgressBarLoading(true);
                                          await ComplianceService.syncTransactionWithDotloop(
                                            transactionID
                                          );
                                          message.success(
                                            'Sync with Dotloop initiated successfully'
                                          );
                                          getTransaction(false);
                                        } catch (error) {
                                          console.error(
                                            'Error syncing with Dotloop:',
                                            error
                                          );
                                          message.error(
                                            'Failed to sync with Dotloop'
                                          );
                                        } finally {
                                          setProgressBarLoading(false);
                                        }
                                      },
                                    });
                                  }}
                                >
                                  {complianceTransaction?.loop
                                    ?.syncingTransactionWithDotloop &&
                                  Date.now() -
                                    (complianceTransaction?.loop
                                      ?.dotloopSyncStartedAt || 0) <
                                    20 * 60 * 1000 ? (
                                    <RefreshCw
                                      className="animate-spin"
                                      size={16}
                                    />
                                  ) : (
                                    <RefreshCw size={14} />
                                  )}
                                </button>
                              ) : (
                                <div className="rounded-full transition-transform duration-200 ease-in-out hover:scale-110 text-gray-500">
                                  <Info size={16} />
                                </div>
                              )}
                              <div className="absolute z-10 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-200 bottom-full left-1/2 transform -translate-x-1/2 -translate-y-1 w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg whitespace-pre-line">
                                {complianceTransaction?.loop
                                  ?.syncingTransactionWithDotloop &&
                                Date.now() -
                                  (complianceTransaction?.loop
                                    ?.dotloopSyncStartedAt || 0) <
                                  20 * 60 * 1000
                                  ? `Sync in progress (started ${new Date(
                                      complianceTransaction?.loop
                                        ?.dotloopSyncStartedAt || 0
                                    ).toLocaleString()})`
                                  : complianceTransaction?.loop
                                        ?.dotloopSyncCompletedAt
                                    ? `${isAdmin ? 'Click to sync \n' : ''}(Last sync completed: ${new Date(
                                        complianceTransaction?.loop?.dotloopSyncCompletedAt
                                      ).toLocaleString()})`
                                    : isAdmin
                                      ? 'Sync with Dotloop (no previous sync / sync failed)'
                                      : 'No previous sync or sync failed'}
                                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-800"></div>
                              </div>
                            </div>
                          )}
                        </Typography>
                      </Box>
                      {renderExternalSourceID()}
                    </Box>
                  </Box>

                  {/* Bottom Row */}
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(3, 1fr)"
                    gap={2}
                  >
                    <Box className="detail-group">
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Creation Date
                      </Typography>
                      <Typography>
                        {new Date(
                          complianceTransaction.createdAt
                        ).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                      </Typography>
                    </Box>

                    <Box className="detail-group">
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Transaction Type
                      </Typography>
                      <Typography>
                        {TRANSACTION_TYPE_OPTIONS(
                          complianceTransaction?.type
                        ) || '-'}
                      </Typography>
                    </Box>

                    <Box className="detail-group">
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Transaction Milestone
                      </Typography>
                      <Select
                        loading={updatingMilestone}
                        defaultValue={
                          complianceTransaction?.milestone || undefined
                        }
                        value={complianceTransaction?.milestone || undefined}
                        onChange={(value) => updateTransactionMilestone(value)}
                        style={{ width: '100%', fontFamily: 'Roboto' }}
                      >
                        {Object.entries(transactionMilestones)
                          .filter(([key, value]) => {
                            const compliancePartnerID =
                              getCompliancePartnerID();
                            const transactionType =
                              complianceTransaction?.type ?? '';

                            const excludedMilestones =
                              excludedMilestonesPerPartnerPerTransactionType[
                                compliancePartnerID as keyof typeof excludedMilestonesPerPartnerPerTransactionType
                              ]?.[transactionType ?? ''] ||
                              excludedMilestonesPerPartnerPerTransactionType[
                                compliancePartnerID as keyof typeof excludedMilestonesPerPartnerPerTransactionType
                              ]?.['PURCHASE'] ||
                              [];
                            return !excludedMilestones.includes(key);
                          })
                          .map(([key, value]) => (
                            <Select.Option key={value} value={value}>
                              {capitalizeFirstLetter(
                                value.toLowerCase().replace(/_/g, ' ')
                              )}
                            </Select.Option>
                          ))}
                      </Select>
                    </Box>
                  </Box>
                  {adminProfile &&
                  getCompliancePartnerID() === partners.KEYES ? (
                    <Box className="detail-group">
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Assigned Agents
                      </Typography>
                      <Select
                        mode="multiple"
                        showSearch
                        loading={updatingAgents || searchingAgents}
                        value={complianceTransaction?.agents?.map(
                          (agent) => agent.details?._id || agent?._id
                        )}
                        disabled={updatingAgents}
                        options={agentOptions}
                        onChange={updateTransactionAgents}
                        onSearch={(value) => setSearchText(value)}
                        filterOption={false}
                        placeholder="Search and select agents"
                        notFoundContent={
                          searchingAgents ? <Spin size="small" /> : null
                        }
                        style={{ minWidth: '25rem' }}
                      ></Select>
                    </Box>
                  ) : null}
                  {isTagEnabled ? (
                    <Box className="detail-group">
                      <TagDisplay
                        tags={tagsByEntity[transactionID] || []}
                        maxInitialTags={3}
                        loading={isLoadingForEntity(transactionID)}
                        isVertical={false}
                      />
                    </Box>
                  ) : null}
                </Box>

                {/* Right Side - Remarks */}
                <Box
                  sx={{
                    width: '40%',
                    '@media (max-width: 900px)': {
                      width: '100%',
                    },
                  }}
                >
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Remarks
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap={2}
                      height="100%"
                    >
                      <textarea
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        disabled={getCompliancePartnerID() === partners.KEYES}
                        placeholder="Add remarks here..."
                        style={{
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          padding: '0.75rem',
                          borderRadius: '0.5rem',
                          border: '1px solid #d9d9d9',
                          resize: 'vertical',
                          fontSize: '0.9rem',
                          flexGrow: 1,
                          backgroundColor:
                            getCompliancePartnerID() === partners.KEYES
                              ? '#f5f5f5'
                              : '#fff',
                          cursor:
                            getCompliancePartnerID() === partners.KEYES
                              ? 'not-allowed'
                              : 'text',
                          minHeight: '7rem',
                        }}
                      />
                      <Button
                        type="primary"
                        onClick={updateRemarks}
                        loading={savingRemarks}
                        disabled={getCompliancePartnerID() === partners.KEYES}
                      >
                        {savingRemarks ? 'Saving...' : 'Save'}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Card>
          )}
        </Box>

        {deleteConfirmModal.visible && (
          <Modal
            title="Are you sure you want to delete this document?"
            open={deleteConfirmModal.visible}
            onOk={async () => {
              try {
                if (deleteConfirmModal.recordId) {
                  await ComplianceService.deleteDocument(
                    deleteConfirmModal.recordId
                  );
                  message.success('Document deleted successfully');
                  getTransaction(false);
                }
              } catch (error: any) {
                message.error(
                  error?.response?.data?.message || 'Failed to delete document'
                );
                console.error('Error deleting document:', error);
              } finally {
                setDeleteConfirmModal({ visible: false, recordId: null });
              }
            }}
            onCancel={() =>
              setDeleteConfirmModal({ visible: false, recordId: null })
            }
            okText="Yes, Delete"
            cancelText="Cancel"
          >
            <p>
              This will also delete this check from <b>Checks view.</b>
            </p>
          </Modal>
        )}

        <Modal
          title="Upload Documents"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Dragger
            style={{
              width: '100%',
              marginBottom: '1rem',
            }}
            multiple={true}
            accept=".pdf"
            beforeUpload={beforeUpload}
            onChange={(info) => {
              const { status } = info.file;
              if (status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (status === 'done') {
                message.success(
                  `${info.file.name} file uploaded successfully.`
                );
              } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
            onRemove={(file) => {
              setFilesToUpload(filesToUpload.filter((f) => f.uid !== file.uid));
              setFaultyFiles(
                faultyFiles.filter(
                  (faultyFileName) => faultyFileName !== file.name
                )
              );
            }}
            fileList={filesToUpload}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">File formats accepted: PDF</p>
            <p className="ant-upload-hint">
              Maximum Upload Packet Size: 100 MB
            </p>
          </Dragger>
        </Modal>

        {/* TODO: Modularise this better */}
        {isDocumentSignatureEnabled ? <MultiSelectActions /> : null}
        {view === 'folder' ? null : ( // <FolderView />
          <Table
            dataSource={sortDocuments(complianceTransaction?.documents || [])}
            columns={columns}
            style={{ cursor: 'pointer', tableLayout: 'fixed' }}
            pagination={false}
            onRow={(record: Document) => ({
              onClick: () => !record.isSeparator && handleDocumentClick(record),
            })}
            components={{
              body: {
                row: (props: any) => {
                  if (props?.children[0]?.props?.record?.isSeparator) {
                    return (
                      <tr>
                        <td
                          colSpan={columns.length}
                          style={{
                            padding: 0,
                            borderBottom: 'none !important',
                          }}
                        >
                          <Divider
                            style={{ margin: '1rem 0', borderColor: '#c2e2fb' }}
                          />
                        </td>
                      </tr>
                    );
                  }
                  return <tr {...props} />;
                },
              },
            }}
          />
        )}
      </Box>
    </Box>
  );

  return <WaitAndLoadingWithSpinner loading={loading} component={component} />;
};

export default ComplianceTransactionDetailsPage;
