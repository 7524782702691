import {
  getAgentPartnerID,
  getCompliancePartnerID,
  getOfficeAdminPartnerID,
} from './localStorageHandler/userProfile';

import bairdAndWarner from 'assets/logos/baird-and-warner.jpg';
import barronTeamLogo from 'assets/logos/barron-team.svg';
import jasonMitchellGroupLogo from 'assets/logos/jason-mitchell-group.png';
import keyesCompanyLogo from 'assets/logos/keyes-company.webp';
import { partners } from 'app/configs/partner';

const _getPartnerLogo = (partnerID: string) => {
  switch (partnerID) {
    case partners.BARRON_TEAM:
      return barronTeamLogo;
    case partners.BAIRD_AND_WARNER:
      return bairdAndWarner;
    case partners.JMG:
      return jasonMitchellGroupLogo;
    case partners.KEYES:
      return keyesCompanyLogo;
    default:
      return '';
  }
};

export const getLoggedInPartnerLogo = (role: string) => {
  // TODO: Logo must be received from the server
  let partnerID = '';
  if (role === 'Agent') {
    partnerID = getAgentPartnerID() || '';
  } else if (role === 'Compliance') {
    partnerID = getCompliancePartnerID() || '';
  } else if (role === 'OfficeAdmin') {
    partnerID = getOfficeAdminPartnerID() || '';
  }
  return _getPartnerLogo(partnerID || '');
};

export const getPartnerLogoByID = (partnerID: string) => {
  return _getPartnerLogo(partnerID);
};
