import { Chip } from '@mui/material';
import { ReactElement } from 'react';

export const ErrorSeverityChips: Record<string, ReactElement> = {
  required: (
    <Chip
      label="Required"
      size="small"
      sx={{
        backgroundColor: '#FFD6CB',
        color: '#AB0600',
        borderRadius: '4px',
        fontWeight: 500,
      }}
    />
  ),
  optional: (
    <Chip
      label="Optional"
      size="small"
      sx={{
        backgroundColor: '#FFE0C0',
        color: '#AB6F00',
        borderRadius: '4px',
        fontWeight: 500,
      }}
    />
  ),
};
