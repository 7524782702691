import React, { createContext, useContext, useState } from 'react';
import { STATUS_MAPPING } from './types';

interface Office {
  id: string;
  name: string;
}

export interface FilterState {
  statuses: string[];
  checkTypes: string[];
  transactionOffices: Office[];
  receivingOffices: Office[];
  readyToScan: boolean | null;
  dateFilter: { gte: string; lte: string };
  depositDateFilter: { gte: string; lte: string };
  searchTerm: string;
  statusLabels: string[];
  activeButtonIndex: number | null;
  page: number;
  transactionMilestones: string;
}

interface FilterContextType {
  filters: FilterState;
  setStatuses: (statuses: string[]) => void;
  setCheckTypes: (types: string[]) => void;
  setTransactionOffices: (offices: Office[]) => void;
  setReceivingOffices: (offices: Office[]) => void;
  setReadyToScan: (ready: boolean | null) => void;
  setDateFilter: (filter: { gte: string; lte: string }) => void;
  setDepositDateFilter: (filter: { gte: string; lte: string }) => void;
  setSearchTerm: (term: string) => void;
  clearFilters: () => void;
  setStatusLabels: (labels: string[]) => void;
  setActiveButtonIndex: (index: number | null) => void;
  setPage: (index: number | null) => void;
  setTransactionMilestones: (milestone: string) => void;
}

const FILTER_STORAGE_KEY = 'checkFilters';

const getDefaultFilters = (isCompliance: boolean): FilterState => ({
  statuses: isCompliance ? [STATUS_MAPPING['Needs Action']] : [],
  checkTypes: [],
  transactionOffices: [],
  receivingOffices: [],
  readyToScan: null,
  dateFilter: { gte: '', lte: '' },
  depositDateFilter: { gte: '', lte: '' },
  searchTerm: '',
  statusLabels: isCompliance ? ['Needs Action'] : [],
  activeButtonIndex: null,
  page: 1,
  transactionMilestones: '',
});

const FilterContext = createContext<FilterContextType | undefined>(undefined);

const getStoredFilters = (isCompliance: boolean): FilterState => {
  try {
    const stored = sessionStorage.getItem(FILTER_STORAGE_KEY);
    if (stored) {
      return JSON.parse(stored);
    }
  } catch (error) {
    console.error('Error reading stored filters:', error);
  }
  return getDefaultFilters(isCompliance);
};

const saveFilters = (filters: FilterState) => {
  try {
    sessionStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(filters));
  } catch (error) {
    console.error('Error saving filters:', error);
  }
};

export const FilterProvider: React.FC<{
  children: React.ReactNode;
  isCompliance: boolean;
}> = ({ children, isCompliance }) => {
  const [filters, setFilters] = useState<FilterState>(() =>
    getStoredFilters(isCompliance)
  );
  const isOfficeAdmin = Boolean(localStorage.getItem('Office'));

  const updateFilters = (updater: (prev: FilterState) => FilterState) => {
    setFilters((prev) => {
      const newState = updater(prev);
      saveFilters(newState);
      return newState;
    });
  };

  const setStatuses = (statuses: string[]) => {
    updateFilters((prev) => ({ ...prev, statuses }));
  };

  const setCheckTypes = (checkTypes: string[]) => {
    updateFilters((prev) => ({ ...prev, checkTypes }));
  };

  const setTransactionOffices = (offices: Office[]) => {
    updateFilters((prev) => {
      const newState = { ...prev, transactionOffices: offices };
      return newState;
    });
  };

  const setReceivingOffices = (offices: Office[]) => {
    updateFilters((prev) => {
      const newState = { ...prev, receivingOffices: offices };
      return newState;
    });
  };

  const setReadyToScan = (ready: boolean | null) => {
    updateFilters((prev) => ({
      ...prev,
      readyToScan: ready !== undefined ? ready : null,
    }));
  };

  const setDateFilter = (dateFilter: { gte: string; lte: string }) => {
    updateFilters((prev) => ({ ...prev, dateFilter }));
  };

  const setDepositDateFilter = (depositDateFilter: {
    gte: string;
    lte: string;
  }) => {
    updateFilters((prev) => ({ ...prev, depositDateFilter }));
  };

  const setSearchTerm = (searchTerm: string) => {
    updateFilters((prev) => ({ ...prev, searchTerm }));
  };

  const setStatusLabels = (statusLabels: string[]) => {
    updateFilters((prev) => ({ ...prev, statusLabels }));
  };

  const setActiveButtonIndex = (activeButtonIndex: number | null) => {
    updateFilters((prev) => ({ ...prev, activeButtonIndex }));
  };

  const setPage = (page: number | null) => {
    updateFilters((prev) => ({ ...prev, page: page || 1 }));
  };

  const setTransactionMilestones = (transactionMilestone: string) => {
    updateFilters((prev) => ({ ...prev, transactionMilestone }));
  };

  const clearFilters = () => {
    const defaultFilters = getDefaultFilters(false);
    setFilters(defaultFilters);
    saveFilters(defaultFilters);
  };

  return (
    <FilterContext.Provider
      value={{
        filters,
        setStatuses,
        setCheckTypes,
        setTransactionOffices,
        setReceivingOffices,
        setReadyToScan,
        setDateFilter,
        setDepositDateFilter,
        setSearchTerm,
        clearFilters,
        setStatusLabels,
        setActiveButtonIndex,
        setPage,
        setTransactionMilestones,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilters must be used within a FilterProvider');
  }
  return context;
};
