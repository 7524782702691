import { Box, IconButton, Typography } from '@mui/material';
import { CloseOutlined, SettingsOutlined } from '@mui/icons-material';
import { Modal, Popconfirm, message } from 'antd';
import {
  getCompliancePartnerID,
  getUserEmail,
  getUserProfiles,
} from 'app/utils/localStorageHandler/userProfile';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppURLs } from 'app/utils/appURLs';
import { Module } from 'app/pages/Agent/Dashboard/components/types';
import UserForm from '../UserForm/UserForm';
import { UserService } from 'app/services/user';
import { firebaseAuth } from 'App';
import { firebaseSignOut } from 'app/utils/firebase';
import { getLoggedInPartnerLogo } from 'app/utils/logo';
import maxHomeLogo from 'assets/logos/maxhome.svg';
import { partners } from 'app/configs/partner';
import { sendPasswordResetEmail } from 'firebase/auth';
import { toast } from 'react-toastify';

const iconStyle: React.CSSProperties = {
  fontSize: '2rem',
  cursor: 'pointer',
  color: '#2F78EB',
  borderRadius: '10px',
  margin: '0.5rem',
};

const logoStyleFull: React.CSSProperties = {
  maxWidth: '6rem',
  maxHeight: '2.5rem',
};

const logout = async () => {
  await firebaseSignOut();
  window.open(AppURLs.agentLogin(), '_self');
};

const CommonLeftNavBar = ({
  initialModules,
  onClick = null,
}: {
  initialModules: Module[];
  onClick?: any;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const settingsModule: any = {
    name: 'Settings',
    icon: SettingsOutlined,
    path: '',
    alignment: 'bottom',
    children: [
      {
        name: 'Change Password',
        onClick: () => {
          sendPasswordResetEmail(firebaseAuth, getUserEmail())
            .then(() => {
              toast.success(
                'Password reset email sent! Please check your inbox.'
              );
            })
            .catch((error: any) => {
              toast.error('Error: ' + error.message);
            });
        },
      },
      {
        name: 'Switch Roles',
        onClick: () => {
          setSwitchRolesOpen((prevSwitchRolesOpen) => {
            return !prevSwitchRolesOpen;
          });
        },
        children: [], // To be populated dynamically
      },
      ...(getUserProfiles().some(
        (profile: any) =>
          profile.role === 'ComplianceAdmin' || profile.role === 'Admin'
      )
        ? [
            {
              name: 'Add User',
              onClick: () => {
                setIsModalOpen(true);
                setFormData({
                  name: '',
                  email: '',
                  officeID: null,
                  rolesList: [],
                });
              },
            },
          ]
        : []),
      {
        name: 'Logout',
        onClick: () => {
          logout();
        },
      },
    ],
  };

  const [modules, setModules] = useState(initialModules.concat(settingsModule));
  const [selectedModule, setSelectedModule] = useState<number | null>(-1);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [switchRolesOpen, setSwitchRolesOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [formData, setFormData] = useState<any>({
    name: '',
    email: '',
    officeID: null,
    rolesList: [] as string[],
  });
  const compliancePartnerID = getCompliancePartnerID();
  console.log('compliancePartnerID: ', compliancePartnerID);

  const onClickModule = (index: number) => {
    const selected = modules[index];
    if (selected.name === 'Settings') {
      setSettingsOpen(!settingsOpen);
    } else {
      setSelectedModule(index);
      onClick && onClick();
      navigate(selected.path);
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    let moduleIndex = -1;
    setModules(initialModules.concat(settingsModule));
    const verifyDocumentPath =
      AppURLs.complianceDashboardTransactionVerifyDocument('', '').split(
        '?'
      )[0];
    const validateCheckPath =
      AppURLs.complianceDashboardValidation('').split('?')[0];

    initialModules.concat(settingsModule).forEach((module, index) => {
      if (
        currentPath.startsWith(verifyDocumentPath) ||
        currentPath.startsWith(validateCheckPath)
      ) {
        moduleIndex = 0;
      } else if (
        module.path &&
        currentPath.includes(module?.path?.split('?')[0])
      ) {
        moduleIndex = index;
      }
    });

    if (
      currentPath.startsWith(validateCheckPath) ||
      currentPath.startsWith(validateCheckPath)
    ) {
      setSelectedModule(moduleIndex);
    } else if (
      moduleIndex !== -1 &&
      modules[moduleIndex]?.name !== 'Settings'
    ) {
      setSelectedModule(moduleIndex);
    }

    // Load profiles for Switch Roles
    const profiles = getUserProfiles();
    setModules((prevModules) =>
      prevModules.map((module) => {
        if (module.name === 'Settings') {
          return {
            ...module,
            children: module.children?.map((child: any) => {
              if (child.name === 'Switch Roles') {
                return {
                  ...child,
                  children: profiles
                    .filter(
                      (profile: any) =>
                        profile?.roleName !== 'Admin' &&
                        profile?.role !== 'ComplianceAdmin'
                    )
                    .map((profile: any) => ({
                      name: profile?.roleName,
                      onClick: () => {
                        if (profile.role === 'Agent') {
                          window.open(
                            AppURLs.agentDashboardMainHome(),
                            '_self'
                          );
                        } else if (profile.role === 'Compliance') {
                          if (compliancePartnerID === partners.KEYES) {
                            // Navigate to keyes for compliance
                            window.open(
                              AppURLs.complianceDashboardTransactions(),
                              '_self'
                            );
                          } else {
                            window.open(
                              AppURLs.complianceDashboardChecks(),
                              '_self'
                            );
                          }
                        } else if (profile.role === 'OfficeAdmin') {
                          window.open(AppURLs.officeAdminChecks(), '_self');
                        }
                      },
                    })),
                };
              }
              return child;
            }),
          };
        }
        return module;
      })
    );
  }, [location, initialModules]);

  const handleFormSubmit = async () => {
    setButtonLoading(true);

    try {
      const userFormData = {
        ...formData,
        partnerID: compliancePartnerID ?? '',
      };

      await UserService.createUser(userFormData);

      setIsModalOpen(false);
      message.success('User added successfully!');
    } catch (error: any) {
      console.error('Error adding user: ', error);
      message.error('Failed to add user. Please try again.');
    } finally {
      setButtonLoading(false);
      resetFormData();
    }
  };

  const resetFormData = () => {
    setFormData({
      name: '',
      email: '',
      officeID: null,
      rolesList: [],
    });
  };

  return (
    <Box
      padding="1rem"
      height="100%"
      width="100%"
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        boxSizing: 'border-box',
      }}
    >
      <Box display="flex" marginBottom="1.5rem">
        <img
          src={getLoggedInPartnerLogo(getUserProfiles()[0]?.role)}
          alt="Partner Logo"
          style={logoStyleFull}
        />
        <img
          src={maxHomeLogo}
          alt="MaxHome Logo"
          style={{
            ...logoStyleFull,
            marginLeft: '0.5rem',
            paddingLeft: '0.5rem',
            borderLeft: '1px solid black',
          }}
        />
        {onClick && (
          <IconButton sx={{ marginLeft: 'auto' }} onClick={() => onClick()}>
            <CloseOutlined />
          </IconButton>
        )}
      </Box>

      {modules &&
        modules.map((module, index) => {
          const Icon = module.icon;
          return (
            <Box
              display="flex"
              flexDirection="column"
              key={index}
              marginBottom={module.name === 'Settings' ? '0.5rem' : ''}
            >
              <Box
                display="flex"
                alignItems="center"
                fontSize="1rem"
                fontWeight={550}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: `${index === selectedModule ? '#F6F6F6' : ''}`,
                  transition: 'background-color 0.3s, transform 0.3s',
                  '&:hover': {
                    backgroundColor: '#E0E0E0',
                    transform: 'scale(1.05)',
                  },
                }}
                borderRadius="16px"
                marginTop={module.alignment === 'normal' ? 0 : 'auto'}
                onClick={() => onClickModule(index)}
              >
                <Icon style={{ ...iconStyle }} />
                <span style={{ lineHeight: '1.25rem' }}>{module.name}</span>
              </Box>

              {module.name === 'Settings' && settingsOpen && (
                <Box
                  fontSize="0.9rem"
                  marginLeft="1rem"
                  display="flex"
                  flexDirection="column"
                >
                  {module.children?.map((child: any, childIndex: number) => (
                    <Box
                      key={childIndex}
                      sx={{
                        borderRadius: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      {child.name === 'Logout' ||
                      child.name === 'Change Password' ? (
                        <Popconfirm
                          title={child.name}
                          description={`Are you sure you want to ${child.name.toLowerCase()}?`}
                          okText="Yes"
                          cancelText="No"
                          onConfirm={child.onClick}
                        >
                          <Box
                            sx={{
                              padding: '0.5rem 0 0.5rem 1rem',
                              transition:
                                'background-color 0.3s, transform 0.3s',
                              '&:hover': {
                                backgroundColor: '#E0E0E0',
                                transform: 'scale(1.05)',
                              },
                              borderRadius: '16px',
                            }}
                          >
                            {child.name}
                          </Box>
                        </Popconfirm>
                      ) : (
                        <Box
                          sx={{
                            padding: '0.5rem 0 0.5rem 1rem',
                            transition: 'background-color 0.3s, transform 0.3s',
                            '&:hover': {
                              backgroundColor: '#E0E0E0',
                              transform: 'scale(1.05)',
                            },
                            borderRadius: '16px',
                          }}
                          onClick={child.onClick}
                        >
                          {child.name}
                        </Box>
                      )}
                      {child.name === 'Switch Roles' && switchRolesOpen && (
                        <Box
                          marginLeft="1.5rem"
                          display="flex"
                          flexDirection="column"
                        >
                          {child.children?.map(
                            (role: any, roleIndex: number) => (
                              <Box
                                key={roleIndex}
                                sx={{
                                  cursor: 'pointer',
                                  padding: '0.5rem 0 0.5rem 0.5rem',
                                  transition:
                                    'background-color 0.3s, transform 0.3s',
                                  '&:hover': {
                                    backgroundColor: '#E0E0E0',
                                    transform: 'scale(1.05)',
                                  },
                                  borderRadius: '16px',
                                }}
                                onClick={role.onClick}
                              >
                                {role.name}
                              </Box>
                            )
                          )}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          );
        })}

      <Modal
        title={
          <Typography fontSize="1.25rem" fontWeight={500} marginBottom={2}>
            Add User
          </Typography>
        }
        open={isModalOpen}
        onCancel={() => {
          resetFormData();
          setIsModalOpen(false);
        }}
        afterClose={resetFormData}
        onOk={handleFormSubmit}
        cancelText="Cancel"
        okText={buttonLoading ? 'Submitting...' : 'Submit'}
        okButtonProps={{ loading: buttonLoading }}
      >
        <UserForm
          setFormData={setFormData}
          partnerID={compliancePartnerID ?? ''}
          formData={formData}
        />
      </Modal>
    </Box>
  );
};

export default CommonLeftNavBar;
