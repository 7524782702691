export const CheckStatusMap: Record<string, string> = {
  NOT_STARTED: 'Under Review',
  PROCESSING: 'Under Review',
  LOOP_NOT_FOUND: 'Needs Action : Loop Not Found',
  DOCS_MISSING: 'Needs Action : Documents Missing',
  VALIDATION_FAILED: 'Needs Action : Validation Failed',
  PRISM_NOT_FOUND: 'Needs Action : Prism Not Found',
  COMPLETED: 'Max Approved',
  DEPOSITED: 'Deposited',
};

export const CheckFilterStatusMap: Record<string, string> = {
  NOT_STARTED: 'Under Review',
  PROCESSING: 'Under Review',
  LOOP_NOT_FOUND: 'Needs Action',
  DOCS_MISSING: 'Needs Action',
  VALIDATION_FAILED: 'Needs Action',
  PRISM_NOT_FOUND: 'Needs Action',
  COMPLETED: 'Max Approved',
  DEPOSITED: 'Deposited',
};

export const CheckStatusMapOriginal: Record<string, string> = {
  NOT_STARTED: 'Not Started',
  PROCESSING: 'Processing',
  LOOP_NOT_FOUND: 'Needs Action : Loop Not Found',
  DOCS_MISSING: 'Needs Action : Documents Missing',
  VALIDATION_FAILED: 'Needs Action : Validation Failed',
  PRISM_NOT_FOUND: 'Needs Action : Prism Not Found',
  COMPLETED: 'Max Approved',
};

export const CHECK_STATUSES = {
  NOT_STARTED: 'NOT_STARTED',
  PROCESSING: 'PROCESSING',
  LOOP_NOT_FOUND: 'LOOP_NOT_FOUND',
  DOCS_MISSING: 'DOCS_MISSING',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
  PRISM_NOT_FOUND: 'PRISM_NOT_FOUND',
  COMPLETED: 'COMPLETED',
  DEPOSITED: 'DEPOSITED',
};

export const STATUS_MAPPING = {
  'Under Review': `${CHECK_STATUSES.NOT_STARTED},${CHECK_STATUSES.PROCESSING}`,
  'Needs Action': `${CHECK_STATUSES.LOOP_NOT_FOUND},${CHECK_STATUSES.DOCS_MISSING},${CHECK_STATUSES.VALIDATION_FAILED},${CHECK_STATUSES.PRISM_NOT_FOUND}`,
  'Max Approved': CHECK_STATUSES.COMPLETED,
  Deposited: CHECK_STATUSES.DEPOSITED,
} as const;
