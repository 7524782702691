import React, { useEffect, useState } from 'react';
import { Modal, Table, Typography, Button, Select, message } from 'antd';
import { ExternalLink } from 'lucide-react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ComplianceService } from 'app/services/compliance';
import { capitalizeFirstLetter } from 'app/utils/string';
import { getNumericDateStringLong } from 'app/utils/datetime';
import { transactionMilestoneMap } from 'app/pages/Compliance/Dashboard/components/colorScheme';

interface TransactionExistsModalProps {
  open: boolean;
  onClose: () => void;
  initialTransactions: any[];
  onCreate: () => void;
  disable?: boolean;
}

const TransactionExistsModal: React.FC<TransactionExistsModalProps> = ({
  open,
  onClose,
  initialTransactions,
  onCreate,
  disable,
}) => {
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({
    creating: false,
  });
  const [transactions, setTransactions] = useState(initialTransactions);

  const updateTransactionMilestone = async (
    transactionId: string,
    milestone: string
  ) => {
    // Update loading state for specific transaction
    try {
      await ComplianceService.updateTransactionMilestone(
        transactionId,
        milestone
      );
      // Update the local transactions state with the new milestone
      setTransactions((prev) =>
        prev.map((t) => (t._id === transactionId ? { ...t, milestone } : t))
      );
      message.success('Milestone updated successfully');
    } catch (error) {
      console.error('Failed to update milestone:', error);
      message.error('Failed to update milestone');
    }
  };

  const handleCreateTransaction = async () => {
    if (!onCreate) return;

    setLoadingStates((prev) => ({ ...prev, creating: true }));
    try {
      await onCreate();
    } catch (error) {
      console.error('Error creating transaction:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, creating: false }));
      onClose();
    }
  };

  const areAllRowsDisabled =
    disable ||
    transactions.every((transaction) =>
      transaction.terminalMilestones.includes(transaction.milestone)
    );

  const columns = [
    {
      title: 'Property Address',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => {
        const isDisabled =
          record.terminalMilestones.includes(record.milestone) || disable;
        return (
          <div
            className={`flex items-center gap-2 ${isDisabled ? 'opacity-50 pointer-events-none' : ''}`}
          >
            <span>{text}</span>
            <Button
              type="link"
              icon={<ExternalLink size={16} />}
              disabled={isDisabled || disable}
            />
          </div>
        );
      },
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: any) => {
        const isDisabled =
          record.terminalMilestones.includes(record.milestone) || disable;
        return (
          <span className={isDisabled ? 'opacity-50' : ''}>
            {getNumericDateStringLong(text) || 'N/A'}
          </span>
        );
      },
    },
    {
      title: 'Milestone',
      dataIndex: 'milestone',
      key: 'milestone',
      render: (milestone: string, record: any) => {
        const isDisabled = disable;
        return (
          <Select
            loading={loadingStates[record._id] || false}
            defaultValue={milestone || undefined}
            value={
              transactionMilestoneMap[milestone]?.name || milestone || undefined
            }
            onChange={(value) => updateTransactionMilestone(record._id, value)}
            className="w-full"
            disabled={isDisabled}
          >
            {record.terminalMilestones.map((value: string) => (
              <Select.Option key={value} value={value}>
                {capitalizeFirstLetter(value.toLowerCase().replace(/_/g, ' '))}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
  ];

  useEffect(() => {
    setTransactions(initialTransactions);
  }, [initialTransactions]);

  return (
    <Modal
      title={
        <div className="flex items-center gap-2">
          <ExclamationCircleOutlined className="text-yellow-500 text-xl" />
          <span>Transaction Already Exists</span>
        </div>
      }
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
        <Button
          key="create"
          type="primary"
          disabled={!areAllRowsDisabled}
          onClick={handleCreateTransaction}
          loading={loadingStates.creating}
        >
          {disable ? 'Create Check' : 'Create Transaction'}
        </Button>,
      ]}
      width={800}
    >
      <Typography.Paragraph>
        {disable
          ? 'Transactions for this address already exist. Creating a new transaction will mark previous transactions as Dormant.'
          : 'Transactions for this address already exist. Please close all open transactions before creating a new one.'}
      </Typography.Paragraph>

      <Table
        dataSource={transactions}
        columns={columns}
        rowKey="_id"
        pagination={false}
        className="mt-4"
        rowClassName={(record) =>
          record.terminalMilestones.includes(record.milestone)
            ? 'disabled-row'
            : ''
        }
      />
    </Modal>
  );
};

export default TransactionExistsModal;
