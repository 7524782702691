import {
  AddRounded,
  ArrowBackRounded,
  ArrowForwardRounded,
  CheckRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  RemoveRounded,
  ReplyAllRounded,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import {
  Breadcrumb,
  Button,
  Input,
  Modal,
  Switch,
  Tooltip,
  message,
} from 'antd';
import {
  Document as DocumentInterface,
  ErrorField,
} from 'app/interfaces/Document';
import {
  Fragment,
  RefObject,
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Page, Document as PdfDocument, pdfjs } from 'react-pdf';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppURLs } from 'app/utils/appURLs';
import { ComplianceService } from 'app/services/compliance';
import { ComplianceTransaction } from 'app/interfaces/ComplianceTransaction';
import { ErrorActions } from '../utils/ErrorActions';
import { ErrorCardComponent } from '../components/ErrorCard';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Header } from 'app/AgentDetails/Header';
import { StatusChip } from '../components/ReviewStatusChip';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import { capitalizeFirstLetter } from 'app/utils/string';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getCompliancePartnerID } from 'app/utils/localStorageHandler/userProfile';
import { getCustomIdFromUrl } from 'app/utils/url';
import { partners } from 'app/configs/partner';
import { transactionSourceAtom } from 'app/atomManagers/transactionSourceAtom';
import { useRecoilValue } from 'recoil';
import useTransactionDocumentErrorIdsAndActionListManager from 'app/atomManagers/transactionErrorDocumentIdsAndActionListManager';
import useTransactionDocumentErrorsManager from 'app/atomManagers/transactionDocumentErrorsManager';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Feature Flags
const REACT_APP_AGENT_REDESIGN_VERIFY_DOCUMENT_VIEW_ENABLED =
  process.env?.REACT_APP_AGENT_REDESIGN_VERIFY_DOCUMENT_VIEW_ENABLED === 'true';

// TODO: Move this to somewhere else later. This file is too cluttered as is.

const getXYWidthHeight = (
  normalizedVertices: number[],
  pageWidth: number,
  pageHeight: number,
  zoom: number,
  pageNumber: number,
  errorDetection: boolean = false
) => {
  const scale = 1; // in percentage
  const widthExtra = 0; // In px

  const tl_x = normalizedVertices[0];
  const tl_y = normalizedVertices[1];
  const br_x = normalizedVertices[2];
  const br_y = normalizedVertices[3];

  // Calculate base coordinates
  const xVal = tl_x * pageWidth;
  const yVal = tl_y * pageHeight;
  const widthVal = (br_x - tl_x) * pageWidth + widthExtra;
  const heightVal = (br_y - tl_y) * pageHeight * scale;

  // Adjust coordinates based on page number
  const pageOffset = (pageNumber - 1) * (pageHeight + 16);

  const left = `${xVal - (widthVal - (br_x - tl_x) * pageWidth) / 2}px`;
  const top = `${yVal - (heightVal - (br_y - tl_y) * pageHeight) / 2 + pageOffset}px`;
  const width = `${widthVal}px`;
  const height = `${heightVal}px`;

  if (errorDetection) {
    console.log(`Error on page ${pageNumber}:`, {
      top,
      left,
      width,
      height,
      pageOffset,
      originalY: yVal,
    });
  }

  return { top, left, width, height };
};

const defaultErrorAction = {
  action: ErrorActions.IGNORE,
  // Keeping this as a Record/Object so that it can be easily extended in the future
};

export const ComplianceVerifyDocumentPage = () => {
  const errorsManager = useTransactionDocumentErrorsManager();
  const errorDocumentIdsAndActionListManager =
    useTransactionDocumentErrorIdsAndActionListManager();

  const navigate = useNavigate();

  const location = useLocation();
  const documentID = getCustomIdFromUrl('documentID') || '';
  // TODO: This depends on the prefix pieces count. Make it independent
  const transactionID = getCustomIdFromUrl('transactionID') || '';
  console.log('Transaction ID: ', transactionID);

  const [complianceTransaction, setComplianceTransaction] =
    useState<ComplianceTransaction>({} as ComplianceTransaction);
  const [documentType, setDocumentType] = useState('' as string);

  const [pdfData, setPdfData] = useState('');
  const [errors, setErrors] = useState<ErrorField[]>([]);
  const [rawExtract, setRawExtract] = useState<
    Record<string, [string, number, [number, number, number, number], number]>
  >({});
  const [numPages, setNumPages] = useState(0);
  const [page, setPage] = useState(1);
  const [pageErrors, setPageErrors] = useState([] as any[]);
  const [selectedError, setSelectedError] = useState({} as any);
  const [showAllDetections, setShowAllDetections] = useState(false);
  const errorAnnotationRefs = useRef([]);

  const parentRef = useRef<HTMLDivElement>(null);
  const [pageWidth, setPageWidth] = useState(700);
  const [pageRatio, setPageRatio] = useState(0);
  const [zoom, setZoom] = useState(1);

  const [messageShown, setMessageShown] = useState(false);
  const [errorsCountByPageIndex, setErrorsCountByPageIndex] = useState<
    number[]
  >([]);

  const [errorActionsList, setErrorActionsList] = useState<
    Record<string, string>[]
  >([]);
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  const [prevDocument, setPrevDocument] = useState<DocumentInterface>(
    {} as DocumentInterface
  );
  const [nextDocument, setNextDocument] = useState<DocumentInterface>(
    {} as DocumentInterface
  );
  const transactionSource = useRecoilValue(transactionSourceAtom);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  const [activeTab, setActiveTab] = useState('all');

  const [isScrolling, setIsScrolling] = useState(false);

  const [agentDashboard, setAgentDashboard] = useState(false);

  const scrollToPage = (pageNumber: number) => {
    if (scrollContainerRef.current) {
      setIsScrolling(true);
      const pageHeight = pageWidth * zoom * pageRatio + 24;
      const scrollPosition = (pageNumber - 1) * pageHeight;
      scrollContainerRef.current.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });

      // Reset scrolling state after animation
      setTimeout(() => {
        setIsScrolling(false);
      }, 1500); // Adjust timing to match scroll duration
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setSelectedError({});
    scrollToPage(newPage);
  };

  useEffect(() => {
    setPageDimensions();

    ComplianceService.getTransactionDocument(transactionID, documentID).then(
      (response) => {
        console.log('Document:', response.data);
        // setPdfUrl(response.data.signedUrl);
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfURL = URL.createObjectURL(pdfBlob);
        setPdfData(pdfURL);
      },
      (error) => {
        console.error('Error fetching document:', error);
      }
    );

    if (location.pathname === '/agent/dashboard/transactions/verify') {
      setAgentDashboard(true);
    }

    // Get the compliance transaction details as well
    getTransactionDocumentDetails();
    initDocumentNavigation();
  }, [location]);

  useEffect(() => {
    window.addEventListener('resize', setPageDimensions);

    return () => {
      window.removeEventListener('resize', setPageDimensions);
    };
  }, [parentRef]);

  const initDocumentNavigation = () => {
    ComplianceService.getTransactionByID(transactionID)
      .then((response) => {
        console.log('Transaction:', response.data);

        // Also handle the document navigation buttons
        // Get the index of the transactionData.documents
        const documentIndex = response.data.documents.findIndex(
          (doc: Record<string, any>) => doc._id === documentID
        );
        setCurrentDocumentIndex(documentIndex);
        // If the documentIndex is not the first document, then set the prevDocumentIndex
        if (documentIndex > 0) {
          // Check the document's reviewStatus and set prev document to the first document when traversing backwards that
          // is not in 'PROCESSING' reviewStatus
          let prevDocumentIndex = documentIndex - 1;
          while (
            prevDocumentIndex >= 0 &&
            response.data.documents[prevDocumentIndex].displayStatus ===
              'PROCESSING'
          ) {
            prevDocumentIndex--;
          }
          if (prevDocumentIndex >= 0) {
            setPrevDocument(response.data.documents[prevDocumentIndex]);
          } else {
            setPrevDocument({} as DocumentInterface);
          }
        }
        // If the documentIndex is not the last document, then set the nextDocumentIndex
        if (documentIndex < response.data.documents.length - 1) {
          // Check the document's reviewStatus and set next document to the first document when traversing forwards that
          // is not in 'PROCESSING' reviewStatus
          let nextDocumentIndex = documentIndex + 1;
          while (
            nextDocumentIndex < response.data.documents.length &&
            ['PROCESSING', 'NOT_STARTED'].includes(
              response.data.documents[nextDocumentIndex].displayStatus
            )
          ) {
            nextDocumentIndex++;
          }
          if (nextDocumentIndex < response.data.documents.length) {
            setNextDocument(response.data.documents[nextDocumentIndex]);
          } else {
            setNextDocument({} as DocumentInterface);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching transaction:', error);
      });
  };

  const getTransactionDocumentDetails = () => {
    ComplianceService.getTransactionWithDocumentDetails(
      transactionID,
      documentID
    ).then(
      (response) => {
        console.log('Transaction with Document Details:', response.data);
        setComplianceTransaction(response.data);
        setDocumentType(response.data.document.type);
        let errorsFound = false;
        let errorFields = response.data.document.errorFields;
        console.log('Error fields: ', errorFields);
        // If there are errors with severity as 'required', then set activeTab to 'required', else default to 'all'
        if (errorFields.some((error: any) => error?.severity === 'required')) {
          setActiveTab('required');
        } else {
          setActiveTab('all');
        }
        setErrors(errorFields);
        setRawExtract(response.data.document.rawExtract);
        if (response.data.document.errorFields.length > 0 && !messageShown) {
          // message.error(`${response.data.document.errorFields.length} issues found!`);
          errorsFound = true;
          setMessageShown(true);
        }

        // Also create the error annotation refs
        errorAnnotationRefs.current = errorFields.map(
          (_: any, i: number) => errorAnnotationRefs.current[i] ?? createRef()
        );

        if (!errorsFound && !messageShown) {
          // message.success('No issues found!');
          setMessageShown(true);
        }

        // Check if the complianceTransaction already has an errorActionsList, if so, set it
        if (
          response.data.document.errorActions &&
          response.data.document.errorActions.length > 0
        ) {
          setErrorActionsList(response.data.document.errorActions);
        } else {
          let errorActions = Array(errorFields.length).fill(defaultErrorAction);
          console.log('Error Actions gonna set to: ', errorActions);
          setErrorActionsList(errorActions);
        }
      },
      (error) => {
        console.error('Error fetching transaction details:', error);
      }
    );
  };

  const setPageDimensions = () => {
    if (parentRef.current) {
      if (errors.length === 0) {
        setPageWidth(parentRef.current.offsetWidth * 0.9);
      } else {
        setPageWidth(parentRef.current.offsetWidth * 0.7);
      }
      console.log('Parent height: ', parentRef.current.clientHeight);
    }
  };

  useEffect(() => {
    // When page changes, render the error bboxes pagewise
    console.log('Errors in the use effect: ', errors);
    const pageErrors = errors.filter((error) => error['pages'].includes(page));
    console.log('Page Errors:', pageErrors);
    setPageErrors(pageErrors);
  }, [page, errors]);

  useEffect(() => {
    if (numPages > 0) {
      let errorsPerPage = Array(numPages).fill(0);
      console.log('Errors per page before: ', errorsPerPage);
      errors.forEach((error: any) => {
        console.log('Error: ', error);

        error.pages.forEach((page: number) => {
          if (typeof page !== 'number' || page < 1 || page > numPages) {
            console.error('Invalid page value:', page, 'in error:', error);
          } else {
            errorsPerPage[page - 1]++;
          }
        });
      });

      setErrorsCountByPageIndex(errorsPerPage);
      console.log('Setting errors count by page index: ', errorsPerPage);
    }
  }, [numPages, errors]);

  const handleZoomChange = (event: any) => {
    const newZoom = event.target.value as number;
    setZoom(newZoom);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const onRenderSuccess = ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => {
    // Set the Page Ratio so that we can calculate the page height
    setPageRatio(height / width);
  };

  const goToTransactions = () => {
    if (transactionSource === 'compliance-transactions') {
      navigate(AppURLs.complianceDashboardComplianceTransactions());
    } else {
      navigate(AppURLs.complianceDashboardTransactions());
    }
  };

  const goToDocumentsList = () => {
    // Go back to the previous page
    navigate(AppURLs.complianceDashboardTransaction(transactionID));
  };

  const getErrorAnnotationRef = (
    error: any,
    pageNumber: number
  ): RefObject<HTMLDivElement> => {
    const errorIndex = errors.findIndex(
      (e) => e === error && e.pages.includes(pageNumber)
    );

    console.log('Getting ref for error:', {
      errorIndex,
      pageNumber,
      errorPages: error.pages,
    });

    return errorAnnotationRefs.current[errorIndex];
  };

  // const scrollToErrorRef = (error: any, pageNumber: number) => {
  //   const errorRef = getErrorAnnotationRef(error, pageNumber);
  //   console.log('Error ref from the function is: ', errorRef);
  //   if (errorRef?.current) {
  //     errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   } else {
  //     let tries = 0;
  //     const interval = setInterval(() => {
  //       tries++;
  //       if (errorRef?.current) {
  //         console.log('Scrolling to error: ', errorRef.current);
  //         errorRef.current.scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'center',
  //         });
  //         clearInterval(interval);
  //       } else if (tries >= 100) {
  //         clearInterval(interval);
  //         console.log('Failed to get the ref after 20 tries');
  //       }
  //     }, 100);
  //   }
  // };

  const scrollToErrorRef = async (error: any, pageNumber: number) => {
    scrollToPage(pageNumber);

    const vertices = error.fields[0].vertices;

    // After the scrolling is done, check for the error's vertices and accordingly scroll to that

    // Wait for scrolling to finish
    await new Promise((resolve) => {
      const checkScrolling = setInterval(() => {
        if (!isScrolling) {
          clearInterval(checkScrolling);
          resolve(true);
        }
      }, 100);
    });

    // Calculate viewport height
    const viewportHeight = scrollContainerRef.current?.clientHeight || 0;

    // Calculate position of error on page
    const { height: pageHeight } =
      scrollContainerRef.current?.getBoundingClientRect() || {};
    const errorPosition = getXYWidthHeight(
      vertices,
      pageWidth * zoom,
      pageWidth * zoom * pageRatio,
      zoom,
      pageNumber
    );

    // Convert position to number
    const errorTop = parseInt(errorPosition.top);

    // Calculate scroll position to center error
    const scrollPosition = errorTop - viewportHeight / 2;

    // Scroll to center error
    scrollContainerRef.current?.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  };

  const allErrorActionsSet = () => {
    return (
      errorActionsList.length > 0 &&
      errorActionsList.every(
        (errorAction) => errorAction.action !== ErrorActions.NULL
      )
    );
  };

  const approvalDisabled = () => {
    // If the document approval status is COMPLETED, then the approval button should be disabled
    if (
      complianceTransaction?.document?.reviewStatus === 'COMPLETED' ||
      complianceTransaction?.document?.reviewStatus === 'USER_APPROVED'
    ) {
      return true;
    }

    return false;
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const element = event.currentTarget;
    const scrollPosition = element.scrollTop;
    const pageHeight = pageWidth * zoom * pageRatio + 16;
    const currentPage = Math.floor(scrollPosition / pageHeight) + 1;

    if (currentPage !== page && currentPage <= numPages) {
      setPage(currentPage);
      setSelectedError({});

      // Scroll the carousel to keep the current page in view
      if (carouselRef.current) {
        const carouselItemHeight = 180;
        const scrollOffset = (currentPage - 1) * carouselItemHeight;
        carouselRef.current.scrollTo({
          top: scrollOffset,
          behavior: 'smooth',
        });
      }
    }
  };

  const returnToAgentDisabled = () => {
    // If not even on error fields requires a fix, then no point sending back to agent. So disable the button.
    return (
      !allErrorActionsSet() ||
      errorActionsList.every(
        (errorAction) => errorAction.action === ErrorActions.IGNORE
      ) ||
      errorActionsList.every(
        (errorAction, index) =>
          errorAction.action ===
          complianceTransaction?.document?.errorActions?.[index]?.action
      )
    );
  };

  const PDFPagesCarousel = (
    <Box
      width="275px"
      overflow="auto"
      borderRadius="8px"
      ref={carouselRef}
      sx={{
        backgroundColor: 'white',
        boxSizing: 'border-box',
        '::-webkit-scrollbar': {
          width: '0px',
        },
        '::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <PdfDocument file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Box
            key={index}
            textAlign="center"
            padding="1rem"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handlePageChange(index + 1)}
          >
            <Box
              border={
                page === index + 1 ? '2px solid #1677FF' : '1px solid #D9D9D9'
              }
              display="inline-block"
              sx={{
                userSelect: 'none', // Prevents text selection
                cursor: 'default', // Keeps the cursor as default instead of text selector
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)', // Slightly enlarges the box on hover
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Optional: Adds a shadow for depth
                },
              }}
            >
              <Page
                key={`page_${index + 1} `}
                pageNumber={index + 1}
                width={120}
              />
              {errorsCountByPageIndex[index] > 0 && (
                <Box
                  position="relative"
                  borderRadius="100%"
                  sx={{
                    bottom: '-10px', // Adjust as needed for exact positioning
                    right: '-110px', // Adjust as needed for exact positioning
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    color: 'white',
                    backgroundColor: '#FF4D4F',
                    padding: '0.25rem',
                    width: '1rem',
                    height: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {errorsCountByPageIndex[index]}
                </Box>
              )}
            </Box>
            <Box color={page === index + 1 ? '#2F78EB' : 'black'}>
              {index + 1}
            </Box>
          </Box>
        ))}
      </PdfDocument>
    </Box>
  );

  const pdfViewAndAnnotationsComponent = (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      padding="0 1rem 0 1rem"
      boxSizing="border-box"
      borderRadius="8px"
      style={{
        backgroundColor: '#F9FAFA',
      }}
    >
      <Box
        display="flex"
        borderRadius="8px 8px 0 0 "
        alignItems="center"
        width="100%"
        padding="0.5rem"
        boxSizing="border-box"
        sx={{ backgroundColor: '#F9FAFA' }}
        border="1px solid #E5E7EB"
      >
        <Box display="flex" alignItems="center" justifyContent="left">
          <IconButton
            disabled={page <= 1}
            onClick={() => handlePageChange(page - 1)}
          >
            <KeyboardArrowUpRounded sx={{ color: '#7E7E7E' }} />
          </IconButton>
          <Box height="2rem" border="1px solid #D3D3D3" />
          <IconButton
            disabled={page >= numPages}
            onClick={() => handlePageChange(page + 1)}
          >
            <KeyboardArrowDownRounded sx={{ color: '#7E7E7E' }} />
          </IconButton>
          <Box
            marginLeft="2rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {!isScrolling ? (
              <Box
                marginLeft="2rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box position="relative">
                  <Input
                    maxLength={3}
                    defaultValue={page}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const value = (e.target as HTMLInputElement).value;
                        if (
                          value &&
                          parseInt(value) > 0 &&
                          parseInt(value) <= numPages
                        ) {
                          handlePageChange(parseInt(value));
                        } else {
                          (e.target as HTMLInputElement).value =
                            page.toString();
                        }
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, '');
                      if (
                        value === '' ||
                        (parseInt(value) > 0 && parseInt(value) <= numPages)
                      ) {
                        e.target.value = value;
                      }
                    }}
                    style={{ width: '4rem', marginRight: '0.5rem' }}
                    autoFocus
                  />
                </Box>
                <Typography fontSize="1rem">of {numPages}</Typography>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          marginLeft="auto"
          alignItems="right"
          justifyContent="right"
        >
          <IconButton
            disabled={zoom >= 1.5}
            onClick={() => {
              setZoom(zoom + 0.25);
            }}
          >
            <AddRounded sx={{ color: '#7E7E7E' }} />
          </IconButton>
          <Box height="2rem" border="1px solid #D3D3D3" />
          <IconButton
            disabled={zoom <= 0.5}
            onClick={() => {
              setZoom(zoom - 0.25);
            }}
          >
            <RemoveRounded sx={{ color: '#7E7E7E' }} />
          </IconButton>
          <Box
            marginLeft="2rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Select
              value={zoom}
              onChange={handleZoomChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{
                marginRight: '0.25rem',
                backgroundColor: 'white',
                borderRadius: '8px',
                height: '2.5rem', // Reduced height
                padding: '0 14px', // Adjust padding as needed
                lineHeight: '1.5', // Adjust line height for text alignment
              }}
            >
              <MenuItem value={0.5}>50%</MenuItem>
              <MenuItem value={0.75}>75%</MenuItem>
              <MenuItem value={1}>100%</MenuItem>
              <MenuItem value={1.25}>125%</MenuItem>
              <MenuItem value={1.5}>150%</MenuItem>
            </Select>
          </Box>
        </Box>
      </Box>

      <Box
        ref={scrollContainerRef}
        width="100%"
        flexWrap="wrap"
        overflow="auto"
        boxSizing="border-box"
        onScroll={handleScroll}
        // sx={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        border="1px solid #E5E7EB"
      >
        <Box
          display="flex"
          flexDirection="column"
          margin="0 auto 0 auto"
          width="fit-content"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: '#F6F6F6' }}
        >
          <Box position="relative" width={pageWidth * zoom}>
            <PdfDocument file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
              {/* Render all pages */}
              {Array.from(new Array(numPages), (el, index) => (
                <Box key={`page_${index + 1}`} marginBottom="1rem">
                  <Page
                    width={pageWidth * zoom}
                    pageNumber={index + 1}
                    onRenderSuccess={onRenderSuccess}
                  />
                  {/* Render annotations for the current page */}
                  {index + 1 === page &&
                    Object.keys(selectedError).length === 0 &&
                    pageErrors.map((error) =>
                      error.fields.map((field: any, fieldIndex: number) => (
                        <div
                          key={fieldIndex}
                          ref={getErrorAnnotationRef(error, page)}
                          style={{
                            position: 'absolute',
                            backgroundColor: 'red',
                            opacity: '0.3',
                            ...getXYWidthHeight(
                              field.vertices,
                              pageWidth * zoom,
                              pageWidth * zoom * pageRatio,
                              zoom,
                              page,
                              true
                            ),
                          }}
                        />
                      ))
                    )}
                  {index + 1 === page &&
                    Object.keys(selectedError).length > 0 &&
                    selectedError?.fields?.map(
                      (field: any, fieldIndex: number) => (
                        <div
                          key={fieldIndex}
                          ref={getErrorAnnotationRef(selectedError, page)}
                          style={{
                            position: 'absolute',
                            backgroundColor: 'red',
                            opacity: '0.3',
                            ...getXYWidthHeight(
                              field.vertices,
                              pageWidth * zoom,
                              pageWidth * zoom * pageRatio,
                              zoom,
                              page,
                              true
                            ),
                          }}
                        />
                      )
                    )}
                </Box>
              ))}
            </PdfDocument>
            {Object.keys(selectedError).length === 0 &&
              pageErrors.map((error) =>
                error.fields.map((field: any, index: number) => (
                  <div
                    key={index}
                    ref={getErrorAnnotationRef(error, page)}
                    style={{
                      position: 'absolute',
                      backgroundColor: 'red',
                      opacity: '0.3',
                      ...getXYWidthHeight(
                        field.vertices,
                        pageWidth * zoom,
                        pageWidth * zoom * pageRatio,
                        zoom,
                        page,
                        true
                      ),
                    }}
                  />
                ))
              )}
            {Object.keys(selectedError).length > 0 &&
              selectedError?.fields?.map((field: any, index: number) => (
                <div
                  key={index}
                  ref={getErrorAnnotationRef(selectedError, page)}
                  style={{
                    position: 'absolute',
                    backgroundColor: 'red',
                    opacity: '0.3',
                    ...getXYWidthHeight(
                      field.vertices,
                      pageWidth * zoom,
                      pageWidth * zoom * pageRatio,
                      zoom,
                      page,
                      true
                    ),
                  }}
                />
              ))}
            {/* THE TOOLTIP IS NOT VISIBLE */}
            {showAllDetections &&
              Object.keys(rawExtract).map((key: any, index: number) => (
                <Box key={index}>
                  {rawExtract[key][3] === page && (
                    <div
                      style={{
                        position: 'absolute',
                        backgroundColor: 'green',
                        opacity: '0.3',
                        ...getXYWidthHeight(
                          rawExtract[key][2],
                          pageWidth * zoom,
                          pageWidth * zoom * pageRatio,
                          zoom,
                          page
                        ),
                      }}
                    >
                      <Tooltip
                        title={rawExtract[key][0]}
                        placement="top"
                        key={index}
                        // sx={{ position: 'absolute' }}
                      >
                        <div
                          style={{
                            ...getXYWidthHeight(
                              rawExtract[key][2],
                              pageWidth * zoom,
                              pageWidth * zoom * pageRatio,
                              zoom,
                              page
                            ),
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const pdfViewAndAnnotationsComponentWithLoader = (
    <WaitAndLoadingWithSpinner
      loading={!pdfData}
      component={pdfViewAndAnnotationsComponent}
    />
  );

  const approveDocument = () => {
    // This request is just to pass on to the backend, what needs to be fixed and what can be ignored.
    // Backend will decide what to do

    const approvalConfirmation = Modal.confirm({
      title: 'Are you sure you want to approve this document?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          This will set all the issues found in the document to <b>IGNORE</b>{' '}
          and mark it as approved.
        </>
      ),
      okText: 'Approve',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk() {
        // Set all the errorActions to IGNORE
        const newErrorActions = errorActionsList.map((errorAction) => {
          return { action: ErrorActions.IGNORE };
        });
        console.log('New Error Actions: ', newErrorActions);

        // Send the approval request
        ComplianceService.approveDocument(
          transactionID,
          documentID,
          newErrorActions
        ).then(
          (response) => {
            console.log('Response from verifyTransactionDocument: ', response);
            message.success('Document verified successfully!');
            // Refresh the page
            getTransactionDocumentDetails();
          },
          (error) => {
            console.error('Error verifying document:', error);
            message.error('Error verifying document');
          }
        );
      },
      onCancel() {
        console.log('Cancel');
        approvalConfirmation.destroy();
      },
    });
  };

  const goToNextDocument = () => {
    console.log('Current index: ', currentDocumentIndex);
    if (nextDocument && nextDocument._id) {
      console.log('Next Document: ', nextDocument);
      const documentId = nextDocument._id;
      setPrevDocument(
        complianceTransaction?.documents
          ? complianceTransaction.documents[currentDocumentIndex]
          : ({} as DocumentInterface)
      );
      setNextDocument(
        complianceTransaction?.documents &&
          currentDocumentIndex + 2 < complianceTransaction.documents.length
          ? complianceTransaction.documents[currentDocumentIndex + 2]
          : ({} as DocumentInterface)
      );
      setCurrentDocumentIndex(currentDocumentIndex + 1);
      setActiveTab('all');
      setPage(1);

      // Also set up the nextDocument and prevDocument after navigating
      if (agentDashboard) {
        navigate(
          AppURLs.agentDashboardTransactionVerifyDocument(
            transactionID,
            documentId
          )
        );
      } else {
        navigate(
          AppURLs.complianceDashboardTransactionVerifyDocument(
            transactionID,
            documentId
          )
        );
      }
    }
  };

  const goToPrevDocument = () => {
    console.log('Current index: ', currentDocumentIndex);
    if (prevDocument && prevDocument._id) {
      console.log('Prev Document: ', prevDocument);
      const documentId = prevDocument._id;
      setNextDocument(
        complianceTransaction?.documents
          ? complianceTransaction.documents[currentDocumentIndex]
          : ({} as DocumentInterface)
      );
      setPrevDocument(
        complianceTransaction?.documents && currentDocumentIndex - 2 >= 0
          ? complianceTransaction.documents[currentDocumentIndex - 2]
          : ({} as DocumentInterface)
      );
      setCurrentDocumentIndex(currentDocumentIndex - 1);
      setActiveTab('all');
      setPage(1);

      // Also set up the nextDocument and prevDocument after navigating
      if (agentDashboard) {
        navigate(
          AppURLs.agentDashboardTransactionVerifyDocument(
            transactionID,
            documentId
          )
        );
      } else {
        navigate(
          AppURLs.complianceDashboardTransactionVerifyDocument(
            transactionID,
            documentId
          )
        );
      }
    }
  };

  const component = (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        padding={
          REACT_APP_AGENT_REDESIGN_VERIFY_DOCUMENT_VIEW_ENABLED ? '0' : '1rem'
        }
        width="100%"
        boxSizing="border-box"
      >
        {agentDashboard ? (
          <>
            <Header
              transaction={complianceTransaction}
              verifyDocumentView={true}
            />

            <Box className="flex items-center text-white bg-primary px-8 pb-4">
              <span className="text-sm font-light">Status:&nbsp;&nbsp;</span>
              <StatusChip
                status={complianceTransaction?.document?.reviewStatus}
                role={'agent'}
              />
              <span className="text-sm font-light ml-4">
                Last Updated:{' '}
                {complianceTransaction?.document?.updatedAt
                  ? new Date(
                      complianceTransaction.document.updatedAt
                    ).toLocaleString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })
                  : ''}
              </span>
              <Box display="flex" marginLeft="auto">
                <Box display="flex" alignItems="center">
                  <IconButton
                    title={
                      prevDocument && prevDocument._id
                        ? `Go to ${documentNameMap[prevDocument.type]}`
                        : ''
                    }
                    style={{
                      color:
                        prevDocument && prevDocument._id
                          ? '#2F78EB'
                          : '#9e9e9e',
                      backgroundColor: 'white',
                      marginRight: '0.25rem',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                    sx={{
                      '&:hover': {
                        transform:
                          prevDocument && prevDocument._id
                            ? 'scale(1.1)'
                            : 'none',
                      },
                    }}
                    onClick={() => goToPrevDocument()}
                    disabled={!prevDocument || !prevDocument._id}
                  >
                    <ArrowBackRounded />
                  </IconButton>
                  <IconButton
                    title={
                      nextDocument && nextDocument._id
                        ? `Go to ${documentNameMap[nextDocument.type]}`
                        : ''
                    }
                    style={{
                      color:
                        nextDocument && nextDocument._id
                          ? '#2F78EB'
                          : '#9e9e9e',
                      backgroundColor: 'white',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                    sx={{
                      '&:hover': {
                        transform:
                          nextDocument && nextDocument._id
                            ? 'scale(1.1)'
                            : 'none',
                      },
                    }}
                    onClick={() => goToNextDocument()}
                    disabled={!nextDocument || !nextDocument._id}
                  >
                    <ArrowForwardRounded />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box padding="1rem 1rem 0 1rem">
            <Box display="flex" alignItems="center">
              <Breadcrumb style={{ margin: '0.5rem 0' }}>
                <Breadcrumb.Item onClick={goToTransactions}>
                  <Typography style={{ cursor: 'pointer' }}>
                    Transactions
                  </Typography>
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={goToDocumentsList}>
                  <Typography style={{ cursor: 'pointer' }}>
                    {getCompliancePartnerID() === partners.KEYES &&
                      complianceTransaction?.externalSourceID &&
                      `${complianceTransaction.externalSourceID} - `}
                    {complianceTransaction.name}
                  </Typography>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Typography style={{ cursor: 'pointer' }}>
                    {documentNameMap[documentType]}
                  </Typography>
                </Breadcrumb.Item>
              </Breadcrumb>

              <Box
                display="flex"
                alignItems="center"
                marginLeft="auto"
                marginRight="1rem"
              >
                <Switch
                  checked={showAllDetections}
                  onChange={(checked) => setShowAllDetections(checked)}
                  style={{ marginRight: '0.25rem' }}
                />
                <Typography
                  fontSize="0.8rem"
                  fontWeight={500}
                  marginRight="1rem"
                >
                  All Detections
                </Typography>

                <Box display="flex" marginLeft="auto">
                  <Box display="flex" alignItems="center">
                    <IconButton
                      title={
                        prevDocument && prevDocument._id
                          ? `Go to ${documentNameMap[prevDocument.type]}`
                          : ''
                      }
                      style={{
                        color:
                          prevDocument && prevDocument._id
                            ? '#2F78EB'
                            : '#9e9e9e',
                        backgroundColor: 'white',
                        marginRight: '0.25rem',
                        transition: 'transform 0.2s ease-in-out',
                      }}
                      sx={{
                        '&:hover': {
                          transform:
                            prevDocument && prevDocument._id
                              ? 'scale(1.1)'
                              : 'none',
                        },
                      }}
                      onClick={() => goToPrevDocument()}
                      disabled={!prevDocument || !prevDocument._id}
                    >
                      <ArrowBackRounded />
                    </IconButton>
                    <IconButton
                      title={
                        nextDocument && nextDocument._id
                          ? `Go to ${documentNameMap[nextDocument.type]}`
                          : ''
                      }
                      style={{
                        color:
                          nextDocument && nextDocument._id
                            ? '#2F78EB'
                            : '#9e9e9e',
                        backgroundColor: 'white',
                        transition: 'transform 0.2s ease-in-out',
                      }}
                      sx={{
                        '&:hover': {
                          transform:
                            nextDocument && nextDocument._id
                              ? 'scale(1.1)'
                              : 'none',
                        },
                      }}
                      onClick={() => goToNextDocument()}
                      disabled={!nextDocument || !nextDocument._id}
                    >
                      <ArrowForwardRounded />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              display="flex"
              width="100%"
              padding="1rem"
              borderRadius="8px"
              sx={{ backgroundColor: 'white' }}
              margin="0.5rem 0"
              boxSizing="border-box"
            >
              <Box>
                <Typography fontSize="1.1rem" fontWeight={350} color="#7E7E7E">
                  {complianceTransaction?.name}
                </Typography>
                <Typography fontSize="1.5rem" fontWeight={600}>
                  {documentNameMap[documentType]}
                </Typography>
                <Typography
                  fontSize="0.9rem"
                  fontWeight={400}
                  color="#7E7E7E"
                  marginBottom="0.5rem"
                >
                  {complianceTransaction?.document?.title}
                </Typography>
                <StatusChip
                  status={complianceTransaction?.document?.reviewStatus || ''}
                  role={'compliance'}
                />
              </Box>
              <Box
                display="flex"
                marginLeft="auto"
                alignItems="center"
                justifyContent="center"
              >
                {!approvalDisabled() && (
                  <Button
                    shape="round"
                    type="primary"
                    style={{
                      padding: '0 1.5rem',
                      marginRight: '0.5rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => approveDocument()}
                  >
                    <CheckRounded sx={{ marginRight: '0.25rem' }} /> Approve
                  </Button>
                )}

                {!returnToAgentDisabled() && (
                  <Button
                    danger
                    shape="round"
                    type="primary"
                    style={{
                      padding: '0 1.5rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      errorsManager.clearErrorsForDocument(
                        transactionID,
                        documentType
                      );
                      let errorsToBePushed = errors.filter(
                        (error) =>
                          errorActionsList[errors.indexOf(error)].action ===
                          ErrorActions.FIX
                      );
                      errorsManager.addErrors(
                        transactionID,
                        documentType,
                        errorsToBePushed
                      );
                      console.log('Errors to be pushed: ', errorsToBePushed);
                      if (errorsToBePushed.length === 0) {
                        errorDocumentIdsAndActionListManager.removeData(
                          documentID
                        );
                      } else {
                        errorDocumentIdsAndActionListManager.addData(
                          documentID,
                          errorActionsList
                        );
                      }
                    }}
                  >
                    <ReplyAllRounded sx={{ marginRight: '0.5rem' }} /> Add
                    Errors to Stack
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          padding=" 0 1rem 1rem 1rem"
          boxSizing="border-box"
        >
          <Box
            ref={parentRef}
            display="flex"
            flexGrow={1}
            overflow="auto"
            width="100%"
            marginTop="1rem"
            boxSizing="border-box"
          >
            {PDFPagesCarousel}
            <Box
              display="flex"
              height="100%"
              width="100%"
              overflow="auto"
              boxSizing="border-box"
            >
              {pdfViewAndAnnotationsComponentWithLoader}
            </Box>
            {errors.length > 0 && (
              <Box
                width="32rem"
                overflow="auto"
                sx={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
              >
                <Tabs
                  value={activeTab}
                  onChange={(e, newValue) => setActiveTab(newValue)}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="inherit"
                  indicatorColor="secondary"
                  style={{ marginBottom: '1rem', fontSize: '0.8rem' }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor:
                        activeTab === 'required'
                          ? '#E01212'
                          : activeTab === 'optional'
                            ? '#AB6F00'
                            : '#1976d2',
                    },
                  }}
                >
                  {[
                    'all',
                    ...Array.from(new Set(errors.map((e) => e.severity))),
                  ].map((sev: string) => {
                    const count =
                      sev === 'all'
                        ? errors.length
                        : errors.filter((e) => e.severity === sev).length;
                    return (
                      <Tab
                        key={sev}
                        value={sev}
                        label={`${sev === 'all' ? 'All' : `${count} ${capitalizeFirstLetter(sev)}`}`}
                        sx={{
                          color:
                            sev === 'required'
                              ? '#E01212'
                              : sev === 'optional'
                                ? '#AB6F00'
                                : 'inherit',
                          textTransform: 'none',
                          '&.Mui-selected': {
                            color:
                              sev === 'required'
                                ? '#E01212'
                                : sev === 'optional'
                                  ? '#AB6F00'
                                  : '#1976d2',
                          },
                        }}
                      />
                    );
                  })}
                </Tabs>

                {(activeTab === 'all'
                  ? errors
                  : errors.filter((error) => error.severity === activeTab)
                ).map((error: ErrorField, index: number) => {
                  return (
                    <Fragment key={index}>
                      <ErrorCardComponent
                        index={index}
                        error={error}
                        onClick={() => {
                          if (error) {
                            setSelectedError(error);
                            if (
                              typeof error['pages'][0] === 'number' &&
                              error['pages'][0] > 0
                            ) {
                              setPage(error['pages'][0]);
                              scrollToErrorRef(error, error.pages[0]);
                            }
                          }
                        }}
                        selectedError={selectedError}
                        errorActionsList={errorActionsList}
                        setErrorActionsList={setErrorActionsList}
                        verifyDocumentView={
                          !agentDashboard ||
                          [partners.BAIRD_AND_WARNER, partners.KEYES].includes(
                            getCompliancePartnerID() || ''
                          )
                        } // Don't show the buttons on agent dashboard view
                      />
                    </Fragment>
                  );
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );

  return <WaitAndLoadingWithSpinner loading={!pdfData} component={component} />;
};
