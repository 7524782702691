import React, { useEffect, useState } from 'react';
import { Select, Input, Form } from 'antd';
import { OfficeService } from 'app/services/office'; 
import { UserService } from 'app/services/user';

interface UserFormProps {
  setFormData: React.Dispatch<
    React.SetStateAction<{
      name: string;
      email: string;
      officeID: string;
      rolesList: string[];
    }>
  >;
  partnerID: string;
  formData: any;
}

const UserForm: React.FC<UserFormProps> = ({
  setFormData,
  partnerID,
  formData,
}) => {
  const [offices, setOffices] = useState<{ _id: string; name: string }[]>([]);
  const [roles, setRoles] = useState<string[]>([]);

  const getOffices = async () => {
    try {
      const response: any = await OfficeService.getOffices('Compliance', '');
      setOffices(response.data.data);
    } catch (error) {
      console.error('Error fetching offices:', error);
    }
  };

  const getRoles = async () => {
    try {
      await UserService.getRoles(partnerID).then((response: any) => {
        setRoles(response.data.roles);
      });
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([getOffices(), getRoles()]);
      } catch (error: any) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOfficeChange = (value: string) => {
    setFormData((prev) => ({ ...prev, officeID: value }));
  };

  const shouldShowOfficeField = formData.rolesList.some((role: string) =>
    ['Agent', 'OfficeAdmin'].includes(role)
  );


  const handleRolesChange = (value: string[]) => {
    setFormData((prev) => ({ ...prev, rolesList: value }));
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Name" required>
        <Input
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter name"
          className="w-fill-available"
        />
      </Form.Item>
      <Form.Item label="Email" required>
        <Input
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Enter email"
          type="email"
          className="w-fill-available"
        />
      </Form.Item>
      <Form.Item label="Roles" required>
        <Select
          mode="multiple"
          placeholder="Select roles"
          options={roles.map((role: any) => ({
            value: role?.role,
            label: role?.roleName,
          }))}
          value={formData.rolesList}
          onChange={handleRolesChange}
        />
      </Form.Item>
      {shouldShowOfficeField && (
        <Form.Item label="Office" required>
          <Select
            placeholder="Select an office"
            options={offices.map((office) => ({
              value: office._id,
              label: office.name,
            }))}
            value={formData.officeID}
            onChange={handleOfficeChange}
            showSearch
            filterOption={(input, option) =>
              option
                ? option.label.toLowerCase().includes(input.toLowerCase())
                : false
            }
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default UserForm;
