export type severity =
  | 'complete'
  | 'error'
  | 'pending'
  | 'progress'
  | 'inactive'
  | 'utility'
  | 'ready';

type SeverityTheme = {
  bg: string;
  text?: string;
};

const severityThemeMap: Record<severity, SeverityTheme> = {
  inactive: { bg: 'bg-inactive', text: 'text-gray-800' },
  complete: { bg: 'bg-complete', text: 'text-green-800' },
  error: { bg: 'bg-error', text: 'text-red-800' },
  pending: { bg: 'bg-pending', text: 'text-yellow-800' },
  progress: { bg: 'bg-progress', text: 'text-blue-800' },
  utility: { bg: 'bg-utility', text: 'text-green-800' },
  ready: { bg: 'bg-ready', text: 'text-blue-800' },
} as const;

export const getSeverityClasses = (
  type: severity,
  includeText: boolean = true,
  includeBg: boolean = true
): string => {
  const theme = severityThemeMap[type];
  return [includeBg && theme.bg, includeText && theme.text]
    .filter(Boolean)
    .join(' ');
};
