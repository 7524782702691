import { Button, Modal, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { getDateAndTimeString, getShortDateString } from 'app/utils/datetime';

import { ComplianceService } from 'app/services/compliance';
import { EmailService } from 'app/services/email';
import IssueListModal from './IssueListModal';
import { LoadingOutlined } from '@ant-design/icons';
import { RefreshOutlined } from '@mui/icons-material';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import alert from './assets/AlertTriangle.svg';
import correct from './assets/Check.svg';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getCustomIdFromUrl } from 'app/utils/url';
import home from './assets/home.svg';
import { sendEmail } from 'app/utils/email';
import { CHECK_STATUSES } from '../Checks/types';
import { Autocomplete, TextField } from '@mui/material';
import {
  TRANSACTION_TYPE_MAP,
  TRANSACTION_TYPE_OPTIONS,
} from 'app/pages/Compliance/Dashboard/pages/utils/transactionTypeMap';
import {
  getCompliancePartnerID,
  isOfficeAdmin,
} from 'app/utils/localStorageHandler/userProfile';
import { partners } from 'app/configs/partner';
import Remarks from 'app/modules/remarks';
import { useFlags } from 'flagsmith/react';

const checklistItems = (check: any) => [
  {
    label: `Check submitted by ${check?.agentName}`,
    date: getDateAndTimeString(check?.checkList?.uploadedToMaxhomeAt)?.date,
    time: getDateAndTimeString(check?.checkList?.uploadedToMaxhomeAt)?.time,
    completed: true,
  },
  {
    label: 'Check added to the loop by Max',
    date: getDateAndTimeString(check?.checkList?.uploadedToDotloopAt)?.date,
    time: getDateAndTimeString(check?.checkList?.uploadedToDotloopAt)?.time,
    completed: check?.checkList?.uploadedToDotloopAt,
  },
  {
    label: 'Validation completed by Max',
    date: getDateAndTimeString(check?.checkList?.validationCompletedAt)?.date,
    time: getDateAndTimeString(check?.checkList?.validationCompletedAt)?.time,
    completed: check?.checkList?.validationCompletedAt,
  },
  {
    label: 'Prism updated by Max',
    date: getDateAndTimeString(check?.checkList?.prismUpdatedAt)?.date,
    time: getDateAndTimeString(check?.checkList?.prismUpdatedAt)?.time,
    completed: check?.checkList?.prismUpdatedAt,
  },
];

const headerTextOptions = {
  [CHECK_STATUSES.PROCESSING]: 'Under Review',
  [CHECK_STATUSES.NOT_STARTED]: 'Under Review',
  [CHECK_STATUSES.LOOP_NOT_FOUND]: 'Loop Not Found',
  [CHECK_STATUSES.DOCS_MISSING]: 'Documents Missing',
  [CHECK_STATUSES.VALIDATION_FAILED]: 'Validation Failed',
  [CHECK_STATUSES.PRISM_NOT_FOUND]: 'Prism Not Found',
  [CHECK_STATUSES.COMPLETED]: 'Max Approved',
};

const CheckValidationView: React.FC = () => {
  const [checkDetails, setCheckDetails] = useState<any>(null);
  const [transactionDetails, setTransactionDetails] = useState<any>(null);
  const [notes, setNotes] = useState('');
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [modalPdfUrl, setModalPdfUrl] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loadingStates, setLoadingStates] = useState({
    page: true,
    transaction: false,
    button: false,
  });
  const [loadingDocuments, setLoadingDocuments] = useState<
    Record<string, boolean>
  >({});

  const isAdmin = localStorage.getItem('Admin');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [resetKey, setResetKey] = useState(0);
  const [transactionType, setTransactionType] = useState<any>(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const remarksFlags = useFlags(['show_remarks']);
  const isRemarksEnabled = remarksFlags?.show_remarks?.enabled;

  const headerStatus = checkDetails?.processingStatus
    ?.status as keyof typeof headerTextOptions;

  const dropDownData: any[][] = [
    [
      {
        label: 'Branch Office',
        value: checkDetails?.transactionOffice?.name,
      },
      { label: 'Receiving Office', value: checkDetails?.receivingOffice?.name },
      getCompliancePartnerID() !== partners.JMG && {
        label: 'MLS Status',
        value: loadingStates.transaction
          ? 'Loading...'
          : transactionDetails?.mlsDocument?.mlsStatus ||
            'No active listing in last 6 months',
        buttonOnLabel: true,
        buttonOnLabelIcon: loadingStates.transaction ? (
          <LoadingOutlined spin />
        ) : (
          <RefreshOutlined style={{ fontSize: 'large' }} />
        ),
        onButtonOnLabelClick: () => {
          if (!loadingStates.transaction) {
            fetchTransactionDetails(checkDetails?.transactionId);
          }
        },
      },
    ],
    [
      { label: 'Agent Name', value: checkDetails?.agentName },
      {
        label: 'Dotloop Link',
        text: checkDetails?.externalSourceID
          ? 'https://www.dotloop.com'
          : 'N/A',
        value: checkDetails?.externalSourceID
          ? `https://www.dotloop.com/m/loop?viewId=${checkDetails?.externalSourceID}`
          : 'N/A',
        isLink: !!checkDetails?.externalSourceID,
      },
    ],
  ];

  const setLoadingState = (key: keyof typeof loadingStates, value: boolean) => {
    setLoadingStates((prev) => ({ ...prev, [key]: value }));
  };

  const fetchTransactionDocument = async (
    docId: string,
    transactionId: string
  ) => {
    try {
      const response = await ComplianceService.getDocumentByID(
        transactionId,
        docId
      );
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      return URL.createObjectURL(pdfBlob);
    } catch {
      return 'No Document found';
    }
  };

  const fetchTransactionDetails = async (transactionId: string) => {
    try {
      setLoadingState('transaction', true);
      const response =
        await ComplianceService.getUpdatedMlsStatus(transactionId);
      setTransactionDetails(response.data);
      setTransactionType(response.data?.type || null);
      return response.data;
    } catch (error) {
      console.error('Error fetching transaction details:', error);
      return null;
    } finally {
      setLoadingState('transaction', false);
    }
  };

  const handleViewImage = async (docId: string) => {
    setLoadingDocuments((prev) => ({ ...prev, [docId]: true }));
    const doc = await fetchTransactionDocument(
      docId,
      checkDetails.transactionId
    );
    setModalPdfUrl(doc);
    setIsModalVisible(true);
    setLoadingDocuments((prev) => ({ ...prev, [docId]: false }));
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    if (modalPdfUrl) URL.revokeObjectURL(modalPdfUrl);
  };

  const dropDownCell = () =>
    dropDownData.map((row, rowIndex) => (
      <div
        key={rowIndex}
        className={`flex p-4 gap-16 ${rowIndex === 0 ? 'border-b border-[#dfe3eb]' : ''} ${rowIndex === 1 ? 'pb-4' : ''}`}
      >
        {row.map((item, index) => (
          <div key={index} className="flex flex-col gap-1">
            <div className="flex items-center">
              <span className="text-[#2b3340] text-sm font-medium">
                {item.label}
              </span>
              {item?.buttonOnLabel && (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    item.onButtonOnLabelClick && item.onButtonOnLabelClick();
                  }}
                  className="ml-2 cursor-pointer flex items-center"
                >
                  {item.buttonOnLabelIcon}
                </div>
              )}
            </div>
            {item.isLink ? (
              <a
                href={item.value}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue text-sm font-medium"
              >
                {item.text}
              </a>
            ) : (
              <span className="text-[#57677e] text-sm font-medium">
                {item.value}
              </span>
            )}
          </div>
        ))}
      </div>
    ));

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleReadyToSubmitChange = async (readyToDeposit: boolean) => {
    const updateStatus = async (sendEmail: boolean = true) => {
      try {
        setCheckDetails((prevState: any) => ({
          ...prevState,
          readyToDeposit,
        }));
        await ComplianceService.patchReadyToDeposit(
          checkDetails?._id,
          readyToDeposit,
          sendEmail
        );
        message.success('Ready to submit status updated successfully!');
      } catch (error: any) {
        setCheckDetails((prevState: any) => ({
          ...prevState,
          readyToDeposit: !readyToDeposit,
        }));
        message.error(
          error?.response?.data?.message ||
            'Error updating ready to submit status'
        );
      }
    };

    const showConfirmModal = (
      title: string,
      content: string,
      showStatusOnlyButton: boolean = true,
      showSendEmailButton: boolean = true
    ) => {
      Modal.confirm({
        title,
        content,
        okText: showStatusOnlyButton ? 'Send Email' : 'Yes',
        cancelText: 'Cancel',
        width: 500,
        okButtonProps: {
          style: { backgroundColor: '#1890ff', borderColor: '#1890ff' },
        },
        footer: (_, { OkBtn, CancelBtn }) => (
          <div className="flex justify-end gap-4">
            <CancelBtn />
            {showStatusOnlyButton && (
              <Button
                onClick={() => {
                  updateStatus(false);
                  Modal.destroyAll();
                }}
              >
                Change Status only
              </Button>
            )}
            {showSendEmailButton && <OkBtn />}
          </div>
        ),
        onOk: () => updateStatus(true),
      });
    };

    if (
      readyToDeposit &&
      ['IL_BW_EMR', 'IL_BW_EMR_A', 'IL_BW_CC'].includes(checkDetails?.type)
    ) {
      try {
        const response = await EmailService.getEmailLogsByCheckType(
          checkDetails?.transactionId,
          checkDetails?.type
        );

        const emailAlreadySent = response.data.success;
        const isILBWCC = checkDetails?.type === 'IL_BW_CC';
        const requiresModal =
          isILBWCC && !['LISTING', 'PURCHASE'].includes(transactionType);

        if (requiresModal) {
          setIsModalOpen(true);
          return;
        }

        if (
          isILBWCC &&
          TRANSACTION_TYPE_OPTIONS(transactionType) ===
            TRANSACTION_TYPE_MAP.LISTING
        ) {
          showConfirmModal(
            'No Email Sent',
            'For a list side commission check, no email will be sent. Would you like to proceed with changing the status?',
            true,
            false
          );
          return;
        }

        if (emailAlreadySent) {
          showConfirmModal(
            'Email Already Sent',
            `An email confirmation has already been sent for ${documentNameMap[checkDetails?.type]} to the agent. Would you like to resend the email?`,
            true
          );
        } else {
          showConfirmModal(
            'Confirm Check Submission',
            'This will mark the check as ready to submit and trigger a confirmation email to the agent.'
          );
        }
      } catch (error) {
        console.error('Error checking email logs:', error);
      }
    } else {
      await updateStatus();
    }
  };

  const handleChangeTransactionType = async (type: any) => {
    try {
      setButtonLoading(true);
      await ComplianceService.updateTransactionType(
        transactionDetails?._id,
        type
      );
      message.success('Transaction type updated successfully!');
    } catch (error) {
      setButtonLoading(false);
      setIsModalOpen(false);
      message.error('Error updating transaction type');
    } finally {
      await fetchTransactionDetails(checkDetails?.transactionId);
      setButtonLoading(false);
      setIsModalOpen(false);
      setResetKey((prevState) => prevState + 1);
    }
  };
  const handleSaveNote = async () => {
    try {
      setLoadingState('button', true);
      await ComplianceService.patchCheck(
        checkDetails?._id,
        checkDetails?.readyToDeposit,
        notes,
        checkDetails?.processingStatus?.status
      );
      message.success('Notes saved successfully!');
    } catch (error) {
      message.error('Error saving notes');
    } finally {
      setLoadingState('button', false);
    }
  };

  const openSendEmail = async () => {
    const missingDocs = checkDetails?.processingStatus?.missingDocs;

    const formsMissingText = missingDocs
      ?.map((doc: any) => documentNameMap[doc])
      .join(', ');
    const statusTextMap = {
      [CHECK_STATUSES.LOOP_NOT_FOUND]: 'Loop missing',
      [CHECK_STATUSES.DOCS_MISSING]: `${formsMissingText} missing`,
      [CHECK_STATUSES.VALIDATION_FAILED]: 'Validation issues',
      [CHECK_STATUSES.PRISM_NOT_FOUND]: 'Prism entry missing',
      [CHECK_STATUSES.COMPLETED]: 'received and uploaded to Dotloop',
    };
    const status = checkDetails?.processingStatus
      ?.status as keyof typeof statusTextMap;
    const headerStatus = statusTextMap[status];
    const recipient = window.location.href.includes('test.maxhome.ai')
      ? 'support@maxhome.ai'
      : checkDetails?.agentEmail || '';
    const recepientName = checkDetails?.agentName.split(' ')[0] || '';
    const address = checkDetails?.address;
    const modifiedCheckAddress =
      [address?.unitNumber, address?.streetAddress]
        .filter(Boolean)
        .join(', ') || checkDetails?.transactionName;
    const documentName = documentNameMap[checkDetails?.type];
    const subject = `${modifiedCheckAddress} | ${documentName} - ${headerStatus}`;
    const errorsList =
      checkDetails?.checkErrors?.errorFields?.checkErrors || [];
    const checkType = documentNameMap[checkDetails?.type];

    let body = `Hello ${recepientName},\n\n`;
    switch (status) {
      case CHECK_STATUSES.LOOP_NOT_FOUND:
        body += `We have received ${checkType} for the property at ${modifiedCheckAddress}. Please create a loop and submit for review your contract so we can process your sale.\n\nThank you!`;
        break;

      case CHECK_STATUSES.DOCS_MISSING:
        body += `We have received ${checkType} for the property at ${modifiedCheckAddress}. Please complete and submit for review your ${formsMissingText} so we can process your sale.\n\nThank you!`;
        body += checkDetails?.externalSourceId
          ? `\n Dotloop link: https://www.dotloop.com/m/loop?viewId=${checkDetails?.externalSourceID}`
          : '';
        break;

      case CHECK_STATUSES.VALIDATION_FAILED:
        body += `We have received ${checkType} for the property at ${modifiedCheckAddress}. The document has been uploaded to the loop, but there are issues with validation.\n\nPlease review the following:\n\n`;
        if (errorsList?.required?.length > 0) {
          body += `Critical errors:\n`;
          for (let i = 0; i < errorsList.required.length; i++) {
            body += `• ${errorsList.required[i]}\n`;
          }
        }
        if (errorsList?.optional?.length > 0) {
          body += `Non-critical errors:\n`;
          for (let i = 0; i < errorsList.optional.length; i++) {
            body += `• ${errorsList.optional[i]}\n`;
          }
        }
        body += checkDetails?.externalSourceId
          ? `\n Dotloop link: https://www.dotloop.com/m/loop?viewId=${checkDetails?.externalSourceID}`
          : '';
        break;

      case CHECK_STATUSES.PRISM_NOT_FOUND:
        body += `We have received ${checkType} for the property at ${modifiedCheckAddress}. Your receipt has been uploaded to the loop. Please create a transaction in Prism for us to process the funds. Please find a link to Prism here. For any questions please reach out to your office staff.
\n\nhttps://baird.appiancloud.com/suite/sites/Prism\n`;
        break;

      case CHECK_STATUSES.COMPLETED:
        if (checkType === documentNameMap['IL_BW_CC']) {
          body += `Congrats on your closing at ${modifiedCheckAddress}! Copies of your commission statement and closing documents have been added to your Loop. Closing will process your commission.
Thank you!`;
        } else {
          body += `We have received ${checkType} for the property at ${modifiedCheckAddress}. A copy has been added to your loop and closing will process the funds.
Thank you!`;
        }
        body += checkDetails?.externalSourceId
          ? `\n Dotloop link: https://www.dotloop.com/m/loop?viewId=${checkDetails?.externalSourceID}`
          : '';
        break;

      default:
        body += `Please review the status of your transaction at ${modifiedCheckAddress} and ensure all necessary documentation is submitted.\n\nThank you!`;
        break;
    }

    if (
      checkType === documentNameMap['IL_BW_CC'] &&
      transactionDetails?.type === TRANSACTION_TYPE_MAP.LISTING
    ) {
      const MlsStatus = transactionDetails?.mlsDocument
        ? transactionDetails.mlsDocument.mlsData?.StandardStatus
        : null;

      body += `\n\n${MlsStatus === 'Closed' || MlsStatus !== null ? `Please don't forget to close your listing in the MLS, you must do this within 48 hours of your closing in order to avoid a fine.` : ''}`;
    }

    body += `\n\n\Baird & Warner Central Support Services\nDirect Line: 6307244727\nEmail: centralsupportservices@bairdwarner.com`;

    sendEmail(
      recipient,
      subject,
      body,
      window.location.href.includes('test.maxhome.ai')
        ? 'helpdesk@maxhome.ai'
        : 'centralsupportservices@bairdwarner.com'
    );
  };

  const handleSaveIssues = (updatedIssues: {
    checkErrors: { required: string[]; optional: string[] };
    checkErrorsFormatted: { required: string[]; optional: string[] };
  }) => {
    setCheckDetails((prevState: any) => {
      const newState = {
        ...prevState,
        checkErrors: {
          ...prevState.checkErrors,
          errorFields: {
            ...prevState.checkErrors.errorFields,
            checkErrors: updatedIssues.checkErrors,
            checkErrorsFormatted: updatedIssues.checkErrorsFormatted,
          },
        },
      };
      return newState;
    });
  };

  const formColumns = [
    {
      title: 'Name',
      key: 'name',
      width: '50%',
      render: (_: any, record: any) =>
        documentNameMap[record.type] || record.type,
    },
    {
      title: 'Upload Date',
      key: 'date',
      width: '20%',
      render: (_: any, record: any) => getShortDateString(record.createdAt),
    },
    {
      title: 'Action',
      key: 'action',
      width: '30%',
      render: (_: any, record: any) => (
        <Button
          type="primary"
          onClick={() => handleViewImage(record._id)}
          loading={loadingDocuments[record._id]}
        >
          View Document
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const checkId = getCustomIdFromUrl('checkID');
    if (checkId) {
      ComplianceService.getCheckByID(checkId).then((response) => {
        const data = response?.data;
        setCheckDetails(data);
        setNotes(data?.remarks || '');
        setLoadingState('page', false);

        // TODO: Check if it is transactionId or transactionID. Ideally `ID` should be in caps
        if (data?.document?._id) {
          fetchTransactionDocument(data.document._id, data.transactionId).then(
            setPdfUrl
          );
        }
        fetchTransactionDetails(data.transactionId);
      });
    }
  }, []);
  const handleSendStatusEmail = async () => {
    try {
      const status = checkDetails?.processingStatus?.status;
      const emailType = `CHECK_VALIDATION_STATUS_${status}`;

      const response = await EmailService.getEmailLogs(
        checkDetails?._id,
        'Check',
        [emailType],
        {}
      );

      const emailAlreadySent =
        response.data.emailLogs?.data.length > 0 || false;

      const sendEmail = async () => {
        try {
          setEmailLoading(true);
          await ComplianceService.sendPrismNotFoundEmail(checkDetails?._id); // TODO: change to sendStatusEmail once backend is ready
          message.success('Email sent successfully!');
        } catch (error: any) {
          message.error(
            error?.response?.data?.message || 'Error sending status email'
          );
        } finally {
          setEmailLoading(false);
        }
      };

      if (emailAlreadySent) {
        Modal.confirm({
          title: 'Email Already Sent',
          content:
            'An email notification has already been sent to the agent for this check. Would you like to resend the email?',
          okText: 'Resend Email',
          cancelText: 'Cancel',
          onOk: () => sendEmail(),
        });
      } else {
        Modal.confirm({
          title: 'Send Auto Email',
          content: 'This will send a notification email to the agent.',
          okText: 'Send',
          cancelText: 'Cancel',
          onOk: () => sendEmail(),
        });
      }
    } catch (error) {
      console.error('Error checking email logs:', error);
      message.error('Error checking email status');
    }
  };

  const component = (
    <>
      <div className="text-base font-medium bg-[#f9fafc] rounded-lg p-4 border-2 border-[#e0e0e0]">
        <div className="text-xl font-semibold pb-4 border-b-2 border-[#e0e0e0]">
          Overview
        </div>
        <div className="rounded-xl mt-4 mb-4 shadow-[0_2px_10px_rgba(0,0,0,0.1)]  border border-[#e3e8ee]">
          <div
            className={`flex items-center h-11 rounded-t-xl ${
              checkDetails?.processingStatus?.status !==
              CHECK_STATUSES.COMPLETED
                ? 'bg-[#ffb800] text-[#251A00]'
                : 'bg-[#29845A] text-[#F8FFFB]'
            }`}
          >
            <img
              src={
                checkDetails?.processingStatus?.status !==
                CHECK_STATUSES.COMPLETED
                  ? alert
                  : correct
              }
              alt="Alert Icon"
              className="pl-4 flex flex-col gap-2 text-sm mr-2"
            />
            {checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
              ?.required?.length ||
            checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
              ?.optional?.length
              ? `${
                  (checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
                    ?.required?.length || 0) +
                  (checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
                    ?.optional?.length || 0)
                } issues found |`
              : checkDetails?.processingStatus.status ===
                    CHECK_STATUSES.DOCS_MISSING ||
                  checkDetails?.processingStatus.status ===
                    CHECK_STATUSES.LOOP_NOT_FOUND ||
                  checkDetails?.processingStatus.status ===
                    CHECK_STATUSES.PRISM_NOT_FOUND
                ? ''
                : 'No issues found |'}
            <>
              <span className="ml-2">{headerTextOptions[headerStatus]}</span>
              {headerStatus === CHECK_STATUSES.DOCS_MISSING ? (
                <span className="ml-2">
                  {' : '}
                  {checkDetails?.processingStatus?.missingDocs &&
                    checkDetails?.processingStatus?.missingDocs
                      ?.map((doc: any) => documentNameMap[doc] || '')
                      .join(', ')}
                </span>
              ) : null}
            </>
          </div>
          <ul className="pl-8 text-sm flex flex-col gap-2">
            {/* Check if there are required errors and render them */}
            {checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
              ?.required?.length > 0 && (
              <>
                {/* Required Tag */}
                <div className="font-bold pl-2 border-l-4 border-red-500">
                  Critical errors :
                </div>
                {/* Required Errors List */}
                {checkDetails.checkErrors.errorFields.checkErrorsFormatted.required.map(
                  (error: any, index: number) => (
                    <li
                      className="ml-2"
                      key={`required-${index}`}
                      dangerouslySetInnerHTML={{
                        __html: error,
                      }}
                    />
                  )
                )}
              </>
            )}

            {/* Check if there are optional errors and render them */}
            {checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
              ?.optional?.length > 0 && (
              <>
                <div className="font-bold pl-2 border-l-4 border-[#a4a449]">
                  Non-critical errors :
                </div>
                {/* Optional Errors List */}
                {checkDetails.checkErrors.errorFields.checkErrorsFormatted.optional.map(
                  (error: any, index: number) => (
                    <li
                      className="ml-2"
                      key={`optional-${index}`}
                      dangerouslySetInnerHTML={{
                        __html: error,
                      }}
                    />
                  )
                )}
              </>
            )}

            {/* Show check completion message when no errors */}
            {!checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
              ?.required?.length &&
              !checkDetails?.checkErrors?.errorFields?.checkErrorsFormatted
                ?.optional?.length &&
              checkDetails?.processingStatus.status ===
                CHECK_STATUSES.COMPLETED && (
                <li>Check is ready for submission.</li>
              )}
          </ul>

          {!isOfficeAdmin() ? (
            <div className="flex gap-6 pl-4 pb-4">
              <Button
                type="primary"
                onClick={openSendEmail}
                disabled={loadingStates.transaction}
              >
                Send Email
              </Button>{' '}
              {isAdmin &&
                ['PRISM_NOT_FOUND'].includes(
                  checkDetails?.processingStatus?.status
                ) && (
                  <Button
                    type="primary"
                    onClick={handleSendStatusEmail}
                    loading={emailLoading}
                    className="ml-2"
                  >
                    Send {headerTextOptions[headerStatus]} Email
                  </Button>
                )}
              {isAdmin && !window.location.href.includes('test.maxhome.ai') ? (
                <IssueListModal
                  checkErrors={checkDetails?.checkErrors}
                  checkId={checkDetails?._id}
                  onSave={handleSaveIssues}
                />
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="p-4 rounded-xl mb-4 shadow-[0_2px_10px_rgba(0,0,0,0.1)] border border-[#e3e8ee]">
          <div className="font-bold text-lg pb-4 border-b border-[#dfe3eb] flex items-center">
            <img src={home} alt="Home Icon" className="homeIcon mr-2" />
            {checkDetails?.transactionName || 'N/A'}
          </div>
          {dropDownCell()}
        </div>
        <div className="p-4 rounded-xl mb-4 shadow-[0_2px_10px_rgba(0,0,0,0.1)] border border-[#e3e8ee]">
          <div className="flex flex-col mb-5">
            <span className="text-base">Bank check</span>
            {pdfUrl && pdfUrl !== 'No Document found' && (
              <span className="text-sm font-normal">
                {`Sent ${getShortDateString(checkDetails?.createdAt)}` || 'N/A'}
              </span>
            )}
            <div className="mt-2">
              {pdfUrl ? (
                pdfUrl !== 'No Document found' ? (
                  <embed
                    src={`${pdfUrl}`}
                    type="application/pdf"
                    width="100%"
                    height="600px"
                  />
                ) : (
                  <p>{pdfUrl}</p>
                )
              ) : (
                <p>Loading PDF...</p>
              )}
            </div>
          </div>
          <div className="py-5 border-t border-[#e0e0e0] border-b">
            <label className="text-[#2b3340]">Ready to submit? (Y/N)</label>
            <div className="flex gap-4 mt-2 items-center">
              <label className="flex items-center gap-2 text-sm text-[#2b3340]">
                <input
                  type="radio"
                  name="readyToSubmit"
                  value="yes"
                  checked={checkDetails?.readyToDeposit === true}
                  onChange={() => handleReadyToSubmitChange(true)}
                  className="m-0"
                  disabled={isOfficeAdmin()}
                />
                Yes
              </label>
              <label className="flex items-center gap-2 text-sm text-[#2b3340]">
                <input
                  type="radio"
                  name="readyToSubmit"
                  value="no"
                  checked={checkDetails?.readyToDeposit === false}
                  onChange={() => handleReadyToSubmitChange(false)}
                  className="m-0"
                  disabled={isOfficeAdmin()}
                />
                No
              </label>
            </div>
          </div>
          <div className="py-5 border-t border-[#e0e0e0] border-b">
            <label className="text-base">Remarks</label>
            <textarea
              className="w-fill-available my-2 h-24 border border-[#e0e0e0] rounded-md p-2 text-sm"
              placeholder="Add notes here ..."
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              disabled={isOfficeAdmin()}
            ></textarea>
            <Button
              type="primary"
              onClick={handleSaveNote}
              loading={loadingStates.button}
              disabled={isOfficeAdmin()}
            >
              {' '}
              Save note
            </Button>
          </div>
          <div className="py-5 border-t border-[#e0e0e0] border-b">
            <span className="pb-4 text-lg">Forms & contract</span>
            <Table
              dataSource={checkDetails?.transactionDocuments || []}
              columns={formColumns}
              rowKey="_id"
              pagination={false}
              locale={{ emptyText: 'No forms or contracts uploaded' }}
            />
          </div>
          <div className="pt-5">
            <div className="mb-4">Checklist</div>
            <ul className="list-none p-0 m-0">
              {checklistItems(checkDetails).map((item, index) => (
                <li
                  key={index}
                  className={`
          relative flex items-start pb-6 
          ${index !== checklistItems(checkDetails).length - 1 ? 'before:content-[""] before:absolute before:left-3 before:top-0 before:-bottom-6 before:w-0.5 before:bg-blue-600 before:z-0' : ''}
        `}
                >
                  <div
                    className={`
          w-6 h-6 border-2 border-blue-600 rounded-full 
          flex items-center justify-center mr-4 
          relative bg-white
          ${item.completed ? 'text-blue-600 text-lg' : ''}
        `}
                  >
                    {item.completed && <span>✓</span>}
                  </div>
                  <div className="flex flex-col">
                    <span className="font-medium text-gray-800">
                      {item.label}
                    </span>
                    {item.completed && (
                      <span className="text-sm text-gray-600 font-normal mt-1">
                        Dated: {item.time} • {item.date}
                      </span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Modal
        title={
          <div className="text-sm font-medium mb-2">
            Please select Transaction Type
          </div>
        }
        open={isModalOpen}
        onCancel={handleCloseModal}
        onOk={async () => {
          await handleChangeTransactionType(transactionType);
          await handleReadyToSubmitChange(true);
        }}
        cancelText="Cancel"
        okText={buttonLoading ? 'Submitting...' : 'Submit'}
        okButtonProps={{ loading: buttonLoading }}
      >
        <Autocomplete
          key={`transaction-type-${resetKey}`}
          options={Object.entries(TRANSACTION_TYPE_MAP).map(
            ([value, label]) => ({
              label,
              value,
            })
          )}
          getOptionLabel={(option) => option.label}
          className="mt-4"
          onInputChange={(event, value: any) =>
            setTransactionType(value?.value)
          }
          value={transactionType || transactionDetails?.transactionType}
          onChange={(event, value) => setTransactionType(value?.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Select transaction type"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </Modal>
      <Modal
        title="Document Preview"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
        width="80%"
      >
        {modalPdfUrl ? (
          <embed
            src={`${modalPdfUrl}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        ) : (
          <p>No document found</p>
        )}
      </Modal>
      {isRemarksEnabled ? (
        <Remarks
          entityId={checkDetails?._id}
          entityType="Check"
          position="bottom-right"
        />
      ) : null}
    </>
  );

  return (
    <WaitAndLoadingWithSpinner
      loading={loadingStates.page}
      component={component}
    />
  );
};

export default CheckValidationView;
