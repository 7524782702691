const documentNameMap: Record<string, string> = {
  // IL_MAIN: 'Multi-Board Residential Real Estate Contract',
  IL_MAIN: 'Purchase Contract',
  IL_BW_BAA: 'Exclusive Buyer/Tenant Representation Agreement',
  IL_BW_SDF: 'Sales Data Form',
  IL_BW_RTSMA: 'Exclusive Right To Sell Marketing Agreement',
  IL_BW_MOD: 'Dual Multiple Offers Disclosure',
  CA_MAIN: 'California Main Document', // TODO: Get the actual name
  IL_BW_EM_CHECK: 'Earnest Money Receipt',
  IL_BW_COMM_CHECK: 'Commission Check',
  IL_BW_AFFL: 'Disclosure Statement of Affiliated Business Arrangement',
  IL_BW_RRPDR: 'Residential Real Property Disclosure Report',
  IL_BW_PNA: 'Private Network Addendum',
  IL_BW_LEA: 'MRED MLS Listing Exemption Authorization',
  IL_BW_DIRH:
    'Disclosure of Information on Radon Hazards For Residential Real Property Sales or Purchases',
  IL_BW_DIAL:
    'Disclosure of Information and Acknowledgement Lead-Based Paint and or Lead-Based Paint Hazards',
  IL_BW_AEMA: 'Addendum to Exclusive Marketing Agreement',
  IL_BW_CC: 'Commission Check',
  IL_BW_DSABA: 'Disclosure Statement of Affiliated Business Arrangement',
  IL_BW_EMR: 'Earnest Money',
  IL_BW_EMR_A: 'Additional Earnest Money',
  IL_BW_HUD: 'HUD Statement',
  IL_BW_CS_B: 'Commission Statement',
  IL_BW_CS_T: 'Commission Statement',
  IL_BW_EMP: 'Earnestly Deposit',
  IL_BW_BSC: 'Buyer Compensation Statement',
  IL_BW_EBRA: 'Exclusive Buyer Representation Agreement',
  IL_BW_AFDC: 'Anti Fraud Disclosure to Consumers',

  FL_MAIN: 'Florida Residential Contract',
  FL_KE_WFPN: 'Wire Fraud Prevention Notice',
  FL_KE_HUD: 'HUD Statement',
  FL_KE_TRP: 'Tax Roll Print Out',
  FL_KE_ABADS: 'Affiliated Business Arrangement Disclosure',
  FL_KE_CADR: 'Condo Rider',
  FL_KE_HADR: 'Homeowner Association Rider',
  FL_KE_FVR: 'FHA/VA Rider',
  FL_KE_ACR: 'Appraisal Contingency Rider',
  FL_KE_HOPR: 'Housing for Older Person Rider',
  FL_KE_SFR: 'Seller Financing Rider',
  FL_KE_LPDR: 'Lead Based Paint Rider',
  FL_KE_SBPR: 'Buyer Property Rider',
  FL_KE_KOR: 'Kick Out Rider',
  FL_KE_MLS: 'MLS Sheet',
  FL_KE_ELA: 'Exclusive Listing Agreement',
  FL_KE_AKD: 'Additional Keyes Disclosure',
  FL_KE_FD: 'Flood Disclosure',
  FL_KE_BRED: 'Buyer Real Estate Disclosure',
  FL_KE_CA: 'Compensation Agreement',
  FL_KE_EL: 'Escrow Letter',
  FL_KE_SDRC: 'Sellers Disclosure - Residential or Condo',
  FL_KE_BEL: 'Buyer Engagement Letter',
  FL_KE_ROD: 'Release of Deposit',
  FL_KE_EFT: 'Electronic Fund Transfer',
  FL_KE_ERTL: 'Exclusive Right to Lease',
  FL_KE_CTL: 'Contract to Lease',
  FL_KE_TDTB: 'Tenant Disclosures and Brokerage Agreement',
  FL_KE_RLMF: 'Residential Lease Agreement - Multi Family',
  FL_KE_RLSF: 'Residential Lease Agreement - Single Family',

  UNKNOWN: 'Unknown Document',
};

export { documentNameMap };
