import api from 'app/utils/api';

export class TagService {
  public static getTags(params: { entityIds: string[]; entityType: string }) {
    return api.get('/tags/entities', {
      params: {
        entityIds: params.entityIds.join(','),
        entityType: params.entityType,
      },
    });
  }
}
