import { useState, useEffect } from 'react';
import { TagsByEntity } from './tagUtils';
import { TagService } from 'app/services/tag';

export function useEntityTags(entityIds: string[], entityType: string) {
  const [tagsByEntity, setTagsByEntity] = useState<TagsByEntity>({});
  const [tagsLoading, setTagsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    const fetchTags = async () => {
      if (entityIds.length === 0) return;

      setTagsLoading(true);
      try {
        const response = await TagService.getTags({
          entityIds,
          entityType,
        });

        if (mounted) {
          setTagsByEntity(response.data.data);
          setTagsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching tags:', error);
        if (mounted) {
          setTagsLoading(false);
        }
      }
    };

    fetchTags();

    return () => {
      mounted = false;
    };
  }, [entityIds]);

  const isLoadingForEntity = (entityId: string) => {
    return tagsLoading && entityIds.includes(entityId);
  };

  return {
    tagsByEntity,
    isLoadingForEntity,
  };
}
