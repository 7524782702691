import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import { Tag, TAG_DISPLAY_NAMES, TAG_NAMES } from './tagUtils';
import { getSeverityClasses, severity } from 'app/lib/severityColorMap';

interface TagDisplayProps {
  tags: Tag[];
  maxInitialTags: number;
  loading?: boolean;
  isVertical?: boolean;
}

const tagSeverityMap: Record<string, severity> = {
  [TAG_NAMES.NEW_DOCUMENT_ADDED_TODAY]: 'progress',
  [TAG_NAMES.MISSING_DOCUMENT]: 'error',
  [TAG_NAMES.VALIDATION_FAILED]: 'error',
  [TAG_NAMES.DOCUMENT_REJECTED_SENT_TO_AGENT]: 'error',
  [TAG_NAMES.COMPLIANCE_TRANSACTION]: 'utility',
  [TAG_NAMES.STAGNANT_TRANSACTION]: 'inactive',
  [TAG_NAMES.CHECK_DOCUMENT]: 'ready',
} as const;

const getTagColor = (name: string): { text: string } => {
  const severityType = tagSeverityMap[name] || 'inactive';
  return { text: getSeverityClasses(severityType, true, false) };
};

const TagDisplay: React.FC<TagDisplayProps> = React.memo(
  ({ tags, maxInitialTags, loading = false, isVertical = true }) => {
    const [expanded, setExpanded] = useState(false);
    const visibleTags = expanded ? tags : tags.slice(0, maxInitialTags);
    const hasMoreTags = tags.length > maxInitialTags;

    if (loading) {
      return (
        <div className="flex items-center gap-2">
          <div className="relative w-4 h-4">
            <div className="absolute inset-0 rounded-full border-2 border-blue-300/30"></div>
            <div className="absolute inset-0 rounded-full border-t-2 border-blue-500 animate-spin"></div>
            <div className="absolute inset-0 scale-75 rounded-full bg-blue-100/50 animate-ping opacity-30"></div>
          </div>
          <span className="flex text-xs bg-gradient-to-r from-gray-500 to-blue-500 bg-clip-text text-transparent font-medium">
            Fetching...
            <span className="inline-flex overflow-hidden animate-[ellipsis_1.5s_infinite]">
              <span>.</span>
              <span className="delay-300">.</span>
              <span className="delay-600">.</span>
            </span>
          </span>
        </div>
      );
    }

    if (!tags.length && !isVertical) return null;

    if (!tags.length) {
      return <div className="text-gray-500 text-sm">-</div>;
    }

    return (
      <div
        className={`flex ${isVertical ? 'flex-col gap-0.5' : 'flex-row flex-wrap gap-1'}`}
        onClick={(e) => e.stopPropagation()}
      >
        {visibleTags.map((tag, index) => (
          <div key={tag.tagId} className="flex items-center">
            <div
              title={tag.description}
              className={`
              px-2 
              py-0.5 
              bg-[#F5F5F5] 
              rounded 
             ${isVertical ? 'text-[13px]' : 'text-sm'}
              leading-4
              font-medium 
              whitespace-nowrap
              ${getTagColor(tag.tagName).text}
              ${!isVertical && index !== visibleTags.length - 1 ? 'mr-1' : ''}
            `}
            >
              {TAG_DISPLAY_NAMES[tag.tagName as keyof typeof TAG_DISPLAY_NAMES]}
            </div>
            {index === visibleTags.length - 1 && hasMoreTags && (
              <button
                onClick={() => setExpanded(!expanded)}
                className={`p-0.5 hover:bg-black/5 rounded ${isVertical ? 'ml-1' : ''}`}
              >
                {expanded ? (
                  <ChevronUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" />
                )}
              </button>
            )}
          </div>
        ))}
      </div>
    );
  }
);

export default TagDisplay;
