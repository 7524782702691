import React, { useEffect, useState } from 'react';
import { Form, message, Select, Spin } from 'antd';
import { OfficeService } from 'app/services/office';

interface OfficeFormProps {
  selectedOffice: string;
  setSelectedOffice: (officeId: string) => void;
}

const OfficeForm: React.FC<OfficeFormProps> = ({
  selectedOffice,
  setSelectedOffice,
}) => {
  const [offices, setOffices] = useState<{ _id: string; name: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getOffices = async () => {
    setLoading(true);
    try {
      const response: any = await OfficeService.getOffices(
        'Compliance',
        '',
        1,
        100
      );
      setOffices(response.data.data);
    } catch (error) {
      message.error('Error fetching offices');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOffices();
  }, []);

  return (
    <Form layout="vertical">
      <Form.Item label="Select Office" required>
        <Select
          showSearch
          placeholder={loading ? 'Loading offices...' : 'Choose an office'}
          value={selectedOffice}
          onChange={setSelectedOffice}
          loading={loading} 
          notFoundContent={loading ? <Spin size="small" /> : 'No offices found'}
          options={offices.map((office) => ({
            value: office._id,
            label: office.name,
          }))}
          filterOption={(input, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase()) ?? false
          }
          disabled={loading} 
        />
      </Form.Item>
    </Form>
  );
};

export default OfficeForm;
