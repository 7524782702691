import { useEffect, useState } from 'react';
import { Button, Input, Typography, message } from 'antd';
import {
  DeleteOutlined,
  FileTextOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Document } from 'app/interfaces/Document';
import './RequestSignature.scss';
import { SignatureService } from 'app/services/signature';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';

const { Title } = Typography;

interface LocationState {
  documents: Document[];
}

interface Recipient {
  id: string;
  role: string;
  name: string;
  email: string;
  displayRole: string;
  sequence?: string | number;
  signingOrder?: string;
}

interface SignatoryResponse {
  role: string;
  displayRole: string;
  name: string | null;
  email: string | null;
  sequence?: string | number;
  signingOrder?: string;
}

const RequestSignature = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [editingId, setEditingId] = useState<string | null>(null);

  const documents = (location.state as LocationState)?.documents || [];
  const [selectedDocuments, setSelectedDocuments] =
    useState<Document[]>(documents);
  const [approvalDisabled, setApprovalDisabled] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recipients, setRecipients] = useState<Recipient[]>([]);

  const handleEditRecipient = (id: string) => {
    setEditingId(id);
  };

  // const handleAddRecipient = () => {
  //   const newId = String(recipients.length + 1);
  //   setRecipients([
  //     ...recipients,
  //     { id: newId, role: 'buyer', name: '', email: '' },
  //   ]);
  //   setEditingId(newId);
  // };

  const handleRemoveRecipient = (id: string) => {
    if (recipients.length > 1) {
      setRecipients(recipients.filter((r) => r.id !== id));
    }
  };

  const handleSaveRecipient = (id: string) => {
    const recipient = recipients.find((r) => r.id === id);
    if (recipient?.name && recipient?.email) {
      setEditingId(null);
    } else {
      message.error('Please fill in all recipient details');
    }
  };

  const handleRecipientChange = (id: string, field: string, value: string) => {
    if (!id || !field || value === undefined) return;
    setRecipients(
      recipients.map((r) => (r.id === id ? { ...r, [field]: value } : r))
    );
  };

  const validateRecipients = () => {
    if (!recipients || recipients.length === 0) {
      message.error('No recipients found');
      return false;
    }
    const isValid = recipients.every((r) => r?.name && r?.email);
    if (!isValid) {
      message.error('Please fill in all recipient details');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateRecipients()) return;
    if (!selectedDocuments || selectedDocuments.length === 0) {
      message.error('No documents selected');
      return;
    }

    try {
      setIsSubmitting(true);
      const documentIds = selectedDocuments
        .filter((doc) => doc?._id)
        .map((doc) => doc._id);

      if (documentIds.length === 0) {
        throw new Error('Invalid document IDs');
      }
      const recipientData = recipients
        .filter((r) => r?.role && r?.name && r?.email)
        .map(({ role, name, email }) => ({
          role,
          name,
          email,
        }));

      if (recipientData.length === 0) {
        throw new Error('Invalid recipient data');
      }
      const response = await SignatureService.requestSignatures(
        documentIds,
        recipientData
      );
      if (response.status === 201) {
        setApprovalDisabled(true);
        message.success('Signature request sent successfully');
      }
    } catch (error: any) {
      console.error('Error requesting signatures:', error);
      message.error(error.message || 'Failed to request signatures');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRemoveDocument = (docId: string) => {
    if (selectedDocuments.length === 1) {
      message.error('At least one document must be selected');
      return;
    }
    setSelectedDocuments(selectedDocuments.filter((doc) => doc._id !== docId));
  };

  // Dedupe signatories based on role

  const processSignatories = (documentSignatories: {
    [key: string]: any;
  }): Recipient[] => {
    const allSignatories: Map<
      string,
      {
        signatory: SignatoryResponse;
        signingOrder: string;
        sequence: number | string;
      }
    > = new Map();

    // First pass: process sequential signing
    Object.values(documentSignatories).forEach((docData: any) => {
      const { signatories, signing_order } = docData;
      if (!signatories || signing_order !== 'sequential') return;

      signatories.forEach((signatory: SignatoryResponse) => {
        const key = signatory.role;
        // Only set if not already set or if current is sequential
        if (!allSignatories.has(key)) {
          if (signatory.role === 'managing_broker') {
            signatory.displayRole = 'Managing Broker';
            signatory.name = 'Eddie Mateja';
            signatory.email = 'eddie.mateja@bairdwarner.com';
          }
          allSignatories.set(key, {
            signatory,
            signingOrder: 'sequential',
            sequence: signatory.sequence || 'NOT_APPLICABLE',
          });
        }
      });
    });

    // Second pass: process parallel signing
    Object.values(documentSignatories).forEach((docData: any) => {
      const { signatories, signing_order } = docData;
      if (!signatories || signing_order !== 'parallel') return;

      signatories.forEach((signatory: SignatoryResponse) => {
        const key = signatory.role;
        // Only set if not already set (don't overwrite sequential)
        if (!allSignatories.has(key)) {
           if (signatory.role === 'managing_broker') {
          signatory.displayRole = 'Managing Broker';
          signatory.name = 'Eddie Mateja';
          signatory.email = 'eddie.mateja@bairdwarner.com';
        }
          allSignatories.set(key, {
            signatory,
            signingOrder: 'parallel',
            sequence: 'NOT_APPLICABLE',
          });
        }
      });
    });

    // Convert to array and sort
    const processedSignatories = Array.from(allSignatories.values())
      .sort((a, b) => {
        // Sort sequential signers by sequence number
        if (
          a.signingOrder === 'sequential' &&
          b.signingOrder === 'sequential'
        ) {
          const seqA =
            typeof a.sequence === 'number' ? a.sequence : Number.MAX_VALUE;
          const seqB =
            typeof b.sequence === 'number' ? b.sequence : Number.MAX_VALUE;
          return seqA - seqB;
        }
        // Put sequential signers before parallel ones
        if (a.signingOrder === 'sequential') return -1;
        if (b.signingOrder === 'sequential') return 1;
        return 0;
      })
      .map(({ signatory, signingOrder, sequence }, index) => ({
        id: String(index + 1),
        role: signatory.role,
        name: signatory.name || '',
        email: signatory.email || '',
        displayRole: signatory.displayRole || signatory.role,
        sequence: sequence,
        signingOrder: signingOrder,
      }));

    return processedSignatories;
  };

  const fetchAndProcessSignatories = async (documentIds: string[]) => {
    try {
      const response: any = await SignatureService.getSignatories(documentIds);
      if (response.data) {
        const processedRecipients = processSignatories(response.data);
        setRecipients(processedRecipients);
      }
    } catch (error: any) {
      console.error('Error fetching signatories:', error);
      message.error('Failed to fetch existing signatories');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDocuments.length > 0) {
      fetchAndProcessSignatories(selectedDocuments.map((doc) => doc._id));
    }
  }, [selectedDocuments.length]);

  const component = (
    <div className="request-signature">
      <div className="request-signature__header">
        <Title level={4}>Request Signature</Title>
        <div className="request-signature__actions">
          <Button onClick={() => navigate(-1)}>Return to Documents</Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={approvalDisabled}
            loading={isSubmitting}
          >
            {isSubmitting ? 'Approving...' : 'Approve'}
          </Button>
        </div>
      </div>

      <div className="request-signature__content">
        <div className="request-signature__section">
          <Title level={5}>
            {selectedDocuments.length} selected
            {selectedDocuments.length > 1 ? ' files' : ' file'}{' '}
          </Title>
          <div className="selected-files">
            {selectedDocuments.map((doc: Document) => (
              <div key={doc._id} className="file-item">
                <FileTextOutlined className="file-item__icon" />
                <span className="file-item__name">{doc.title}</span>
                <span
                  className="file-item__remove"
                  onClick={() => handleRemoveDocument(doc._id)}
                  style={{ cursor: 'pointer' }}
                >
                  Remove
                </span>{' '}
              </div>
            ))}
          </div>
        </div>

        <div className="request-signature__section">
          <Title level={5}>Recipients</Title>
          <div className="recipients-list">
            {recipients.map((recipient) => (
              <div
                key={recipient.id}
                className="recipient-item"
                style={
                  editingId === recipient.id
                    ? { padding: '1rem' }
                    : { padding: '0.5rem 1rem' }
                }
              >
                {' '}
                {editingId === recipient.id ? (
                  // Edit Mode
                  <div className="recipient-item__row">
                    {recipient?.signingOrder === 'sequential' &&
                      recipient.sequence &&
                      recipient.sequence !== 'NOT_APPLICABLE' && (
                        <span className="sequence-badge">
                          Sequence: {recipient.sequence}
                        </span>
                      )}
                    {recipient?.signingOrder === 'parallel' && (
                      <span className="sequence-badge">Parallel Signing</span>
                    )}
                    <span className={`role-badge`}>
                      {recipient.displayRole}
                    </span>
                    <Input
                      placeholder="Name"
                      value={recipient.name}
                      onChange={(e) =>
                        handleRecipientChange(
                          recipient.id,
                          'name',
                          e.target.value
                        )
                      }
                      style={{ width: 'max-content' }}
                    />
                    <Input
                      placeholder="Email"
                      value={recipient.email}
                      onChange={(e) =>
                        handleRecipientChange(
                          recipient.id,
                          'email',
                          e.target.value
                        )
                      }
                      style={{ width: 'max-content' }}
                    />
                    <div className="recipient-item__actions">
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => handleSaveRecipient(recipient.id)}
                      >
                        Save
                      </Button>
                      {recipients.length > 1 && (
                        <Button
                          type="text"
                          icon={<DeleteOutlined />}
                          onClick={() => handleRemoveRecipient(recipient.id)}
                          className="delete-button"
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  // View Mode
                  <div className="recipient-item__row">
                    {recipient?.signingOrder === 'sequential' &&
                      recipient.sequence &&
                      recipient.sequence !== 'NOT_APPLICABLE' && (
                        <span className="sequence-badge">
                          Sequence: {recipient.sequence}
                        </span>
                      )}
                    {recipient?.signingOrder === 'parallel' && (
                      <span className="sequence-badge">Parallel Signing</span>
                    )}
                    <span className={`role-badge`}>
                      {recipient.displayRole}
                    </span>
                    <span className="recipient-item__name">
                      {recipient.name || 'No name'}
                    </span>
                    <span className="recipient-item__email">
                      {recipient.email || 'No email'}
                    </span>
                    <div className="recipient-item__actions">
                      <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={() => handleEditRecipient(recipient.id)}
                      />
                      {recipients.length > 1 && (
                        <Button
                          type="text"
                          icon={<DeleteOutlined />}
                          onClick={() => handleRemoveRecipient(recipient.id)}
                          className="delete-button"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* <Button className="add-recipient" onClick={handleAddRecipient}>
            Add recipient
          </Button> */}
        </div>
      </div>
    </div>
  );

  return (
    <WaitAndLoadingWithSpinner loading={isLoading} component={component} />
  );
};

export default RequestSignature;
