import api from 'app/utils/api';
import apiV2 from 'app/utils/apiV2';

export class ComplianceService {
  public static getTransactions(
    searchValue: string = '',
    filterStatuses: string[] = [],
    page: number = 1,
    limit: number = 10,
    createdAtGte?: string,
    createdAtLte?: string,
    closingDateGte?: string,
    closingDateLte?: string,
    transactionTypes?: string[],
    transactionMilestones?: string[],
    sort: string = '-createdAt',
    complianceTransactions?: boolean,
    isAgent?: boolean,
    milestones: string[] = [],
    tags: string[] = [],
    updatedAtGte?: string,
    updatedAtLte?: string
  ) {
    let url = isAgent
      ? `/agent/transactions?sort=${sort}`
      : `/transactions?sort=${sort}`;
    if (page !== undefined) url += `&page=${page}`;
    if (limit !== undefined) url += `&limit=${limit}`;
    if (createdAtGte) url += `&createdAt[gte]=${createdAtGte}`;
    if (createdAtLte) url += `&createdAt[lte]=${createdAtLte}`;
    if (closingDateGte) url += `&closingDate[gte]=${closingDateGte}`;
    if (closingDateLte) url += `&closingDate[lte]=${closingDateLte}`;
    if (updatedAtGte) url += `&updatedAt[gte]=${updatedAtGte}`;
    if (updatedAtLte) url += `&updatedAt[lte]=${updatedAtLte}`;
    if (milestones?.length) url += `&milestone=${milestones.join(',')}`;
    if (filterStatuses?.length)
      url += `&processingStatus.status=${filterStatuses.join(',')}`;
    if (searchValue) url += `&search=${searchValue}`;
    if (transactionTypes?.length) url += `&type=${transactionTypes.join(',')}`;
    if (transactionMilestones?.length)
      url += `&milestone=${transactionMilestones.join(',')}`;
    if (complianceTransactions) url += '&filterBy=withoutChecks';
    if (tags?.length) url += `&tags=${tags.join(',')}`;

    return isAgent ? api.get(url) : apiV2.get(url);
  }

  public static getUpdatedMlsStatus(transactionId: string) {
    return api.get(`/mls-data/${transactionId}`);
  }
  public static getTransactionByID(transactionId: string) {
    return api.get(`/transaction/${transactionId}`);
  }

  public static getActivityLogsByTransactionId(
    transactionId: string,
    page?: number,
    limit?: number
  ) {
    let url = `/activity-logs/transaction/${transactionId}?`;
    if (page !== undefined) url += `&page=${page}`;
    if (limit !== undefined) url += `&limit=${limit}`;
    return api.get(url);
  }

  public static getTransactionDocumentByID(
    transactionId: string,
    documentId: string
  ) {
    return api.get(`/transaction/${transactionId}/documents/${documentId}`);
  }

  public static createTransaction(formData: any) {
    const addressPayload = {
      address: formData?.address,
      agentID: formData?.selectedAgentName?.value,
    };
    return api.post('/transaction', addressPayload);
    // return apiV2.post('/transactions', addressPayload);
  }

  public static archiveTransaction(transactionID: string) {
    return api.patch(`/transaction/${transactionID}/archive`);
  }

  public static deleteTransaction(transactionID: string) {
    return api.delete(`/transaction/${transactionID}`);
  }

  public static updateStatus(transactionID: string, status: string) {
    const data = {
      status,
    };
    return api.patch(`/transaction/${transactionID}/status`, data);
  }

  // TODO: Once we no longer require file type, implement multi document upload
  public static uploadDocuments(transactionID: string, files: any[]) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file);
    });
    console.log('Form Data:', formData);
    return api.put(`/transaction/${transactionID}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public static getTransactionDocument(
    transactionID: string,
    documentID: string
  ) {
    return api.get(`/transaction/${transactionID}/documents/${documentID}`, {
      responseType: 'arraybuffer',
    });
  }

  public static getTransactionWithDocumentDetails(
    transactionID: string,
    documentID: string
  ) {
    return api.get(
      `transaction/${transactionID}/document_details/${documentID}`
    );
  }

  public static validateDocuments(transactionID: string) {
    return api.patch(`/transaction/${transactionID}/validate_documents`);
  }

  public static approveDocument(
    transactionID: string,
    documentID: string,
    errorActions: Record<string, string>[]
  ) {
    const data = {
      errorActions,
    };

    return api.patch(
      `/transaction/${transactionID}/documents/${documentID}`,
      data
    );
  }

  public static getAgents(query?: string) {
    const url = query ? `/agent?search=${query}` : '/agent';
    return api.get(url);
  }

  public static assignRealtor(
    transactionID: string,
    agentID: string,
    role: string
  ) {
    const data = {
      agentID,
      role,
    };
    return api.patch(`/transaction/${transactionID}/agent`, data);
  }

  public static getAgent(query: string) {
    return api.get(`/agent?search=${query}&limit=5`);
  }

  public static postCheck(
    agentID: string,
    documentType: string,
    file: File,
    newTransaction: boolean,
    receivingOfficeId: string,
    transactionName?: string,
    transactionID?: string,
    address?: any
  ) {
    const formData = new FormData();
    formData.append('transactionName', transactionName ?? '');
    formData.append('agentID', agentID);
    formData.append('file', file);
    formData.append('documentType', documentType);
    formData.append('newTransaction', newTransaction ? 'true' : 'false');
    formData.append('transactionID', transactionID ?? '');
    formData.append('receivingOfficeId', receivingOfficeId);
    formData.append('address', JSON.stringify(address));

    return api.post('/transaction/checks', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public static getCheckByID(checkID: string) {
    return api.get(`/transaction/checks/${checkID}`);
  }

  public static getDocumentByID(transactionID: string, documentID: string) {
    return api.get(`/transaction/${transactionID}/documents/${documentID}`, {
      responseType: 'arraybuffer',
    });
  }

  public static async getDocumentsByOfficeAdmin(transactionID: string) {
    return await api.get(
      `/transaction/${transactionID}/office-admin-documents`
    );
  }

  public static patchCheck(
    checkID: string,
    readyToDeposit: boolean,
    remarks: string,
    status: string
  ) {
    const data = {
      readyToDeposit,
      remarks,
      processingStatus: {
        status,
      },
    };
    return api.patch(`/transaction/checks/${checkID}`, data);
  }

  public static patchCheckFields(
    checkID: string,
    readyToDeposit: boolean,
    remarks: string,
    status: string,
    office?: string,
    agentId?: string
  ) {
    const data = {
      readyToDeposit,
      remarks,
      processingStatus: {
        status,
      },
      office,
      agentId,
    };
    return api.patch(
      `/transaction/checks/${checkID}/update-check-fields`,
      data
    );
  }

  public static patchReadyToDeposit(
    checkID: string,
    readyToDeposit: boolean,
    sendEmail: boolean = true
  ) {
    const data = {
      readyToDeposit,
      sendEmail,
    };
    return api.patch(`/transaction/checks/${checkID}/ready-to-deposit`, data);
  }

  public static archiveCheck(checkID: string) {
    return api.patch(`/transaction/checks/${checkID}/archive`);
  }

  public static deleteCheck(checkID: string) {
    return api.delete(`/transaction/checks/${checkID}`);
  }

  public static getPropertyByAddress(query: string) {
    return apiV2.get(`/transactions?search=${query}`);
  }

  public static async updateCheckErrors(
    checkId: string,
    updatedIssues: {
      checkErrors: { required: string[]; optional: string[] };
      checkErrorsFormatted: { required: string[]; optional: string[] };
    }
  ) {
    try {
      const response = await api.patch(
        `/transaction/checks/${checkId}/update-validation-result`,
        updatedIssues
      );
      return response.data;
    } catch (error) {
      console.error('Error updating check errors:', error);
      throw error;
    }
  }

  // // Tried to set up client side rendering of the document but it is not working
  // public static getDocumentv2(documentID: string) {
  //   return api.get(`/compliance/document/${documentID}`);
  // }

  public static getSourceOfTruth(transactionID: string, group: string) {
    return api.get(`/sources-of-truth/${transactionID}/group/${group}`);
  }

  public static updateSourceOfTruth(
    transactionID: string,
    fieldKey: string,
    value: string
  ) {
    return api.patch(`/sources-of-truth/update-field`, {
      transactionID,
      fieldKey,
      value,
    });
  }

  public static approveTransaction(transactionID: string) {
    return api.patch(`/sources-of-truth/${transactionID}/approve`);
  }

  public static updateRemarks(transactionID: string, remarks: string) {
    return api.patch(`/transaction/${transactionID}/remarks`, {
      remarks,
    });
  }

  public static updateDocumentReviewStatus(
    documentId: string,
    reviewStatus: string
  ) {
    return api.patch(`/document/${documentId}/review-status`, {
      reviewStatus,
    });
  }
  public static deleteDocument(documentId: string) {
    return api.delete(`/document/${documentId}`);
  }

  public static checkDocument(documentId: string) {
    return api.get(`/document/${documentId}/get-check`);
  }

  public static sendCSTeamRectificationEmail(
    transactionId: string,
    errorDocumentIdsAndActionList: Record<string, Record<string, string>[]>,
    subject: string,
    body: string,
    recipients: Record<string, Record<string, string> | string[]>
  ) {
    return api.post(`/transaction/${transactionId}/send-rectification-email`, {
      errorDocumentIdsAndActionList,
      subject,
      body,
      recipients,
    });
  }

  public static updateDocumentTitle(documentId: string, title: string) {
    return api.patch(`/document/${documentId}/title`, { title });
  }

  public static updateTransactionMilestone(
    transactionId: string,
    milestone: string
  ) {
    return api.patch(`/transaction/${transactionId}/milestone`, { milestone });
  }

  public static updateTransactionAgent(
    transactionId: string,
    agents: { _id: string }[]
  ) {
    return api.patch(`/transaction/${transactionId}/agents`, { agents });
  }

  public static updateTransactionType(transactionID: string, type: string) {
    return api.patch(`/transaction/${transactionID}/transaction-type`, {
      type,
    });
  }

  public static bulkUpdateSourceOfTruth(
    transactionID: string,
    updates: Array<{ fieldKey: string; value: string }>
  ) {
    return api.patch(`/sources-of-truth/bulk-update-fields`, {
      transactionID,
      updates,
    });
  }

  public static sendPrismNotFoundEmail(checkId: string) {
    return api.post(
      `/transaction/checks/${checkId}/notifications/prism-not-found`
    );
  }

  public static markTransactionsDormant(transactionIds: string[]) {
    return api.post('transaction/mark-dormant', { transactionIds });
  }
  public static syncTransactionWithDotloop(transactionId: string) {
    return api.patch(`/transaction/${transactionId}/sync-dotloop`);
  }
}
