import { Box, Typography } from '@mui/material';
import LoadingProgressBar from 'app/components/common/LoadingProgressBar';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import { useEffect, useState } from 'react';
import WorkAllocationTable from './components/WorkAllocationTable';
import { Button, message, Modal } from 'antd';
import OutOfOfficeModal from './components/OutOfOfficeModal';
import ReassignPrimaryAdminModal from './components/ReassignPrimaryAgentModal';
import { WorkAllocationService } from '../util/workAllocationService';
import { OfficeService } from 'app/services/office';
import OfficeForm from 'app/components/common/OfficeForm/OfficeForm';

const WorkAllocation = () => {
  const [progressBarLoading, setProgressBarLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oooModalVisible, setOooModalVisible] = useState(false);
  const [reassignModalVisible, setReassignModalVisible] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState<any | null>({});
  const [workAllocationData, setWorkAllocationData] = useState<any[]>([]);
  const [offices, setOffices] = useState([]);
  const [processingTeamMembers, setProcessingTeamMembers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    officeID: '',
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const getWorkAllocations = async (loading = false) => {
    if (loading) setLoading(true);
    setProgressBarLoading(true);
    try {
      const response: any = await WorkAllocationService.getWorkAllocations();
      setWorkAllocationData(response?.data);
    } catch (error: any) {
      setWorkAllocationData([]);
      message.error(error.message);
    } finally {
      setProgressBarLoading(false);
      setLoading(false);
    }
  };

  const getOffices = async () => {
    try {
      const response: any = await OfficeService.getOffices('Compliance', '');
      setOffices(response.data.data);
    } catch (error) {
      message.error('Error fetching offices');
    }
  };

  const getProcessingTeamMembers = async () => {
    try {
      const response: any =
        await WorkAllocationService.getProcessingTeamMembers();
      if (response.status === 200) setProcessingTeamMembers(response.data.data);
    } catch (error) {
      message.error('Error fetching processing team members');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          getWorkAllocations(true),
          getOffices(),
          getProcessingTeamMembers(),
        ]);
      } catch (error: any) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    if (!formData.officeID) {
      return message.error('Office ID is required');
    }

    setProgressBarLoading(true);
    setButtonLoading(true);
    try {
      const response: any =
        await WorkAllocationService.addOfficeToWorkAllocation({
          officeID: formData.officeID,
        });

      if (response?.data) {
        message.success('Office created successfully');
      } else {
        message.error(response?.message || 'Error creating office');
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message || 'Error creating office');
      console.error('Error:', error);
    } finally {
      setProgressBarLoading(false);
      setButtonLoading(false);
      getWorkAllocations();
    }
  };

  const resetFormData = () => {
    setFormData({
      officeID: '',
    });
  };

  const component = (
    <>
      <Box width="100%" boxSizing="border-box">
        {progressBarLoading && (
          <Box width="100%">
            <LoadingProgressBar />
          </Box>
        )}

        <div className="p-4">
          <div className="flex justify-between items-center w-full">
            <div className="text-2xl font-medium">Offices & Teams</div>
            <div>
              <Button
                onClick={() => setIsModalOpen(true)}
                className="h-10 rounded-2xl border border-[#1677FF] text-[#1677FF]"
              >
                Add Office
              </Button>
            </div>
          </div>
          <div className="flex justify-end p-2"></div>
          <div className="flex flex-col mt-4">
            <WorkAllocationTable
              processingTeamMembers={processingTeamMembers}
              workAllocationData={workAllocationData}
              setOooModalVisible={(visible, office) => {
                setSelectedOffice(office || null);
                setOooModalVisible(visible);
              }}
              setReassignModalVisible={(visible, office) => {
                setSelectedOffice(office || null);
                setReassignModalVisible(visible);
              }}
              getWorkAllocations={getWorkAllocations}
            />
          </div>
        </div>
      </Box>

      {/* OutOfOfficeModal component */}
      <OutOfOfficeModal
        visible={oooModalVisible}
        onCancel={() => setOooModalVisible(false)}
        offices={offices}
        selectedOffice={selectedOffice}
        getWorkAllocations={getWorkAllocations}
        processingTeamMembers={processingTeamMembers}
      />

      {/* ReassignPrimaryAdminModal component */}
      <ReassignPrimaryAdminModal
        selectedOffice={selectedOffice}
        visible={reassignModalVisible}
        onCancel={() => setReassignModalVisible(false)}
        processingTeamMembers={processingTeamMembers}
        getWorkAllocations={getWorkAllocations}
      />

      <Modal
        title={
          <Typography fontSize="1.25rem" fontWeight={500} marginBottom={2}>
            Add Office
          </Typography>
        }
        open={isModalOpen}
        onCancel={() => {
          resetFormData();
          setIsModalOpen(false);
        }}
        afterClose={resetFormData}
        onOk={async () => {
          await handleSubmit();
          setIsModalOpen(false);
        }}
        cancelText="Cancel"
        okText={buttonLoading ? 'Submitting...' : 'Submit'}
        okButtonProps={{ loading: buttonLoading }}
      >
        <OfficeForm
          selectedOffice={formData.officeID}
          setSelectedOffice={(id) =>
            setFormData((prev) => ({ ...prev, officeID: id }))
          }
        />
      </Modal>
    </>
  );

  return <WaitAndLoadingWithSpinner loading={loading} component={component} />;
};

export default WorkAllocation;
