import { ComplianceService } from 'app/services/compliance';

interface TransactionBuilderOptions {
  page?: number;
  limit?: number;
  createdAtGte?: string;
  createdAtLte?: string;
  closingDateGte?: string;
  closingDateLte?: string;
  status?: string[];
  search?: string;
  transactionTypes?: string[];
  transactionMilestones?: string[];
  sort?: string;
  complianceTransactions?: boolean;
  isAgent?: boolean;
  milestones?: string[];
  tags?: string[];
  updatedAtGte?: string;
  updatedAtLte?: string;
}

export class TransactionBuilder {
  private page?: number;
  private limit?: number;
  private createdAtGte?: string;
  private createdAtLte?: string;
  private closingDateGte?: string;
  private closingDateLte?: string;
  private transactionTypes?: string[];
  private transactionMilestones?: string[];
  private status?: string[];
  private search?: string;
  public hasMore: boolean;
  private complianceTransactions?: boolean;
  private isAgent?: boolean;
  private sort: string = '-createdAt';
  private milestones?: string[];
  private tags?: string[];
  private updatedAtGte?: string;
  private updatedAtLte?: string;

  private constructor(options: TransactionBuilderOptions) {
    this.page = options.page;
    this.limit = options.limit;
    this.createdAtGte = options.createdAtGte;
    this.createdAtLte = options.createdAtLte;
    this.closingDateGte = options.closingDateGte;
    this.closingDateLte = options.closingDateLte;
    this.status = options.status;
    this.search = options.search;
    this.hasMore = true;
    this.transactionTypes = options.transactionTypes;
    this.transactionMilestones = options.transactionMilestones;
    this.sort = options.sort || '-createdAt';
    this.complianceTransactions = options.complianceTransactions;
    this.milestones = options.milestones;
    this.isAgent = options.isAgent;
    this.tags = options.tags;
    this.updatedAtGte = options.updatedAtGte;
    this.updatedAtLte = options.updatedAtLte;
  }

  static builder = class {
    private page?: number;
    private limit?: number;
    private createdAtGte?: string;
    private createdAtLte?: string;
    private closingDateGte?: string;
    private closingDateLte?: string;
    private status?: string[];
    private search?: string;
    private transactionTypes?: string[];
    private transactionMilestones?: string[];
    private sort: string = '-createdAt';
    private complianceTransactions?: boolean;
    private isAgent?: boolean;
    private milestones?: string[];
    private tags?: string[];
    private updatedAtGte?: string;
    private updatedAtLte?: string;

    setPage(page: number): this {
      this.page = page;
      return this;
    }

    setLimit(limit: number): this {
      this.limit = limit;
      return this;
    }

    setCreatedAtGte(date: string): this {
      this.createdAtGte = date;
      return this;
    }

    setCreatedAtLte(date: string): this {
      this.createdAtLte = date;
      return this;
    }

    setClosingDateGte(date: string): this {
      this.closingDateGte = date;
      return this;
    }

    setClosingDateLte(date: string): this {
      this.closingDateLte = date;
      return this;
    }

    setTransactionTypes(types: string[]): this {
      this.transactionTypes = types;
      return this;
    }

    setTransactionMilestones(milestones: string[]): this {
      this.transactionMilestones = milestones;
      return this;
    }

    setStatus(status: string[]): this {
      this.status = status;
      return this;
    }

    setSearch(search: string): this {
      this.search = search;
      return this;
    }

    setSort(sort: string): this {
      this.sort = sort;
      return this;
    }

    setcomplianceTransactions(complianceTransactions: boolean): this {
      this.complianceTransactions = complianceTransactions;
      return this;
    }

    setIsAgent(isAgent: boolean): this {
      this.isAgent = isAgent;
      return this;
    }

    setMilestone(milestones: string[]): this {
      this.milestones = milestones;
      return this;
    }

    setTags(tags: string[]): this {
      this.tags = tags;
      return this;
    }
    
    setUpdatedAtGte(date: string): this {
      this.updatedAtGte = date;
      return this;
    }

    setUpdatedAtLte(date: string): this {
      this.updatedAtLte = date;
      return this;
    }

    build(): TransactionBuilder {
      return new TransactionBuilder({
        page: this.page,
        limit: this.limit,
        createdAtGte: this.createdAtGte,
        createdAtLte: this.createdAtLte,
        closingDateGte: this.closingDateGte,
        closingDateLte: this.closingDateLte,
        status: this.status,
        search: this.search,
        transactionTypes: this.transactionTypes,
        transactionMilestones: this.transactionMilestones,
        sort: this.sort,
        complianceTransactions: this.complianceTransactions,
        isAgent: this.isAgent,
        milestones: this.milestones,
        tags: this.tags,
        updatedAtGte: this.updatedAtGte,
        updatedAtLte: this.updatedAtLte,
      });
    }
  };

  async fetchNext(): Promise<any> {
    try {
      const response: any = await ComplianceService.getTransactions(
        this.search,
        this.status,
        this.page,
        this.limit,
        this.createdAtGte,
        this.createdAtLte,
        this.closingDateGte,
        this.closingDateLte,
        this.transactionTypes,
        this.transactionMilestones,
        this.sort,
        this.complianceTransactions,
        this.isAgent,
        this.milestones,
        this.tags,
        this.updatedAtGte,
        this.updatedAtLte
      );

      if (this.page !== undefined && this.limit !== undefined) {
        const totalFetched = this.page * this.limit;
        this.hasMore =
          totalFetched < (this.isAgent ? response.total : response.data.total);
      } else {
        this.hasMore = false;
      }
      return this.isAgent ? response : response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
}
