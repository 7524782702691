import { Typography } from '@mui/material';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { OfficeService } from 'app/services/office';
import { useFilters } from '../Checks/FilterContext';

export enum OfficeType {
  TRANSACTION = 'transaction',
  RECEIVING = 'receiving',
}

interface OfficeFilterProps {
  type: OfficeType;
}

export const OfficeFilter: React.FC<OfficeFilterProps> = ({ type }) => {
  const [loadingOffices, setLoadingOffices] = useState(false);
  const [filteredOffices, setFilteredOffices] = useState<
    Array<{ value: string; label: string }>
  >([]);

  const { filters, setTransactionOffices, setReceivingOffices } = useFilters();

  const selectedOffices =
    type === OfficeType.TRANSACTION
      ? filters.transactionOffices
      : filters.receivingOffices;
  const setSelectedOffices =
    type === OfficeType.TRANSACTION
      ? setTransactionOffices
      : setReceivingOffices;

  const fetchOffices = async (searchText: string = '') => {
    try {
      setLoadingOffices(true);
      const response = await OfficeService.getOffices('Compliance', searchText);
      const formattedOffices = response.data.data.map((office: any) => ({
        value: office._id,
        label: office.name,
      }));
      setFilteredOffices(formattedOffices);
    } catch (error) {
      console.error('Error fetching offices:', error);
    } finally {
      setLoadingOffices(false);
    }
  };

  useEffect(() => {
    fetchOffices();
  }, []);

  const handleSearch = (searchText: string) => {
    if (searchText.length >= 1) {
      fetchOffices(searchText);
    }
  };

  const handleChange = (values: string[]) => {
    const officeObjects = values.map((id) => ({
      id,
      name: filteredOffices.find((office) => office.value === id)?.label || id,
    }));

    setSelectedOffices(officeObjects);
  };

  return (
    <div className="filter-section">
      <Typography fontSize="1rem" marginBottom="0.5rem">
        {type === OfficeType.TRANSACTION ? 'Branch Office' : 'Receiving Office'}
      </Typography>
      <Select
        mode="multiple"
        className="select-component"
        placeholder={`Select ${type === OfficeType.TRANSACTION ? 'Branch' : 'Receiving'} Office`}
        value={selectedOffices.map((office) => office.id)}
        onChange={handleChange}
        options={filteredOffices}
        loading={loadingOffices}
        allowClear
        showSearch
        onSearch={handleSearch}
        filterOption={false}
      />
    </div>
  );
};
