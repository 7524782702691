import api from 'app/utils/api';

export class EmailService {
  public static async getEmailLogs(
    entityId: string,
    entityType: string,
    emailType?: string[],
    queryParams: Record<string, any> = {}
  ) {
    return api.get(`/email-logs/entity/${entityId}/entity-type/${entityType}`, {
      params: { emailType, ...queryParams },
    });
  }

  public static async getEmailLogsByCheckType(
    transactionId: string,
    checkType: string
  ) {
    return api.get(
      `/email-logs/transaction/${transactionId}/check-type/${checkType}`
    );
  }
}
